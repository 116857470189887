import {
  Paper,
  Stack,
  Typography,
  Avatar,
  IconButton,
  MenuItem,
  Menu,
  Box,
  Tooltip,
  Divider,
} from "@mui/material";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import UndoRoundedIcon from "@mui/icons-material/UndoRounded";
import RedoRoundedIcon from "@mui/icons-material/RedoRounded";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import StarIcon from "@mui/icons-material/Star";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  getEmailDetailList,
  updateEmail,
} from "../../../../services/api/apiManager";
import { settings as s } from "../../../../services/Settings";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import Reply from "./Reply";
import excel from "../../../../../assets/images/excel.png";
import pdf from "../../../../../assets/images/pdf.png";
import { setEmailId } from "../../../../services/store/slice/ChatSlice";
import { setRenderEmailList } from "../../../../services/store/slice/EmailSlice";
// import Forword from "./Forword";

function EmailWindow() {
  const [selectedOption, setSelectedOption] = useState("");
  const [apiEmailDetail, setApiEmailDetail] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [replyShow, setReplyShow] = useState(false);
  const [forwardShow, setForwardShow] = useState(false);
  const [replyprofilePic, setReplyProfilePic] = useState(null);
  const [emailReplyId, setEmailReplyId] = useState(null);
  const [replyName, setReplyName] = useState("");
  const [replyEmail, setReplyEmail] = useState("");
  const [senderId, setSenderId] = useState(null);
  const [parentEmailId, setParentEmailId] = useState(null);
  const [subject, setSubject] = useState("");
  const [bodyData, setBodyData] = useState("");
  const [dateTime, setDateTime] = useState("");
  const [toName, setToName] = useState("");
  const [toEmail, setToEmail] = useState("");
  const [emailType, setEmailType] = useState("");

  // region redux valuess
  const dispatch = useDispatch();
  const emailIds = useSelector((state) => state?.chat?.emailId);
  const renderEmailList = useSelector((state) => state?.email?.renderEmailList);
  const emailsLength = useSelector((state) => state?.email?.emailsLength);
  const handleSentEmail = useSelector((state) => state?.email?.handleSentEmail);
  // remove from data
  const filterFromData = apiEmailDetail?.filter(
    (item, key) => item?.recipients?.length > 1
  );

  console.log("FilterFrom Data is >>>", filterFromData);

  let UserId = localStorage.getItem("user_id");

  //
  useEffect(() => {
    dispatch(setEmailId(localStorage.getItem("emailIds")));
  }, []);
  // region render detail data
  useEffect(() => {
    if (renderEmailList) {
      getEmailDetail(emailIds);
      dispatch(setRenderEmailList(false));
    }
  }, [renderEmailList]);

  //Favorite Email Function
  const isFavMail = async (data) => {
    try {
      const response = await updateEmail(data);
      if (response?.status === 200) {
        getEmailDetail(emailIds);
        dispatch(setRenderEmailList(true));
      }
    } catch (error) {
      console.error("Error Occur:", error);
    }
  };

  // region deleteEmail
  async function deleteMail(data) {
    try {
      const response = await updateEmail(data);
      if (response?.status === 200) {
        dispatch(setRenderEmailList(true));
        dispatch(setEmailId(0));
        enqueueSnackbar("Deleted Successfully", { variant: "success" });
      }
    } catch (error) {
      console.error(error);
    }
  }

  // region Email Detail
  async function getEmailDetail(id) {
    try {
      const response = await getEmailDetailList(id);
      if (response?.status === 200) {
        setParentEmailId(response?.data?.result[0]?.emailId);
        if (handleSentEmail) {
          setApiEmailDetail(
            response?.data?.result?.filter((data) => data?.emailId == emailIds)
          );
        } else {
          setApiEmailDetail(response?.data?.result);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (emailIds) {
      getEmailDetail(emailIds);
    }
  }, [emailIds]);

  const handleDownload = async (filePath) => {
    try {
      const response = await fetch(`${s.baseUrl}${filePath}`);
      if (!response.ok) {
        throw new Error("Failed to Fetch API Data");
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const fileName = filePath.split("/").pop();
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("Download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  // Handle "Reply" button click
  const handleReplyClick = () => {
    setSelectedOption("reply");
    setReplyShow(true);
    setForwardShow(false);
  };

  // Handle "Forward" button click
  const handleForwardClick = () => {
    setSelectedOption("forward");
    setForwardShow(true);
    setReplyShow(false);
  };

  const latestChatDate = Math.max(
    ...filterFromData.map((data) => new Date(data?.createdDate).getTime())
  );

  return (
    <Stack
      direction={"column"}
      sx={{
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* chat */}
      {filterFromData?.length > 0 &&
      emailIds !== null &&
      localStorage.getItem("emailIds") !== null ? (
        <Box
          sx={{
            width: "100%",
            height: { xl: "96vh", lg: "96vh", md: "96vh", xs: "90vh" },
            overflowY: "auto",
            zIndex: "0",
          }}
        >
          {filterFromData?.map((data, key) => {
            const isLatestChat =
              new Date(data?.createdDate).getTime() === latestChatDate;

            const ToValue =
              data?.recipients?.filter(
                (item, key) => item?.recipientType === "To"
              ) || [];
            const CcValue =
              data?.recipients?.filter(
                (item, key) => item?.recipientType === "CC"
              ) || [];
            const BccValue =
              data?.recipients?.filter(
                (item, key) => item?.recipientType === "BCC"
              ) || [];
            const From =
              data?.recipients?.filter(
                (item, key) => item?.recipientType === "From"
              ) || [];

            return (
              <Box
                key={key}
                sx={{
                  width: "100%",
                  zIndex: "0",
                }}
              >
                <Divider />
                <Box
                  sx={{
                    width: "100%",
                    p: 2,
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: { md: "row", xs: "column-reverse" },
                      justifyContent: "space-between",
                      // alignItems: "center",
                    }}
                  >
                    <Stack direction={"row"}>
                      <Avatar
                        src={`${s?.baseUrl}/${data?.profilePicPath}`}
                        alt={`${data?.firstName} ${data?.lastName}`}
                        sx={{
                          marginRight: 2,
                          width: { md: "50px", xs: "50px" },
                          height: { md: "50px", xs: "50px" },
                        }}
                      />

                      <Stack
                        direction={"column"}
                        sx={{
                          justifyContent: "center",
                          alignItems: "start",
                          marginLeft: { md: "20px", xs: "5px" },
                        }}
                      >
                        <Box display={"flex"}>
                          {data?.emailType === "Internal" && (
                            <Typography
                              variant="h5"
                              sx={{
                                fontFamily: "Poppins",
                                fontWeight: 600,
                                fontSize: "14px",
                                lineHeight: "20px",
                              }}
                            >
                              {`${data?.firstName} ${data?.lastName} `}
                            </Typography>
                          )}
                          <Typography
                            variant="h5"
                            sx={{
                              fontFamily: "Poppins",
                              fontWeight: 400,
                              fontSize: "14px",
                              lineHeight: "20px",
                            }}
                          >
                            {`<${data?.email || data?.recipients[0]?.email}>`}
                            {/* {data?.emailType === "Internal"
                              ? `<${data?.email}>`
                              : `<${data?.recipients[0]?.email}>`} */}
                          </Typography>
                        </Box>
                        {ToValue?.length > 0 && (
                          <Box display={"flex"} gap={"3px"}>
                            <Typography
                              variant="h2"
                              sx={{
                                fontFamily: "Poppins",
                                fontWeight: 600,
                                fontSize: "14px",
                                lineHeight: "20px",
                              }}
                            >
                              To:{" "}
                            </Typography>
                            <Typography
                              variant="h2"
                              sx={{
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "14px",
                                lineHeight: "20px",
                              }}
                            >
                              {" "}
                              {ToValue?.map((item) =>
                                data?.emailType === "Internal"
                                  ? `${item?.firstName} ${item?.lastName}`
                                  : `${item?.email}`
                              ).join(", ")}
                            </Typography>
                          </Box>
                        )}
                        <Box>
                          {CcValue?.length > 0 && (
                            <Box display={"flex"} gap={"3px"}>
                              <Typography
                                variant="h2"
                                sx={{
                                  fontFamily: "Poppins",
                                  fontWeight: 600,
                                  fontSize: "14px",
                                  lineHeight: "20px",
                                  // marginLeft: "-2px",
                                }}
                              >
                                Cc:{" "}
                              </Typography>
                              <Typography
                                variant="h2"
                                sx={{
                                  fontFamily: "Poppins",
                                  fontWeight: 400,
                                  fontSize: "14px",
                                  lineHeight: "20px",
                                  // marginLeft: "-2px",
                                }}
                              >
                                {CcValue?.map((item) =>
                                  data?.emailType === "Internal"
                                    ? `${item?.firstName} ${item?.lastName}`
                                    : `${item?.email}`
                                ).join(", ")}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                        <Box>
                          {BccValue?.length > 0 && (
                            <Box display={"flex"} gap={"3px"}>
                              <Typography
                                variant="h2"
                                sx={{
                                  fontFamily: "Poppins",
                                  fontWeight: 600,
                                  fontSize: "14px",
                                  lineHeight: "20px",
                                  // marginLeft: "-2px",
                                }}
                              >
                                Bcc:{" "}
                              </Typography>
                              <Typography
                                variant="h2"
                                sx={{
                                  fontFamily: "Poppins",
                                  fontWeight: 400,
                                  fontSize: "14px",
                                  lineHeight: "20px",
                                  // marginLeft: "-2px",
                                }}
                              >
                                {BccValue?.map((item) =>
                                  data?.emailType === "Internal"
                                    ? `${item?.firstName} ${item?.lastName}`
                                    : `${item?.email}`
                                ).join(", ")}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                        <Box display={"flex"} gap={"3px"}>
                          <Typography
                            variant="h2"
                            sx={{
                              fontFamily: "Poppins",
                              fontWeight: 600,
                              fontSize: "14px",
                              lineHeight: "20px",
                            }}
                          >
                            Subject:
                          </Typography>
                          <Typography
                            variant="h2"
                            sx={{
                              fontFamily: "Poppins",
                              fontWeight: 400,
                              fontSize: "14px",
                              lineHeight: "20px",
                            }}
                          >
                            {data?.subject}
                          </Typography>
                        </Box>
                        {/* Body */}
                      </Stack>
                    </Stack>

                    <Stack>
                      {isLatestChat && (
                        <Stack
                          direction="row"
                          spacing={1}
                          sx={{
                            justifyContent: "center",
                          }}
                        >
                          <IconButton
                            onClick={() => {
                              isFavMail({
                                emailId: [data?.emailId],
                                UserId: UserId,
                                IsFav:
                                  From[0]?.isFavorites === true ||
                                  BccValue[0]?.isFavorites === true ||
                                  CcValue[0]?.isFavorites === true ||
                                  ToValue[0]?.isFavorites === true
                                    ? false
                                    : true,
                                    CreatedBy: parseInt(UserId)

                              });
                            }}
                          >
                            {From[0]?.isFavorites === true ||
                            BccValue[0]?.isFavorites === true ||
                            CcValue[0]?.isFavorites === true ||
                            ToValue[0]?.isFavorites === true ? (
                              <StarIcon
                                fontSize="small"
                                style={{ color: "#FFD700" }}
                              />
                            ) : (
                              <StarBorderIcon
                                fontSize="small"
                                sx={{
                                  color: "#757575",
                                }}
                              />
                            )}
                          </IconButton>
                          <Tooltip title="Reply">
                            <IconButton
                              sx={{
                                color: "#316AB7",
                                width: "30px",
                                height: "30px",
                              }}
                            >
                              <UndoRoundedIcon
                                fontSize="small"
                                onClick={() => {
                                  setReplyProfilePic(data?.profilePicPath);
                                  setReplyName(
                                    `${data?.firstName} ${data?.lastName}`
                                  );
                                  setReplyEmail(
                                    data?.email || data?.recipients[0]?.email
                                  );
                                  setSenderId(data?.senderId);
                                  // setParentEmailId(data?.emailId);
                                  setSubject(data?.subject);
                                  setBodyData(data?.body);
                                  handleReplyClick();
                                  setEmailReplyId(data?.emailId);
                                  setEmailType(data?.emailType);
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Forward">
                            <IconButton
                              sx={{
                                color: "#316AB7",
                                width: "30px",
                                height: "30px",
                              }}
                            >
                              <RedoRoundedIcon
                                fontSize="small"
                                onClick={() => {
                                  handleForwardClick();
                                  setToEmail(ToValue[0]?.email);
                                  setToName(
                                    `${ToValue[0]?.firstName} ${ToValue[0]?.lastName}`
                                  );
                                  setDateTime(data?.createdDate);
                                  setReplyName(
                                    `${From[0]?.firstName} ${From[0]?.lastName}`
                                  );
                                  setReplyEmail(From[0]?.email || "");
                                  setSubject(data?.subject);
                                  setBodyData(data?.body);
                                  setReplyProfilePic(data?.profilePicPath);
                                  setEmailReplyId(data?.emailId);
                                  setEmailType(data?.emailType);
                                }}
                              />
                            </IconButton>
                          </Tooltip>

                          <IconButton>
                            <DeleteIcon
                              onClick={() => {
                                deleteMail({
                                  emailId: [parentEmailId],
                                  UserId: UserId,
                                  IsTrash: true,
                                  CreatedBy: parseInt(UserId)
                                });
                              }}
                              color="error"
                              fontSize="small"
                            />
                          </IconButton>

                          {/* <IconButton
                            sx={{
                              color: "#316AB7",
                              width: "30px",
                              height: "30px",
                            }}
                            onClick={(event) => {
                              handleClick(event);
                              setParentEmailId(data?.emailId);
                            }}
                          >
                            <MoreHorizOutlinedIcon />
                          </IconButton>
                          <Menu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          >
                            <MenuItem onClick={handleClose}>Mark as Unread</MenuItem>
                            <MenuItem onClick={handleClose}>
                            Move to Folder
                          </MenuItem>
                            <MenuItem
                              onClick={() => {
                                deleteMail({
                                  emailId: [parentEmailId],
                                  UserId: UserId,
                                  IsTrash: true,
                                });
                                handleClose();
                              }}
                            >
                              <Typography color="error">Delete</Typography>
                            </MenuItem>
                          </Menu> */}
                        </Stack>
                      )}
                      <Stack
                        direction={"row"}
                        marginLeft={"30px"}
                        spacing={6}
                        sx={{
                          justifyContent: "end",
                          alignItems: "top",
                        }}
                      >
                        <Typography
                          variant="h6"
                          sx={{
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: "12px",
                            lineHeight: "16px",
                            paddingRight: "10px",
                            color: "#808080",
                          }}
                        >
                          {new Date(data?.createdDate).toLocaleDateString(
                            "en-GB",
                            {
                              weekday: "short", // 'Mon'
                              day: "2-digit", // '21'
                              month: "2-digit", // '10'
                              year: "numeric", // '2024'
                            }
                          ) +
                            " " +
                            new Date(data?.createdDate).toLocaleTimeString(
                              "en-GB",
                              {
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: false,
                              }
                            )}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Box>
                <Divider sx={{ margin: "0 auto", width: "80%" }} />
                <Box
                  sx={{
                    padding: {
                      md: "0 2rem 0 6.4rem",
                      xs: "0 2rem 0 2rem",
                    },
                  }}
                >
                  <Box
                    dangerouslySetInnerHTML={{ __html: data?.body }}
                    style={{ fontSize: "14px", fontFamily: "Poppins" }}
                  />
                </Box>
                {/* Attachments */}
                {data?.attachments?.length > 0 && (
                  <Box
                    display={"flex"}
                    flexWrap={"wrap"}
                    gap={"10px"}
                    sx={{
                      padding: {
                        md: "10px 2rem 0 6.4rem",
                        xs: "10px 2rem 0 2rem",
                      },
                      overflowX: "hidden",
                      overflowY: "auto",
                      height: "20%",
                      width: "100%",
                      mt: "-1px",
                    }}
                  >
                    {data?.attachments?.map((item, key) => {
                      const isPdf = item?.attachName
                        ?.toLowerCase()
                        .endsWith(".pdf");
                      const isExcel =
                        item?.attachName?.toLowerCase().endsWith(".xlsx") ||
                        item?.attachName?.toLowerCase().endsWith(".xls");
                      return (
                        <Box
                          key={key}
                          display={"flex"}
                          justifyContent={"space-between"}
                          border={"1px solid #00000026"}
                          p={"5px"}
                          borderRadius={"5px"}
                          height={"45px"}
                          width={"13rem"}
                          alignItems={"center"}
                        >
                          <Box
                            display={"flex"}
                            gap={"5px"}
                            alignItems={"center"}
                            width={"12rem"}
                            height={"35px"}
                          >
                            {(isPdf || isExcel) && (
                              <Box
                                sx={{ width: "25px", height: "25px" }}
                                component={"img"}
                                src={isPdf ? pdf : isExcel ? excel : null}
                              />
                            )}
                            <Tooltip
                              arrow
                              placement="top-start"
                              title={item?.attachName}
                            >
                              <Typography
                                fontSize={"12px"}
                                fontWeight={"500px"}
                              >
                                {item?.attachName?.length > 23
                                  ? item?.attachName?.slice(0, 23)
                                  : item?.attachName}
                              </Typography>
                            </Tooltip>
                          </Box>
                          {/* download icon */}
                          <IconButton
                            onClick={() => {
                              handleDownload(item.attachPath);
                            }}
                            sx={{ width: "20px", height: "20px" }}
                          >
                            <ArrowCircleDownIcon />
                          </IconButton>
                        </Box>
                      );
                    })}
                  </Box>
                )}

                {emailReplyId === data?.emailId && (
                  <Box
                    sx={{
                      zIndex: "1",
                      m: "20px 10px",
                    }}
                  >
                    <Reply
                      replyShow={replyShow}
                      setReplyShow={setReplyShow}
                      forwardShow={forwardShow}
                      setForwardShow={setForwardShow}
                      replyName={replyName}
                      replyprofilePic={replyprofilePic}
                      replyEmail={replyEmail}
                      subject={subject}
                      parentEmailId={parentEmailId}
                      senderId={senderId}
                      bodyData={bodyData}
                      setBodyData={setBodyData}
                      setDateTime={setDateTime}
                      dateTime={dateTime}
                      // forward
                      setToName={setToName}
                      setToEmail={setToEmail}
                      toEmail={toEmail}
                      toName={toName}
                      emailTypeReply={emailType}
                    />
                  </Box>
                )}
                {/* <Divider sx={{ marginTop: "5px" }} /> */}
              </Box>
            );
          })}
        </Box>
      ) : (
        // {}
        <Box
          minHeight={"150px"}
          width={"100%"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {emailsLength > 0 ? (
            <Box
              display={"flex"}
              alignItems={"center"}
              flexDirection={"column"}
            >
              <DraftsOutlinedIcon fontSize="large" />
              <Typography>Select an item to read</Typography>
            </Box>
          ) : (
            <Typography>No Email</Typography>
          )}
        </Box>
      )}
    </Stack>
  );
}

export default EmailWindow;
