import {
  getData,
  postData,
  postFormData,
  postDataAnonymously,
  putFormData,
} from "./apiCalls";
import { settings as s } from "../Settings";
import { replace } from "react-router-dom";
// login
export const login = async (data) => {
  debugger;
  try {
    const response = await postDataAnonymously(`${s.chatuser.login}`, data);
    return response;
  } catch (err) {
    return null;
  }
};
// send otp
export const sendPassOtp = async (email) => {
  try {
    const response = await postDataAnonymously(
      `${s.chatuser.sendOtp.replace(`[email]`, email)}`
    );
    return response;
  } catch (err) {
    return null;
  }
};
// verify otp
export const verifyOtp = async (email, otp) => {
  try {
    const response = await postDataAnonymously(
      `${s.chatuser.verifyOTP.replace(`[email]`, email).replace(`[otp]`, otp)}`
    );
    return response;
  } catch (err) {
    return null;
  }
};
// resetPassword
export const resetPassword = async (email, password) => {
  try {
    const response = await postDataAnonymously(
      `${s.chatuser.resetPassword
        .replace(`[email]`, email)
        .replace(`[password]`, password)}`
    );
    return response;
  } catch (err) {
    return null;
  }
};
// resetPassword
export const refresh_Token = async (refreshToken) => {
  try {
    const response = await postData(
      `${s.chatuser.refreshToken.replace(`[refreshAccessToken]`, refreshToken)}`
    );
    return response;
  } catch (err) {
    return null;
  }
};
// getData
export const getUsers = async (page, pageSize, username, depId, status) => {
  try {
    const url = `${s.chatuser.getUsers
      .replace(`[page]`, page)
      .replace(`[pageSize]`, pageSize)
      .replace(`[username]`, username)
      .replace(`[depId]`, depId)
      .replace(`[status]`, status)}`;
    const response = await getData(url);
    return response;
  } catch (err) {
    return null;
  }
};
// getUsersList
export const getUsersList = async () => {
  try {
    const url = `${s.chatuser.getUsersList}`;
    const response = await getData(url);
    return response;
  } catch (err) {
    return null;
  }
};
// region getFolders
export const getFolders = async (userId) => {
  try {
    const url = `${s.emails.getUserFolders.replace(`$[UserId]`, userId)}`;
    const response = await getData(url);
    return response;
  } catch (err) {
    return null;
  }
};
// region AddFolders
export const addFolders = async (data) => {
  try {
    const response = await postData(`${s.emails.addUserFolder}`, data);
    return response;
  } catch (err) {
    return null;
  }
};
// region UpdateFolders
export const updateFolders = async (data) => {
  try {
    const response = await postData(`${s.emails.updateUserFolder}`, data);
    return response;
  } catch (error) {
    return null;
  }
};
// region DelTrashEmails
export const delTrashEmails = async (data) => {
  try {
    const response = await postData(`${s.emails.updateEmail}`, data);
    return response;
  } catch (err) {
    return null;
  }
};
// region getEmailList or inbox
export const getEmailList = async (userId, PageNumber, Rows) => {
  try {
    const url = `${s.emails.getEmailsList
      .replace(`$[UserId]`, userId)
      .replace(`$[PageNumber]`, PageNumber)
      .replace(`$[Rows]`, Rows)}`;
    const response = await getData(url);
    return response;
  } catch (err) {
    return null;
  }
};
// region getEmailListSent
export const getEmailListSent = async (userId, PageNumber, Rows, IsSent) => {
  try {
    const url = `${s.emails.getEmailsListSent
      .replace(`$[UserId]`, userId)
      .replace(`$[PageNumber]`, PageNumber)
      .replace(`$[Rows]`, Rows)
      .replace(`$[IsSent]`, IsSent)}`;
    const response = await getData(url);
    return response;
  } catch (err) {
    return null;
  }
};
// region getEmailListDraft
export const getEmailListDraft = async (userId, PageNumber, Rows, IsDraft) => {
  try {
    const url = `${s.emails.getEmailsListDraft
      .replace(`$[UserId]`, userId)
      .replace(`$[PageNumber]`, PageNumber)
      .replace(`$[Rows]`, Rows)
      .replace(`$[IsDraft]`, IsDraft)}`;
    const response = await getData(url);
    return response;
  } catch (err) {
    return null;
  }
};
// region getEmailListFav
export const getEmailListFav = async (userId, PageNumber, Rows, IsFav) => {
  try {
    const url = `${s.emails.getEmailsListFav
      .replace(`$[UserId]`, userId)
      .replace(`$[PageNumber]`, PageNumber)
      .replace(`$[Rows]`, Rows)
      .replace(`$[IsFav]`, IsFav)}`;
    const response = await getData(url);
    return response;
  } catch (err) {
    return null;
  }
};
// region getEmailListTrash
export const getEmailListTrash = async (userId, PageNumber, Rows, IsTrash) => {
  try {
    const url = `${s.emails.getEmailsListTrash
      .replace(`$[UserId]`, userId)
      .replace(`$[PageNumber]`, PageNumber)
      .replace(`$[Rows]`, Rows)
      .replace(`$[IsTrash]`, IsTrash)}`;
    const response = await getData(url);
    return response;
  } catch (err) {
    return null;
  }
};
// region getEmailDetailList
export const getEmailDetailList = async (EmailId) => {
  try {
    const url = `${s.emails.getEmailDetail.replace(`$[EmailId]`, EmailId)}`;
    const response = await getData(url);
    return response;
  } catch (err) {
    return null;
  }
};
// region getIndividualFoldersEmailsList
export const getIndividualFoldersEmails = async (
  userId,
  PageNumber,
  Rows,
  foldersId
) => {
  try {
    const url = `${s.emails.getIndividualFoldersEmailsList
      .replace(`$[UserId]`, userId)
      .replace(`$[PageNumber]`, PageNumber)
      .replace(`$[Rows]`, Rows)
      .replace(`$[FolderId]`, foldersId)}`;
    const response = await getData(url);
    return response;
  } catch (err) {
    return null;
  }
};
// region Update Email
export const updateEmail = async (data) => {
  try {
    const response = await postData(`${s.emails.updateEmail}`, data);
    return response;
  } catch (error) {
    return null;
  }
};

// region Move to folder
export const moveToFolder = async (data) => {
  try {
    const response = await postData(`${s.emails.moveToFolder}`, data);
    return response;
  } catch (error) {
    return null;
  }
};

// sent Email postFormData
export const sentEmailData = async (data) => {
  try {
    const response = await postFormData(`${s.emails.sendEmail}`, data);
    return response;
  } catch (err) {
    return null;
  }
};
// getUsersCSV
export const getUsersCSV = async (page, pageSize, username, depId, status) => {
  try {
    const url = `${s.chatuser.getUsersCSV
      .replace(`[page]`, page)
      .replace(`[pageSize]`, pageSize)
      .replace(`[username]`, username)
      .replace(`[depId]`, depId)
      .replace(`[status]`, status)}`;
    const response = await getData(url);
    return response;
  } catch (err) {
    return null;
  }
};
// region getUsers Contact Module Data to Excel
export const ExportUserContacts = async (UserId) => {
  try {
    const url = `${s.chatuser.ExportUserContactsToExcel.replace(
      `[UserId]`,
      UserId
    )}`;
    const response = await getData(url);
    return response;
  } catch (err) {
    return null;
  }
};
// getDataById
export const getUsersById = async (userId) => {
  try {
    const url = `${s.chatuser.getUserById.replace(`[UserId]`, userId)}`;
    const response = await getData(url);
    return response;
  } catch (err) {
    return null;
  }
};

// getContactById
export const getUserContactsById = async (userId) => {
  try {
    const url = `${s.chatuser.getUserContactsById.replace(`[UserId]`, userId)}`;
    const response = await getData(url);
    return response;
  } catch (err) {
    return null;
  }
};
// getContactsByParams
export const getUserContactsByParams = async (
  userId,
  Rows,
  PageNumber,
  isFav,
  isExternal,
  UserName
) => {
  try {
    const url = `${s.chatuser.getUserContactsByParams
      .replace(`[UserId]`, userId)
      .replace("[Rows]", Rows)
      .replace("[PageNumber]", PageNumber)
      .replace("[isFav]", isFav)
      .replace("[IsExternal]", isExternal)
      .replace("[UserName]", UserName)}`;
    const response = await getData(url);
    return response;
  } catch (err) {
    return null;
  }
};
// getData Department
export const getDepertmentListData = async () => {
  try {
    const url = `${s.chatuser.getDepertmentList}`;
    const response = await getData(url);
    return response;
  } catch (err) {
    return null;
  }
};
// getData Desination
export const getDesignationListById = async (DepertmentId) => {
  try {
    const url = `${s.chatuser.getDesignationListById.replace(
      `[DepertmentId]`,
      DepertmentId
    )}`;
    const response = await getData(url);
    return response;
  } catch (err) {
    return null;
  }
};

//get Call Recordings
export const callRecordings = async (autoRecording, sid) => {
  try {
    const url = `${s.chats.getCallRecordings
      .replace(`[autoRecording]`, autoRecording)
      .replace(`$[sid]`, sid)}`;
    const response = await getData(url);
    return response;
  } catch (err) {
    return null;
  }
};
// addData by post method
export const addUser = async (data) => {
  try {
    const response = await postData(`${s.chatuser.addUser}`, data);
    return response;
  } catch (err) {
    return null;
  }
};
// addData by post method
export const updateUserContact = async (data) => {
  try {
    const response = await postData(`${s.chatuser.updateUserContact}`, data);
    return response;
  } catch (err) {
    return null;
  }
};
// Update Data by post method
export const updateUser = async (data) => {
  try {
    const response = await postData(`${s.chatuser.updateUser}`, data);
    return response;
  } catch (err) {
    return null;
  }
};
// add postFormData
export const addUserFormData = async (data) => {
  try {
    const response = await postFormData(`${s.chatuser.addUser}`, data);
    return response;
  } catch (err) {
    return null;
  }
};
// update postformdata
export const updateUserFormData = async (data) => {
  try {
    const response = await postFormData(`${s.chatuser.updateUser}`, data);
    return response;
  } catch (error) {
    return null;
  }
};
// get roles
export const getRolesList = async () => {
  try {
    const url = `${s.chatuser.getRolesList}`;
    const response = await getData(url);
    return response;
  } catch (err) {
    return null;
  }
};

export const addUserContacts = async (data) => {
  try {
    const response = await postData(`${s.chatuser.addUserContacts}`, data);
    return response;
  } catch (error) {
    return null;
  }
};

//Chats
export const getUserContactStatusLists = async (userId) => {
  try {
    const url = `${s.chatuser.getUserContactStatusLists}`.replace(
      `$[userId]`,
      userId
    );
    const response = await getData(url);
    return response;
  } catch (error) {
    return null;
  }
};

//Chats
export const getUserChats = async (userId, isFav) => {
  try {
    const url = `${s.chats.getUserChats}`
      .replace(`$[userId]`, userId)
      .replace(`$[isFav]`, isFav);
    const response = await getData(url);
    return response;
  } catch (error) {
    return null;
  }
};
export const getSearchUserChats = async (userId, isFav, IsAdmin) => {
  try {
    const url = `${s.chats.getSearchUserChats}`
      .replace(`$[userId]`, userId)
      .replace(`$[isFav]`, isFav)
      .replace(`$[IsAdmin]`, IsAdmin);
    const response = await getData(url);
    return response;
  } catch (error) {
    return null;
  }
};

export const addChat = async (chatData) => {
  try {
    const response = await postFormData(`${s.chats.addChat}`, chatData);
    return response;
  } catch (error) {
    return null;
  }
};

export const updateChat = async (chatData) => {
  try {
    const response = await postFormData(`${s.chats.updateChat}`, chatData);
    return response;
  } catch (error) {
    return null;
  }
};

export const updateFavChat = async (favChatData) => {
  try {
    const response = await postData(`${s.chats.updateFavChat}`, favChatData);
    return response;
  } catch (error) {
    return null;
  }
};
// region -- leave group
export const RemoveChatParticipant = async (formData) => {
  try {
    const response = await postData(
      `${s.chats.removeChatParticipant}`,
      formData
    );
    return response;
  } catch (error) {
    console.error("Error in RemoveChatParticipant:", error);
    return null;
  }
};
// region -- Add Participant Group
export const AddChatParticipant = async (formData) => {
  try {
    const response = await postData(`${s.chats.addChatParticipant}`, formData);
    return response;
  } catch (error) {
    console.error("Error in RemoveChatParticipant:", error);
    return null;
  }
};
export const getChatMessages = async (userId, chatId, isAdmin) => {
  try {
    const url = `${s.chats.getChatMessages}`
      .replace(`$[userId]`, userId)
      .replace(`$[chatId]`, chatId)
      .replace(`$[isAdmin]`, isAdmin);
    const response = await getData(url);
    return response;
  } catch (error) {
    return null;
  }
};
export const getChatMessagesUser = async (userId, chatId) => {
  try {
    const url = `${s.chats.getChatMessagesUser}`
      .replace(`$[userId]`, userId)
      .replace(`$[chatId]`, chatId);
    const response = await getData(url);
    return response;
  } catch (error) {
    return null;
  }
};

export const getUserContacts = async (userId) => {
  try {
    const url = `${s.chats.getUserContacts}`.replace(`$[userId]`, userId);
    const response = await getData(url);
    return response;
  } catch (error) {
    return null;
  }
};
export const getSearchUserContacts = async (userId, deletedContacts) => {
  try {
    const url = `${s.chats.getSearchUserContacts}`
      .replace(`$[userId]`, userId)
      .replace(`$[IsAdmin]`, deletedContacts);
    const response = await getData(url);
    return response;
  } catch (error) {
    return null;
  }
};

export const addChatMessage = async (chatData) => {
  try {
    const response = await postFormData(`${s.chats.addChatMessage}`, chatData);
    return response;
  } catch (error) {
    return null;
  }
};
// region -- Attachments
export const GetChatAttachments = async (ChatId) => {
  try {
    const url = `${s.chats?.GetChatAttachments?.replace(`$[ChatId]`, ChatId)}`;
    const response = await getData(url);
    return response;
  } catch (err) {
    return null;
  }
};
//Call History API
export const userCallHistory = async (clientIdentifier, conference) => {
  try {
    const url = `${s.chats?.getCallHistory
      ?.replace(`$[clientIdentifier]`, clientIdentifier)
      .replace(`$[conference]`, conference)}`;
    const response = await getData(url);
    return response;
  } catch (error) {
    console.error("CallHistoryErr", error);
    return null;
  }
};
// region -- ChatPaticcipants
export const getChatParticipants = async (ChatId, UserId) => {
  try {
    const url = `${s.chats?.GetChatParticipants?.replace(
      `$[ChatId]`,
      ChatId
    ).replace(`$[UserId]`, UserId)}`;
    const response = await getData(url);
    return response;
  } catch (err) {
    console.error("Error fetching chat participants:", err); // Optional: Log the error for debugging
    return null;
  }
};

// Twillio Get Token

export const getTwilioUserToken = async (identity) => {
  try {
    const url = `${s.twilio.getToken}=${identity}`;
    const response = await getData(url);
    return response;
  } catch (err) {
    return null;
  }
};
// Twillio Get Video Call Token

export const getVideoCallToken = async (identity) => {
  try {
    const url = `${s.twilio.getVideoCallToken.replace(
      "$[identity]",
      identity
    )}`;
    const response = await getData(url);
    return response;
  } catch (err) {
    return null;
  }
};

// Add Participant

export const addParticipantPostAPI = async (data) => {
  try {
    const response = await postDataAnonymously(
      `${s.twilio.addParticipant}`,
      data
    );
    return response;
  } catch (err) {
    return null;
  }
};

// start recording  Call Api
export const startRecordingAPI = async (data) => {
  try {
    const response = await postDataAnonymously(
      `${s.twilio.startRecordingCall}`,
      data
    );
    return response;
  } catch (err) {
    return null;
  }
};
// stop recording Call Api
export const stopRecordingAPI = async (data) => {
  try {
    const response = await postDataAnonymously(
      `${s.twilio.stopRecordingCall}`,
      data
    );
    return response;
  } catch (err) {
    return null;
  }
};
// Get Recording Call From API
export const getRecordingCallAPI = async () => {
  try {
    const url = `${s.twilio.getRecordingCall}`;
    const response = await getData(url);
    return response;
  } catch (err) {
    return null;
  }
};
// Fetching Call Details By User Login ID

export const FetchingCallDetailAPI = async (limit) => {
  try {
    // postDataAnonymously
    const response = await getData(`${s.twilio.fetchingCallDetail}`);
    return response;
  } catch (err) {
    return null;
  }
};

// Outbound Call API
export const OutboundCallAPI = async (data) => {
  try {
    const response = await postDataAnonymously(
      `${s.twilio.outboundCall}`,
      data
    );
    return response;
  } catch (err) {
    return null;
  }
};

// inprogress Call API
export const InProgressCallAPI = async (clientId) => {
  try {
    const response = await postDataAnonymously(
      `${s.twilio.inprogressCall}${clientId}`
    );
    return response;
  } catch (err) {
    return null;
  }
};
// in Group Call API
export const InGroupCall = async (data) => {
  try {
    const response = await postDataAnonymously(`${s.twilio.groupCall}`, data);
    return response;
  } catch (err) {
    return null;
  }
};
export const fetchCallLogsDetails = async (userID) => {
  try {
    const response = await getData(
      `${s.twilio.fetchCallLogsDetails.replace(`$[userID]`, userID)}`
    );
    return response;
  } catch (err) {
    return null;
  }
};
export const addCallRecords = async (data) => {
  try {
    const response = await postData(`${s.chatuser.addCallRecords}`, data);
    return response;
  } catch (err) {
    return null;
  }
};
// in Create Room API
export const CreateRoom = async (data) => {
  try {
    const response = await postDataAnonymously(`${s.twilio.createRoom}`, data);
    return response;
  } catch (err) {
    return null;
  }
};
