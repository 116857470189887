import React from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useTwilio } from "../../../../../services/context/TwilioContext";

export default function NoDeviceModal() {
  const { noDeviceModal, closeNoDeviceModal } = useTwilio();
  return (
    <>
      <Modal
        open={noDeviceModal.isOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={noDeviceModalStyle}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "auto",
                textAlign: "center",
              }}
            >
              <Typography
                variant="h6"
                color={"#085F99"}
                fontWeight={"bold"}
                fontSize={"18px"}
              >
                {noDeviceModal.title}
              </Typography>
            </Box>
            <IconButton
              sx={{ padding: "0", color: "#085F99" }}
              onClick={closeNoDeviceModal}
            >
              <HighlightOffIcon />
            </IconButton>
          </Box>
          <Divider orientation="horizontal" flexItem sx={{ marginY: "10px" }} />
          <Typography
            id="user-status-description"
            variant="body2"
            sx={{ mb: 2 }}
          >
            {noDeviceModal.message}
          </Typography>
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button variant="outlined" onClick={closeNoDeviceModal}>
              {noDeviceModal.buttonText}
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
}
// Call Modal Style
const noDeviceModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: 2,
  border: "2px solid #FFFFFF",
  p: 2,
};
