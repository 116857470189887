import React, { useEffect } from "react";
import { Snackbar, Alert, Box, Avatar, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { hideNotification } from "../../../services/store/slice/NotificationSlice";
import {
  setChat_Id,
  setSelectedContactName,
  setSelectedContactProfileImage,
} from "../../../services/store/slice/ChatSlice";
import { settings as s } from "../../../services/Settings";
import addNotification from "react-push-notification";
import avatarImg from "../../../../assets/img/Profile.png";

const Notification = () => {
  const userId = localStorage.getItem("user_id");
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Hook for navigation
  const { open, message, chatId } = useSelector((state) => state.notification);
  const chat_Id = useSelector((state) => state.chat.chat_Id);

  const handleClose = () => {
    dispatch(hideNotification());
  };

  const handleClick = () => {
    dispatch(hideNotification());
    if (chatId !== null) {
      // localStorage.setItem("chatIdLS", chatId)
      // dispatch(setChat_Id(chatId));
      navigate(`/chats`);
    } else {
      navigate("/chats");
    }
  };

  // handle Notification Fun
  const handleNotifyFun = (message) => {
    addNotification({
      title: message?.senderName,
      message:
        message?.attachments?.length > 0
          ? "Attachment"
          : message?.messageContent,
      theme: "dark",
      position: "bottom-left",
      duration: 10000,
      icon: message?.senderProfilePic
        ? `${s.baseUrl}${message?.senderProfilePic}`
        : avatarImg,
      native: true,
      onClick: () => {
        dispatch(setChat_Id(chatId));
        dispatch(setSelectedContactName(message?.senderName));
        dispatch(setSelectedContactProfileImage(message?.senderProfilePic));
        navigate(`/chats`);
      },
    });
  };

  const getChatAlert = () => {
    if (open && chat_Id != chatId) {
      handleNotifyFun(message || []);
      dispatch(hideNotification());
    }
  };

  useEffect(() => {
    getChatAlert();
  }, [message?.senderId, chatId, userId, chat_Id]);
  return (
    <Snackbar
      // open={open}
      open={false}
      autoHideDuration={4000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <Alert
        onClose={handleClose}
        severity={"info"}
        variant="filled"
        icon={false}
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          bgcolor: "gray",
          cursor: "pointer",
        }}
      >
        <Box display={"flex"} alignItems={"center"} onClick={handleClick}>
          <Avatar
            src={`${s?.baseUrl}/${message?.senderProfilePic}`}
            alt={message[0]?.senderName}
            sx={{
              marginRight: 2,
              width: { md: "35px", xs: "35px" },
              height: { md: "35px", xs: "35px" },
            }}
          />

          <Box>
            <Typography sx={{ fontSize: "12px" }}>
              {message?.senderName}
            </Typography>
            <Typography sx={{ fontSize: "12px" }}>
              {message?.messageContent?.length > 12
                ? message?.messageContent?.slice(0, 12) + "..."
                : message?.messageContent}
            </Typography>
          </Box>
        </Box>
      </Alert>
    </Snackbar>
  );
};

export default Notification;
