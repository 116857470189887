import * as signalR from "@microsoft/signalr";
import {
  deleteMessage,
  newChatRecieve,
  receiveMessage,
  receiveReadReceipt,
  receiveTyping,
  removeChat,
  setUserOnline,
  updateEditedMessage,
  setReaction,
  setRenderReaction,
  addSingleContact,
  addReactions,
  addSingleAllContacts,
  setEventsRealTime,
} from "../store/slice/ChatSlice";
import store from "../store/Store";
import { settings as s } from "../Settings";
import { showNotification } from "../store/slice/NotificationSlice";
import {
  setRealTimeEmailData,
  setRenderNewEmail,
} from "../store/slice/EmailSlice";
import {
  setCallStatus,
  setIsReceiveMuted,
  setIsReceiveRecording,
  setIsSendingRoomData,
  setIsShareScreen,
} from "../store/slice/CallSlice";

let connection = null;

// Initialize SignalR connection
export const initializeSignalRConnection = (handleOnConnection) => {
  // handle Notification Fun
  // const handleNotifyFun = (message) => {
  //   addNotification({
  //     title: message?.senderName[0],
  //     message: message?.messageContent,
  //     theme: "dark",
  //     position: "bottom-left",
  //     duration: 10000,
  //     icon: message?.senderProfilePic?.[0]
  //       ? `${s.baseUrl}${message.senderProfilePic[0]}`
  //       : avatarImg,
  //     native: true,
  //     // onClick: () => {
  //     //   window?.open("http://localhost:3000/", "_blank");
  //     // },
  //   });
  // };

  if (!connection) {
    connection = new signalR.HubConnectionBuilder()
      // .withUrl(`${s.baseUrl}/chatHub`, signalR.HttpTransportType.WebSockets) // Fallback to WebSockets
      .withUrl(`${s.baseUrl}/chatHub`) // Fallback to WebSockets
      .withAutomaticReconnect()
      .build();

    connection
      .start()
      .then(async () => {
        const user_id = localStorage.getItem("user_id");
        console.log("Connected to SignalR Hub");
        await sendUserOnline(user_id);

        // Log all events to check if they are being registered
        connection.onreconnected(async () => {
          console.log("Reconnected to SignalR Hub");
          await sendUserOnline(user_id);
        });
        connection.onreconnecting(() =>
          console.log("Reconnecting to SignalR Hub")
        );
        connection.onclose((error) =>
          console.error("Connection closed with error:", error)
        );

        // Check if ReceiveMessage event is registered
        console.log("Registering ReceiveMessage event");
        // region on ReceiveMessage
        connection.on("ReceiveMessage", (message, chatId, participant) => {
          const userId = localStorage.getItem("user_id");
          const payload = {
            message: message,
            chatId: chatId,
          };
          store.dispatch(receiveMessage(payload));
          // Get current location
          const currentPath = window.location.pathname;
          if (message?.senderId != userId) {
            store.dispatch(showNotification({ message, chatId }));
          }
        });
        // region on ReceiveMessage attachment
        connection.on("ReceiveMessages", (message, chatId, participant) => {
          const userId = localStorage.getItem("user_id");
          const payload = {
            message: message,
            chatId: chatId,
          };
          store.dispatch(receiveMessage(payload));
          // Get current location
          const currentPath = window.location.pathname;
          const userNotification = participant?.some(
            (item) => item?.userId == userId
          );
          // Show notification if not on the /chats page
          if (userNotification && message?.senderId != userId) {
            store.dispatch(showNotification({ message, chatId }));
          }
        });

        // region on emails Receive
        connection.on("ReceiveEmail", (email) => {
          const userId = localStorage.getItem("user_id");
          if (userId && email?.sender?.senderId?.toString() !== userId) {
            store.dispatch(setRenderNewEmail(true));
            store.dispatch(setRealTimeEmailData(email));
          }
        });
        // region on ReceiveEditMessage
        connection.on("ReceiveEditMessage", (message, chatId) => {
          const payload = {
            message,
            chatId,
          };
          store.dispatch(updateEditedMessage(payload));
        });

        connection.on("ReceiveDeleteMessage", (messageId, chatId) => {
          console.log("Received edited message for chatId:", chatId);
          const payload = {
            messageId,
            chatId,
          };
          // Dispatch action to update the message in the store
          store.dispatch(deleteMessage(payload));
        });
        // region On ReceiveNewChat
        connection.on("ReceiveNewChat", (chat) => {
          store.dispatch(newChatRecieve(chat));
        });

        connection.on("ReceiveRemoveChat", (chatId) => {
          store.dispatch(removeChat(chatId));
        });

        connection.on("ReceiveReadReceipt", (readerUser, chatId, messageId) => {
          const payload = {
            readerUser,
            chatId,
            messageId,
          };
          store.dispatch(receiveReadReceipt(payload));
        });
        connection.on("ReceiveTyping", (user_id, userName, chatId) => {
          const payload = {
            user_id,
            userName,
            chatId,
          };
          store.dispatch(receiveTyping(payload));
        });
        connection.on("UserOffline", (user_id) => {
          const payload = {
            userId: user_id,
            userOnline: false,
          };
          store.dispatch(setUserOnline(payload));
        });

        connection.on("UserOnline", (user_id) => {
          const payload = {
            userId: user_id,
            userOnline: true,
          };
          store.dispatch(setUserOnline(payload));
        });

        connection.on("ReceiveCall", (call) => {
          store.dispatch(setCallStatus(call));
        });

        connection.on("ReceiveCallStatus", () => {
          // console.log(`Call SID Zain:--->> ${callSid}, Status:--->> ${status}`);
        });
        // Receive Muted
        connection.on("ReceiveMute", (CallId, UserId, isMuted) => {
          if (CallId && UserId && isMuted === true) {
            const payload = { CallId, UserId, isMuted };
            store.dispatch(setIsReceiveMuted(payload));
          }
          if (CallId && UserId && isMuted === false) {
            const payload = { CallId, UserId, isMuted };
            store.dispatch(setIsReceiveMuted(payload));
          }
        });
        // Receive Recording
        connection.on(
          "ReceiveRecoring",
          (CallId, UserId, CallSid, IsRecord) => {
            if (CallId && UserId && CallSid && IsRecord === true) {
              const payload = { CallId, UserId, CallSid, IsRecord };
              store.dispatch(setIsReceiveRecording(payload));
            }
            if (CallId && UserId && CallSid && IsRecord === false) {
              const payload = { CallId, UserId, CallSid, IsRecord };
              store.dispatch(setIsReceiveRecording(payload));
            }
          }
        );
        // Receive Screen Sharing
        connection.on(
          "ReceiveShareScreen",
          (CallId, UserId, CallSid, IsStream) => {
            const payload = { CallId, UserId, CallSid, IsStream };
            store.dispatch(setIsShareScreen(payload));
          }
        );
        // Receive Group Call
        connection.on(
          "ReceiveGroupCall",
          (Type, RoomName, fromUserId, ToUserIds) => {
            debugger;
            const matchUserId = ToUserIds?.filter((i) => i == user_id);
            if (matchUserId) {
              const payload = { Type, RoomName, fromUserId, matchUserId };
              store.dispatch(setIsSendingRoomData(payload));
            }
          }
        );
        // region Add reaction On
        connection.on(
          "ReceiveReaction",
          (MessageId, UserId, Reaction, ReactionData) => {
            const payload = {
              MessageId: ReactionData?.messageId,
              messageReactionId: ReactionData?.messageReactionId,
              UserId: ReactionData?.reactorId,
              reaction: ReactionData?.reaction,
              reactor_Name: ReactionData?.name?.name,
              proifle_Pic: ReactionData?.name?.profilePicPath,
            };
            store.dispatch(setReaction(payload));
          }
        );
        // region Remove reaction On
        connection.on(
          "RemoveReaction",
          (userId, messageReactionId, reaction, ReactionData) => {
            const payload = {
              MessageId: ReactionData?.messageId,
              messageReactionId: ReactionData?.messageReactionId,
              UserId: ReactionData?.reactorId,
              reaction: ReactionData?.reaction,
              reactor_Name: ReactionData?.name?.name,
              proifle_Pic: ReactionData?.name?.profilePicPath,
            };
            store.dispatch(setReaction(payload));
          }
        );
        // region AddRemoveUser events
        connection.on("AddRemoveUser", (Events) => {
          store.dispatch(setEventsRealTime(Events));
        });
        // region get Contacts
        connection.on("ReceiveNewContact", (chat) => {
          store.dispatch(addSingleContact(chat));
          store.dispatch(addSingleAllContacts(chat));
        });
      })
      .catch((err) => {
        console.error("Error connecting to SignalR Hub:", err);
        if (err instanceof Error) {
          console.error("Detailed error:", err.message);
        }
      });
  }
};

// Function to check connection status
export const getConnectionStatus = () => {
  if (connection) {
    switch (connection.state) {
      case signalR.HubConnectionState.Disconnected:
        return "Disconnected";
      case signalR.HubConnectionState.Connecting:
        return "Connecting";
      case signalR.HubConnectionState.Connected:
        return "Connected";
      case signalR.HubConnectionState.Disconnecting:
        return "Disconnecting";
      default:
        return "Unknown";
    }
  } else {
    return "Connection not initialized";
  }
};

// Check User Online
export const sendUserOnline = async (user_id) => {
  if (getConnectionStatus() === "Connected") {
    try {
      await connection.invoke("SendUserOnline", parseInt(user_id));
      console.log("User is online");
    } catch (err) {
      console.error("Error sending message:", err);
      if (err instanceof Error) {
        console.error("Detailed error:", err.message);
      }
    }
  } else {
    console.error("SignalR connection is not established or not connected.");
  }
};

// Function to send message
export const sendMessageToHub = async (message) => {
  if (getConnectionStatus() === "Connected") {
    try {
      await connection.invoke("SendMessage", message);
      console.log("Message sent successfully");
    } catch (err) {
      console.error("Error sending message:", err);
      if (err instanceof Error) {
        console.error("Detailed error:", err.message);
      }
    }
  } else {
    console.error("SignalR connection is not established or not connected.");
  }
};

// Function to send message with attachments
export const sendMessageWithAttachment = async (MessageId, ChatId) => {
  if (getConnectionStatus() === "Connected") {
    try {
      await connection.invoke("SendMessageWithAttachment", MessageId, ChatId);
      console.log("Message sent successfully");
    } catch (err) {
      console.error("Error sending message:", err);
      if (err instanceof Error) {
        console.error("Detailed error:", err.message);
      }
    }
  } else {
    console.error("SignalR connection is not established or not connected.");
  }
};
// region edit messages invoke
export const SendEditMessage = async (message) => {
  if (getConnectionStatus() === "Connected") {
    try {
      await connection.invoke("SendEditMessage", message);
      console.log("Message has been edit");
    } catch (err) {
      console.error("Error sending message:", err);
      if (err instanceof Error) {
        console.error("Detailed error:", err.message);
      }
    }
  } else {
    console.error("SignalR connection is not established or not connected.");
  }
};

export const SendReadReceipt = async (readerUserId, chatId, messageId) => {
  if (getConnectionStatus() === "Connected") {
    try {
      await connection.invoke(
        "SendReadReceipt",
        parseInt(readerUserId),
        chatId,
        messageId
      );
      console.log("Message has been read");
    } catch (err) {
      console.error("Error sending message:", err);
      if (err instanceof Error) {
        console.error("Detailed error:", err.message);
      }
    }
  } else {
    console.error("SignalR connection is not established or not connected.");
  }
};
// region SendNewChat invoke
export const SendNewChat = async (chatId, users) => {
  if (getConnectionStatus() === "Connected") {
    try {
      await connection.invoke("SendNewChat", parseInt(chatId), users);
      console.log("New chat added");
    } catch (err) {
      console.error("Error sending message:", err);
      if (err instanceof Error) {
        console.error("Detailed error:", err.message);
      }
    }
  } else {
    console.error("SignalR connection is not established or not connected.");
  }
};

// region Invok contacts
export const InvokeContacts = async (senderUserid, contactId, phoneNo) => {
  if (getConnectionStatus() === "Connected") {
    try {
      await connection.invoke(
        "AddNewContact",
        parseInt(senderUserid),
        parseInt(contactId),
        phoneNo
      );
    } catch (err) {
      console.error("Error sending message:", err);
      if (err instanceof Error) {
        console.error("Detailed error:", err.message);
      }
    }
  } else {
    console.error("SignalR connection is not established or not connected.");
  }
};
// region invoke AddRection
export const AddReaction = async (MessageId, UserId, Reaction) => {
  if (getConnectionStatus() === "Connected") {
    try {
      await connection.invoke("AddReaction", MessageId, UserId, Reaction);
      console.log("Add reacation console");
      // store.dispatch(setRenderReaction(true));
    } catch (err) {
      console.error("Error sending message:", err);
      if (err instanceof Error) {
        console.error("Detailed error:", err.message);
      }
    }
  } else {
    console.error("SignalR connection is not established or not connected.");
  }
};
// region invoke RemoveRection
export const RemoveReaction = async (userId, messageReactionId) => {
  if (getConnectionStatus() === "Connected") {
    try {
      await connection.invoke("RemoveReaction", userId, messageReactionId);
      console.log("Add reacation console");
      // store.dispatch(setRenderReaction(true));
    } catch (err) {
      console.error("Error sending message:", err);
      if (err instanceof Error) {
        console.error("Detailed error:", err.message);
      }
    }
  } else {
    console.error("SignalR connection is not established or not connected.");
  }
};
// region AddRemoveUser
export const AddRemoveUser = async (ConversationEventId) => {
  if (getConnectionStatus() === "Connected") {
    try {
      await connection.invoke("AddRemoveUser", parseInt(ConversationEventId));
    } catch (err) {
      console.error("Error sending message:", err);
      if (err instanceof Error) {
        console.error("Detailed error:", err.message);
      }
    }
  } else {
    console.error("SignalR connection is not established or not connected.");
  }
};

export const newEmailNotification = async (email) => {
  if (getConnectionStatus() === "Connected") {
    try {
      await connection.invoke("ReceiveEmail", email);
    } catch (err) {
      console.error("Error sending message:", err);
      if (err instanceof Error) {
        console.error("Detailed error:", err.message);
      }
    }
  } else {
    console.error("SignalR connection is not established or not connected.");
  }
};

export const SendTyping = async (user_id, userName, chatId) => {
  if (getConnectionStatus() === "Connected") {
    try {
      await connection.invoke(
        "SendTyping",
        parseInt(user_id),
        userName,
        parseInt(chatId)
      );
      console.log("User is typing");
    } catch (err) {
      console.error("Error typing message:", err);
      if (err instanceof Error) {
        console.error("Detailed error:", err.message);
      }
    }
  } else {
    console.error("SignalR connection is not established or not connected.");
  }
};

export const StopConnection = async () => {
  if (connection && connection.state !== "Disconnected") {
    try {
      connection.off("ReceiveMessage");
      connection.off("ReceiveEditMessage");
      connection.off("ReceiveDeleteMessage");
      connection.off("ReceiveNewChat");
      connection.off("ReceiveReadReceipt");
      connection.off("ReceiveTyping");
      connection.off("UserOffline");
      connection.off("UserOnline");

      await connection.stop();
      console.log("SignalR disconnected");
    } catch (error) {
      console.error("Error while disconnecting SignalR:", error);
    } finally {
      connection = null;
    }
  } else {
    console.log("Connection is already disconnected or null");
  }
};

export const RemoveAttachment = async (attachmentId, userId) => {
  if (getConnectionStatus() === "Connected") {
    try {
      await connection.invoke("RemoveAttachment", attachmentId, userId);
      console.log("The attachment has been deleted!");
    } catch (err) {
      console.error("Error sending message:", err);
      if (err instanceof Error) {
        console.error("Detailed error:", err.message);
      }
    }
  } else {
    console.error("SignalR connection is not established or not connected.");
  }
};

export const SendCallAccept = async (call) => {
  if (getConnectionStatus() === "Connected") {
    try {
      await connection.invoke("CallAccept", call);
    } catch (err) {
      console.error("Error sending message:", err);
      if (err instanceof Error) {
        console.error("Detailed error:", err.message);
      }
    }
  } else {
    console.error("SignalR connection is not established or not connected.");
  }
};

export const sendAudioRemoteStream = async (stream) => {
  if (getConnectionStatus() === "Connected") {
    try {
      await connection.invoke("ScreenShare", stream);
    } catch (err) {
      console.error("Error sending message:", err);
      if (err instanceof Error) {
        console.error("Detailed error:", err.message);
      }
    }
  } else {
    console.error("SignalR connection is not established or not connected.");
  }
};
export const SendMute = async (CallId, UserId, isMuted) => {
  if (getConnectionStatus() === "Connected") {
    try {
      await connection.invoke("SendMute", CallId, UserId, isMuted);
    } catch (err) {
      console.error("Error sending message:", err);
      if (err instanceof Error) {
        console.error("Detailed error:", err.message);
      }
    }
  } else {
    console.error("SignalR connection is not established or not connected.");
  }
};
export const SendRecording = async (CallId, UserId, CallSid, IsRecord) => {
  if (getConnectionStatus() === "Connected") {
    try {
      await connection.invoke(
        "SendRecording",
        CallId,
        UserId,
        CallSid,
        IsRecord
      );
    } catch (err) {
      console.error("Error sending message:", err);
      if (err instanceof Error) {
        console.error("Detailed error:", err.message);
      }
    }
  } else {
    console.error("SignalR connection is not established or not connected.");
  }
};
// For Sharing Screen
export const SendShareScreen = async (CallId, UserId, CallSid, IsStream) => {
  if (getConnectionStatus() === "Connected") {
    try {
      await connection.invoke("ShareScreen", CallId, UserId, CallSid, IsStream);
    } catch (err) {
      console.error("Error sending message:", err);
      if (err instanceof Error) {
        console.error("Detailed error:", err.message);
      }
    }
  } else {
    console.error("SignalR connection is not established or not connected.");
  }
};

// For Group CAlling
export const startGroupCall = async (Type, RoomName, fromUserId, ToUserIds) => {
  if (getConnectionStatus() === "Connected") {
    try {
      debugger;
      await connection.invoke(
        "startGroupCall",
        Type,
        RoomName,
        fromUserId,
        ToUserIds
      );
    } catch (err) {
      console.error("Error sending message:", err);
      if (err instanceof Error) {
        console.error("Detailed error:", err.message);
      }
    }
  } else {
    console.error("SignalR connection is not established or not connected.");
  }
};
