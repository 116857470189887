import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import AppRoutes from "./AppRoutes";
import { SnackbarProvider } from "notistack";
import { AuthProvider } from "./app/services/context/InactiveContext";
import GlobalSnackbar from "./app/main/pages/GlobalSnackbar/SnackbarAlert";
import DraftModal from "./app/main/pages/emailModule/GlobalDraftModal/DraftModal";
import { BgCallPopupProvider } from "./app/services/context/BgCallPopupContext";
import BackgroundCallPopup from "./app/main/components/BackgroundCallPOPUP/BackgroundCallPopup";
import { TwilioProvider } from "./app/services/context/TwilioContext";
import NoDeviceModal from "./app/main/pages/chats/chatList/modal/NoDeviceModal";
import { Notifications } from "react-push-notification";
// import GlobalSnackbar from "./app/main/pages/GlobalSnackbar";

function App() {
  return (
    <BgCallPopupProvider>
      <Router>
        {/* for Message Notifications */}
        <Notifications />
        <TwilioProvider>
          {/* <InactiveContext> */}
          <SnackbarProvider
            maxSnack={5}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            classes={{
              containerRoot:
                "bottom-0 right-0 mb-52 md:mb-68 mr-8 lg:mr-80 z-100",
            }}
          >
            <AuthProvider>
              <BackgroundCallPopup />
              <NoDeviceModal />
              <AppRoutes />
            </AuthProvider>
          </SnackbarProvider>
          {/* </InactiveContext> */}
          <GlobalSnackbar />
          <DraftModal />
        </TwilioProvider>
      </Router>
    </BgCallPopupProvider>
  );
}

export default App;
