import React, { createContext, useContext, useState } from "react";

const callPopupWindowContext = createContext();

export const BgCallPopupProvider = ({ children }) => {
  const [isCallActive, setIsCallActive] = useState(false);

  return (
    <callPopupWindowContext.Provider value={{ isCallActive, setIsCallActive }}>
      {children}
    </callPopupWindowContext.Provider>
  );
};

export const BgCallPopupContext = () => useContext(callPopupWindowContext);
