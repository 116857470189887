import {
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  addFolders,
  getFolders,
  updateFolders,
} from "../../../../services/api/apiManager";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
// import { setRenderFolder } from "../../../../services/store/slice/ChatSlice";
import { setRenderFolder } from "../../../../services/store/slice/ChatSlice";

export default function CreateNewFolder(props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const foldersList = useSelector((state) => state?.email?.foldersList);

  const [fonderData, setFonderData] = useState([]);
  const [folderName, setFolderName] = useState("");

  const userId = localStorage.getItem("user_id");
  const [selectedCategory, setSelectedCategory] = useState(
    localStorage.getItem("selectedCategory") || "Inbox"
  );
  // region get Folder

  // region AddFolders
  const addNewFolder = async (data) => {
    try {
      const response = await addFolders(data);
      if (response?.status === 200) {
        props?.setOpenModal(true);
        enqueueSnackbar("New folder added successfully!", {
          variant: "success",
        });
        // props?.renderFromParent((prev) => !prev);
        dispatch(setRenderFolder((pre) => !pre));
        props?.setOpenModal(false);
      } else {
        props?.setOpenModal(false);
        enqueueSnackbar("Error", { variant: "error" });
      }
    } catch (error) {
      console.warn(error);
      props?.setOpenModal(false);
    }
  };
  //
  useEffect(() => {
    if (props.folderNameSideBar !== null) {
      setFolderName(props.folderNameSideBar);
    }
  }, [props.folderNameSideBar]);

  // region update Folders
  const updateFolder = async (data) => {
    try {
      const response = await updateFolders(data);
      if (response?.status === 200) {
        dispatch(setRenderFolder((pre) => !pre));
        props.setOpenModal(false);
        if (data?.Deleted === true) {
          enqueueSnackbar("Deleted Successfully", { variant: "success" });
        } else {
          enqueueSnackbar("Updated Successfully", { variant: "success" });
        }
        setFolderName("");
      } else {
        //setAnchorEl(null);
        enqueueSnackbar("Error", { variant: "error" });
      }
    } catch (error) {
      console.warn(error);
      //setAnchorEl(null);
      props?.setOpenModal(false);
      enqueueSnackbar("Error", { variant: "error" });
    }
  };

  const getFolder = async (id) => {
    try {
      const response = await getFolders(id);
      if (response?.status === 200) {
        setFonderData(response?.data?.result);
      }
    } catch (error) {
      console.warn(error);
    }
  };

  useEffect(() => {
    getFolder(props.folderId);
  }, []);

  return (
    <>
      <Modal
        open={props?.openModal}
        onClose={() => props?.setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            padding: "10px",
            bgcolor: "background.paper",
            borderRadius: "1rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "5px",
            }}
          >
            <Box sx={{ width: "90%", height: "auto" }}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{
                  color: "#085f99",
                  fontSize: "16px",
                  fontWeight: 600,
                  textAlign: "center",
                }}
              >
                {props?.folderId === null ? "Create A New Folder" : "Rename"}
              </Typography>
            </Box>

            <IconButton>
              <HighlightOffIcon
                sx={{ color: "#085f99", height: "25px", width: "25px" }}
                onClick={() => {
                  setFolderName("");
                  props?.setOpenModal(false);
                }}
              />
            </IconButton>
          </Box>
          <Divider orientation="horizontal" flexItem sx={{ marginY: "10px" }} />
          <Box pl={3} pr={3} pb={3}>
            <Typography sx={{ mt: 2, fontSize: "16px", fontWeight: 600 }}>
              {props?.folderId === null ? "Enter Folder Name" : "Rename"}
            </Typography>
            <TextField
              size="small"
              value={folderName}
              onChange={(e) => setFolderName(e.target.value)}
              // onChange={(e) => props?.setFolderNameSideBar(e.target.value)}
              variant="outlined"
              fullWidth
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "right",
              marginRight: "20px",
            }}
          >
            <Box
              sx={{
                width: "200px",
                display: "flex",
                justifyContent: "right",
                gap: "10px",
              }}
            >
              <Button
                variant="contained"
                size="large"
                sx={{
                  backgroundColor: "#dedede",
                  color: "black",
                  "&:hover": { backgroundColor: "#d6d4d4" },
                }}
                onClick={() => {
                  setFolderName("");
                  props?.setOpenModal(false);
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  // Check if a folder with the same name already exists
                  const isDuplicate = foldersList.some(
                    (item) => item.folderName === folderName
                  );

                  if (isDuplicate) {
                    enqueueSnackbar("Folder name must be unique!", {
                      variant: "warning", // Updated variant to "warning"
                    });
                    return; // Exit early if a duplicate is found
                  }

                  if (props.folderId === null) {
                    // Add a new folder if folderId is null
                    addNewFolder({
                      UserId: userId,
                      FolderName: folderName,
                    });
                  } else {
                    // Update an existing folder if folderId is provided
                    updateFolder({
                      folderId: props.folderId,
                      FolderName: folderName,
                      Deleted: false,
                    });
                  }

                  // Reset folder name
                  setFolderName(null);
                }}
                variant="contained"
                size="large"
              >
                {props.folderId === null ? "Create" : "Update"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
