import React, { useState } from "react";
import { Box, Paper, Typography, Button } from "@mui/material";
import loginImage from "../../../../assets/img/max.png";
import favicon from "../../../../assets/img/x.png";
import "./login.css";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import MailIcon from "@mui/icons-material/Email";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// import Lock from "@mui/icons-material/Lock"; // Import lock icon
import { Link } from "react-router-dom"; // For navigation
import AccountCircle from "@mui/icons-material/AccountCircle";
import email from "../../../../assets/img/Message.png";
import Lock from "../../../../assets/img/Lock.png";
// import eyeon from "../img/Vector.png";
// import eyeOff from "../img/eyeoff.png";
import Profile from "../../../../assets/img/Profile.png";
import eyeOn from "../../../../assets/img/Eyeon.svg"; //
import eyeOff from "../../../../assets/img/visibilityoff.svg";
const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            borderRadius: "4px",
            backgroundColor: "#f6f7fb", // Background color of the text field
            // "& .MuiOutlinedInput-root": {

            "& input": {
              padding: "6px 12px", // Decrease padding to reduce height
              fontSize: "12px", // Adjust font size if needed
              height: "30px",
              backgroundColor: "#f6f7fb",
            },
            "& fieldset": {
              borderColor: "#f6f7fb", // Border color when inactive
            },
            "&:hover fieldset": {
              borderColor: "#f6f7fb", // Border color when hovered
            },
            "&.Mui-focused fieldset": {
              borderColor: "#f6f7fb", // Border color when focused
            },
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          padding: "4px 8px", // Adjust padding if needed
        },
      },
    },
  },
});

const Signup = () => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100vh", // Full height of the viewport
        backgroundImage: `url(${loginImage})`,
        backgroundSize: "cover", // Cover the entire container
        backgroundPosition: "center", // Center the image
        backgroundRepeat: "no-repeat", // Prevent repeating the image
      }}
    >
      {/* <img
          src={loginImage}
          alt="Description of the image"
          className="favicon"
        /> */}
      {/* <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 50, 0.3", 
          zIndex: 1, 
        }}
      /> */}

      <Paper
        elevation={3}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          padding: "31px",
          backgroundColor: "#ffffff", // Optional: semi-transparent background
          borderRadius: "10px",
          textAlign: "center",
          width: {
            xl: "26%",
            lg: "26%",
            md: "40%",
            sm: "75%",
            xs: "75%",
          },
        }}
      >
        <Box pt={2.5} pb={2.5}>
          <Typography variant="h4" component="h1">
            <img
              src={favicon}
              alt="Description of the image"
              // className="favicon"
            />
          </Typography>
          <Typography
            variant="h5"
            component="h1"
            style={{ fontWeight: "600", fontSize: "17px" }}
          >
            <span>
              Get Started With{" "}
              <span style={{ color: "#18699f" }}>MaxCharts</span>
            </span>
          </Typography>
          <Typography
            variant="h6"
            component="h1"
            style={{ fontSize: "14px" }}
            pt={0.5}
          >
            <span style={{ color: "#90919b" }}>Create Your Free Account</span>
          </Typography>
          <Box pt={3}>
            <ThemeProvider theme={theme}>
              <Box pt={2}>
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder="Your Full Name"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src={Profile}
                          alt="Description of the image"
                          className="icons-width"
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </ThemeProvider>
          </Box>
          <Box pt={0.5}>
            <ThemeProvider theme={theme}>
              <Box pt={2}>
                <TextField
                  // label="Email" // Optional: Uncomment if you want a label
                  variant="outlined"
                  fullWidth
                  placeholder="Email Address"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src={email}
                          alt="Description of the image"
                          className="icons-width"
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </ThemeProvider>
          </Box>
          <Box pt={2.5}>
            <ThemeProvider theme={theme}>
              <Box>
                <TextField
                  // label="Password"
                  variant="outlined"
                  placeholder="Password"
                  type={showPassword ? "text" : "password"}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src={Lock}
                          alt="Description of the image"
                          className="icons-width"
                        />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <img
                          src={eyeOn} // Eye-off image is the same, just toggle visibility
                          alt="Hide password"
                          className="icons-width"
                          style={{
                            position: "absolute",
                            right: "10px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                            // width: "24px",
                            // height: "24px",
                            display: showPassword ? "block" : "none", // Show eye-off when password is visible
                          }}
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        />
                        <img
                          src={eyeOff} // Eye-on image is the same, just toggle visibility
                          alt="Show password"
                          className="icons-width"
                          style={{
                            position: "absolute",
                            right: "10px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                            // width: "24px",
                            // height: "24px",
                            display: showPassword ? "none" : "block", // Show eye-on when password is hidden
                          }}
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </ThemeProvider>
          </Box>
          {/* <Box pt={2}>
            <Typography className="textrecover" sx={{ fontSize: "14px" }}>
              Recover Password
            </Typography>
          </Box> */}
          <Box pt={3}>
            <Typography>
              <Box className="text-center" pt={3} sx={{ fontSize: "14px" }}>
                Don't have an account?{" "}
                <Link
                  to="/" // Path to the sign-up page
                  style={{
                    textDecoration: "none",
                    color: "#095f99",
                    fontWeight: "600",
                  }} // Remove underline
                >
                  Login
                </Link>
              </Box>
            </Typography>
          </Box>
          <Box pt={3}>
            <Button variant="contained" className="btn">
              Sign Up
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default Signup;
