import React, { Suspense, lazy, useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import NavigationBar from "./app/main/components/navigation/navigationBar";
import Login from "./app/main/pages/authentication/login";
import Signup from "./app/main/pages/authentication/signup";
import Recover from "./app/main/pages/authentication/recover";
import Reset from "./app/main/pages/authentication/reset";
import Submit from "./app/main/pages/authentication/submit";
import PrivateRoute from "./privateroutes/PrivateRoute";
import CallScreen from "./app/main/pages/chatCall/CallScreen";
// import VideoCallScreen from "./app/main/pages/chatCall/VideoCallScreen";
import Emails from "./app/main/pages/emailModule";
import NewEmail from "./app/main/pages/emailModule/emailWindow/newEmail/NewEmail";
import { Add } from "./app/main/pages/emailModule/emailWindow/emailRules/AddRules/Add";
import { Manage } from "./app/main/pages/emailModule/emailWindow/emailRules/ManageRules/Manage";
import EmailWindow from "./app/main/pages/emailModule/emailWindow/emailWindow";
import NewFolders from "./app/main/pages/emailModule/AllFolders/NewFolders";
import { getFolders } from "./app/services/api/apiManager";
import { useDispatch, useSelector } from "react-redux";
import CallNotification from "./app/main/pages/chatCall/CallNotification";
import {
  setCallStatus,
  setClearIncomingCall,
  setIncomingCall,
} from "./app/services/store/slice/CallSlice";
import Reply from "./app/main/pages/emailModule/emailWindow/Reply";
// import SentMails from "./app/main/pages/emailModule/emailWindow/sentMails/SentMails";
// import DraftMails from "./app/main/pages/emailModule/emailWindow/draftMails/DraftMails";
// import TrashMails from "./app/main/pages/emailModule/emailWindow/trash/TrashMails";
// import FavouriteMails from "./app/main/pages/emailModule/emailWindow/favouriteMails/FavouriteMails";
import LoadingSpinner from "./app/main/pages/chats/chatWindow/loadingSpinner/LoadingSpinner";
import { Contacts } from "./app/main/pages/contacts/Contacts";
// Lazy loaded components
const Chats = lazy(() => import("./app/main/pages/chats/Chats"));
const Users = lazy(() => import("./app/main/pages/users/Users"));
const AdminPanel = lazy(() => import("./app/main/pages/adminPanel/AdminPanel"));
const Meetings = lazy(() => import("./app/main/pages/meetings/index"));
const UserInfo = lazy(() => import("./app/main/pages/userInfo/UserInfo"));
const Inbox = lazy(() =>
  import("./app/main/pages/emailModule/emailWindow/inbox/Inbox")
);
const Draft = lazy(() =>
  import("./app/main/pages/emailModule/emailWindow/draftMails/DraftMails")
);
const Favourite = lazy(() =>
  import(
    "./app/main/pages/emailModule/emailWindow/favouriteMails/FavouriteMails"
  )
);
const Sent = lazy(() =>
  import("./app/main/pages/emailModule/emailWindow/sentMails/SentMails")
);
const Trash = lazy(() =>
  import("./app/main/pages/emailModule/emailWindow/trash/TrashMails")
);

function AppRoutes() {
  const dispatch = useDispatch();
  const { incomingCall, callAccepted } = useSelector((state) => state.call);
  const userId = localStorage.getItem("user_id");
  const userRole = JSON.parse(localStorage.getItem("user_roles"));
  const renderFolder = useSelector((state) => state?.chat?.renderFolder);
  const [folderData, setFolderData] = useState([]);
  const [render, setRender] = useState(null);

  // region getFolders
  const getFolder = async (id) => {
    try {
      const response = await getFolders(id);
      if (response?.status === 200) {
        setFolderData(response?.data?.result);
        // dispatch(setRenderAfterDeleteFolder(response?.data?.result));
      }
    } catch (error) {
      console.warn(error);
    }
  };
  useEffect(() => {
    getFolder(userId);
  }, [render]);
  //
  useEffect(() => {
    setRender(renderFolder);
  }, [renderFolder]);

  const handleCancel = () => {
    if (!callAccepted) {
      dispatch(setCallStatus("Call canceled"));
      dispatch(setIncomingCall(null));
      dispatch(setClearIncomingCall(true));
    }
  };
  useEffect(() => {
    if (incomingCall) {
      incomingCall.on("cancel", handleCancel);
    }
    return () => {
      if (incomingCall) {
        incomingCall.off("cancel", handleCancel);
      }
    };
  }, [callAccepted, incomingCall]);

  return (
    <>
      {incomingCall && <PrivateRoute element={<CallNotification />} />}
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/recover" element={<Recover />} />
        <Route path="/reset" element={<Reset />} />
        <Route path="/submit" element={<Submit />} />
        {/* <Route
          path="/audioCallScreen"
          element={<PrivateRoute element={<CallScreen />} />}
        /> */}
        {/* Nested Routing Example */}
        {/* Protected Routes wrapped with NavigationBar */}
        <Route element={<NavigationBar />}>
          <Route path="/emails" element={<PrivateRoute element={<Emails />} />}>
            {/* Nested Routes */}
            <Route path="inbox" element={<Inbox />} />
            <Route path="newEmail" element={<NewEmail />} />
            <Route path="sent" element={<Sent />} />
            <Route path="draft" element={<Draft />} />
            <Route path="favourite" element={<Favourite />} />
            <Route path="trash" element={<Trash />} />
            <Route path="add" element={<Add />} />
            <Route path="manage" element={<Manage />} />
            <Route path="window" element={<EmailWindow />} />
            {/* Dynamic Folder Routes */}
            {folderData?.map((folder) => (
              <Route
                key={folder.folderId}
                path={`folder/${folder.folderId}`} // Create unique path for each folder
                element={<NewFolders />}
              />
            ))}
          </Route>
          <Route
            path="/chats"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <PrivateRoute element={<Chats />} />
              </Suspense>
            }
          />
          <Route
            path="/users"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <PrivateRoute element={<Users />} />
              </Suspense>
            }
          />
          <Route
            path="/meetings"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <PrivateRoute element={<CallScreen />} />
              </Suspense>
            }
          />
          {/* {userRole[0]?.roleName?.toLowerCase() == "admin" && ( */}
          <Route
            path="/admin"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <PrivateRoute
                  element={
                    userRole?.some(
                      (ur) => ur.roleName.toLowerCase() === "admin"
                    ) ? (
                      <AdminPanel />
                    ) : (
                      <Navigate to="/" replace />
                    )
                  }
                />
              </Suspense>
            }
          />
          {/* )} */}

          <Route
            path="/contacts"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <PrivateRoute element={<Contacts />} />
              </Suspense>
            }
          />
          <Route
            path="/admin/info"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <PrivateRoute element={<UserInfo />} />
              </Suspense>
            }
          />
        </Route>
      </Routes>
    </>
  );
}

export default AppRoutes;
