import {
  AppBar,
  Avatar,
  Badge,
  Box,
  CssBaseline,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuItem,
  styled,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import ChatRoundedIcon from "@mui/icons-material/ChatRounded";
import PersonIcon from "@mui/icons-material/Person";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { getUsersById } from "../../../services/api/apiManager";
import AppLogo from "../../../../assets/icons/MaxChatsLogo.svg";
import LogoutIcon from "@mui/icons-material/Logout";
import { settings as s } from "../../../services/Settings";
import {
  getSignalRConnection,
  initializeSignalRConnection,
  sendUserOnline,
  StopConnection,
} from "../../../services/signalRService/signalRService";
import { setChat_Id } from "../../../services/store/slice/ChatSlice";
import { useDispatch, useSelector } from "react-redux";
import Notification from "./Notification";
import { resetChatState } from "../../../services/store/slice/ChatSlice";
import EmailIcon from "@mui/icons-material/Email";
import {
  ContactEmergencyRounded,
  ContactEmergencySharp,
  Contacts,
} from "@mui/icons-material";

const menuItems = [
  {
    name: "Admin Panel",
    to: "/Admin",
    icon: <DashboardRoundedIcon fontSize="small" />,
    // filledIcon: <DashboardRoundedIcon sx={{ color: "#085F99" }} />,
    label: "Admin",
  },
  {
    name: "Chats",
    to: "/chats",
    icon: <ChatRoundedIcon fontSize="small" />,
    // filledIcon: <ChatRoundedIcon sx={{ color: "#085F99" }} />,
    label: "Chats",
  },
  // {
  //   name: "Meetings",
  //   to: "/meetings",
  //   icon: <VideocamOutlinedIcon fontSize="small" />,
  //   // filledIcon: <VideocamOutlinedIcon sx={{ color: "#085F99" }} />,
  //   label: "Meetings",
  // },
  {
    name: "contacts",
    to: "/contacts",
    icon: <ContactEmergencyRounded fontSize="small" />,
    // filledIcon: <VideocamOutlinedIcon sx={{ color: "#085F99" }} />,
    label: "Contacts",
  },
  {
    name: "emails",
    to: "/emails",
    icon: <EmailIcon fontSize="small" />,
    // filledIcon: <VideocamOutlinedIcon sx={{ color: "#085F99" }} />,
    label: "Emails",
  },
];
const settings = [
  {
    // to: "/Admin",
    icon: <SettingsOutlinedIcon fontSize="small" />,
    // filledIcon: <DashboardRoundedIcon sx={{ color: "#085F99" }} />,
    label: "Settings",
  },
];

function NavigationBar() {
  const dispatch = useDispatch();
  const { device } = useSelector((state) => state.call);
  const [showProfileHead, setShowProfileHead] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showProfileInfo, setShowProfileInfo] = useState(false);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  const [userData, setUserData] = useState([]);
  console.log("Profile Details", userData);
  const location = useLocation();
  // get userId from Local storage
  const getUserId = localStorage.getItem("user_id");
  // HEAD TITLE
  const HeaderTitle = () => {
    const location = useLocation();
    let title;
    switch (location.pathname) {
      case "/Admin":
        title = "Admin Panel";
        break;
      case "/chats":
        title = "Chats";
        break;
      case "/meetings":
        title = "Meetings";
        break;
      case "/users":
        title = "Users";
        break;
      case "/tasks":
        title = "Tasks";
        break;
      default:
        title = "Admin Panel";
    }

    return (
      <Typography
        fontWeight={600}
        fontSize={"15px"}
        variant="h6"
        noWrap
        component="div"
        sx={{ textTransform: "uppercase" }}
      >
        {showProfileHead ? "PROFILE MANAGEMENT" : title}
      </Typography>
    );
  };
  // api get data by id
  const getData = async (data) => {
    try {
      const response = await getUsersById(data);
      if (response?.status) {
        setUserData(response?.data?.result || []);
        const { firstName, lastName } = response?.data?.result[0];
        const fullName = `${firstName} ${lastName}`;
        localStorage.setItem("username", fullName);
      }
    } catch (err) {
      console.warn(err);
      setUserData([]); // Set to empty array on error
    }
  };

  useEffect(() => {
    if (getUserId) {
      getData(getUserId);
    }
  }, [getUserId]);

  // Listen for the custom event and refresh data when triggered
  useEffect(() => {
    const handleUserDataRefresh = () => {
      if (getUserId) {
        getData(getUserId);
      }
    };
    window.addEventListener("refreshUserData", handleUserDataRefresh);
    return () => {
      window.removeEventListener("refreshUserData", handleUserDataRefresh);
    };
  }, [getUserId]);

  useEffect(() => {
    initializeSignalRConnection();
  }, []);
  // start
  const userRoles = JSON.parse(localStorage.getItem("user_roles"));
  const handleLogout = async () => {
    // debugger;
    handleClose();
    localStorage.clear();
    dispatch(resetChatState());
    await StopConnection();
    if (device) {
      device.removeAllListeners();
      // device.off("ready", handleReady);
      // device.off("error", handleError);
      // device.off("incoming", handleIncoming);
      // device.off("disconnect", handleDisconnect);
      device.destroy();
    }
    navigate("/");
    window.location.reload();
  };
  const filteredMenuItems = menuItems.filter((item) => {
    // Check if userRoles is valid and not empty
    const hasAdminRole =
      Array.isArray(userRoles) &&
      userRoles.some((ur) => ur.roleName.toLowerCase() === "admin");

    // Filter out "Admin" if the user does not have the "Admin" role
    if (item.label === "Admin" && !hasAdminRole) {
      return false;
    }
    return true;
  });
  return (
    <>
      <Box sx={{ display: "flex", height: "100vh", overflowY: "hidden" }}>
        <CssBaseline />
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              border: "none",
              // background:
              //   "radial-gradient(circle at 10% 20%, rgb(0, 102, 161) 0%, rgb(0, 68, 108) 90.1%)",
              // background: "#0D406F",
              background: "#085F99",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            },
          }}
        >
          {/* <Toolbar /> */}
          <Box
            sx={{
              overflow: "auto",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={AppLogo}
                style={{
                  height: "45px",
                  width: "60px",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                p: 1,
                pt: 3,
              }}
            >
              {filteredMenuItems?.map((item, index) => (
                <Tooltip
                  title={item.name}
                  key={index}
                  placement="right-start"
                  arrow
                >
                  <Box
                    component={NavLink}
                    onClick={() => dispatch(setChat_Id(null))}
                    to={item.to}
                    sx={{
                      ...listItemStyles,
                      p: 1,
                      "&:hover": {
                        borderRadius: "7px",
                        background: "#0D4273",
                      },
                      "&.active": {
                        background: "#0D4273",
                        color: "#fff",
                        borderRadius: "7px",
                      },
                    }}
                  >
                    {item.icon}
                  </Box>
                </Tooltip>
              ))}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              p: 1,
            }}
          >
            <Box
              // onMouseEnter={() => setShowProfileInfo(true)}
              // onMouseLeave={() => setShowProfileInfo(false)}
              sx={{
                ...listItemStyles,
                p: 1,
                "&:hover": {
                  borderRadius: "7px",
                  background: "#0D4273",
                },
                "&.active": {
                  background: "#0D4273",
                  borderRadius: "7px",
                },
                display: "flex",
                flexDirection: "column",
              }}
            >
              <StyledBadge
                onMouseEnter={() => setShowProfileInfo(true)}
                onMouseLeave={() => setShowProfileInfo(false)}
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                variant="dot"
              >
                <Avatar
                  sx={{
                    bgcolor: "#085F99",
                    width: 30,
                    height: 30,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    // handleClose();
                    navigate("/admin/info");
                    setShowProfileHead(true);
                  }}
                  src={`${s.baseUrl}${userData[0]?.profilePicPath}`}
                />
              </StyledBadge>

              <Typography
                title={`${userData[0]?.firstName} ${userData[0]?.lastName}`}
                sx={{ fontSize: "10px", fontFamily: "Poppins" }}
              >
                {" "}
                {userData[0]?.firstName}
                {/* {userData[0]?.lastName || "Name"} */}
              </Typography>
            </Box>

            <Box
              sx={{
                ...listItemStyles,
                p: 1,
                cursor: "pointer",
                "&:hover": {
                  borderRadius: "7px",

                  background: "#0D4273",
                },
                "&.active": {
                  // backgroundColor: "#1b2631",
                  background: "#085F99",
                  borderRadius: "7px",
                },
              }}
            >
              <LogoutIcon fontSize="small" onClick={handleLogout} />
            </Box>
          </Box>
        </Drawer>
        {/* profile card */}
        {showProfileInfo && (
          <Grid
            container
            sx={{
              height: "150px",
              width: "250px",
              background: "#DCE9F2",
              borderRadius: "5px",
              position: "absolute",
              bottom: 10,
              left: 50,
              zIndex: 1000000,
              border: "1px solid lightgray",
            }}
          >
            <Grid
              item
              xs={12}
              xl={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Box display={"flex"}>
                {userData[0]?.profilePicPath ? (
                  <Box
                    component={"img"}
                    width={"80px"}
                    height={"80px"}
                    borderRadius={"50%"}
                    objectFit={"cover"}
                    src={`${s.baseUrl}${userData[0]?.profilePicPath}`}
                    alt="Profile"
                    sx={{ cursor: "pointer" }}
                  />
                ) : (
                  <Avatar
                    sx={{
                      bgcolor: "#085F99",
                      width: 40,
                      height: 40,
                      cursor: "pointer",
                    }}
                  >
                    <PersonIcon />
                  </Avatar>
                )}
              </Box>
              <Typography
                variant="subtitle2"
                sx={{
                  fontWeight: "bold",
                  fontFamily: "Poppins",
                }}
              >
                {`${userData[0]?.firstName} ${userData[0]?.lastName}`}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  fontFamily: "Poppins",
                }}
              >
                {userData[0]?.email || "email@example.com"}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "10px",
                  borderRadius: "20px",
                  background: "green",
                  color: "white",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  pl: 1,
                  pr: 1,
                  pt: 0.5,
                  pb: 0.5,
                }}
              >
                {userData[0]?.isActive ? "Active" : "Inactive"}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "10px",
                  background: "lightgray",
                  color: "#333",
                  position: "absolute",
                  top: 0,
                  right: 0,
                  pl: 1,
                  pr: 1,
                  pt: 0.5,
                  pb: 0.5,
                }}
              >
                {userData[0]?.roles[0]?.roleName}
              </Typography>
            </Grid>
          </Grid>
        )}

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            width: "100%",
            overflow: "auto",
          }}
        >
          <Outlet />
        </Box>
        <Notification />
      </Box>
    </>
  );
}

export default NavigationBar;

const drawerWidth = 65;
const listItemStyles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "white",
  textDecoration: "none",
};
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));
