import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOnCall: false,
  incomingCall: null,
  callStatus: null,
  caller: null,
  disconnectCall: null,
  connectCall: null,
  activeConnection: null,
  callDuration: 0,
  callInterval: null,
  clearIncomingCall: false,
  callAccepted: false,
  device: null,
  callRunningInBG: false,
  audioCallStart: false,
  showChatSectionDuringCall: false,
  callerName: "",
  userById: null,
  contactImage: null,
  isReceiveMuted: [],
  isReceiveRecording: [],
  groupCallIds: [],
  isShareScreen: [],
  callStartTime: null,
  isSendingRoomData: [],
  room: null,
  participants: [],
  mainParticipant: null,
};

const callSlice = createSlice({
  name: "call",
  initialState,
  reducers: {
    setIncomingCall: (state, action) => {
      state.incomingCall = action.payload;
    },
    setClearIncomingCall: (state, action) => {
      state.clearIncomingCall = action.payload;
    },
    setCallStatus: (state, action) => {
      state.callStatus = action.payload;
    },
    setCaller: (state, action) => {
      state.caller = action.payload;
    },
    setConnectCall: (state, action) => {
      state.connectCall = action.payload;
    },
    setDisconnectCall: (state, action) => {
      state.disconnectCall = action.payload;
    },
    setActiveConnection: (state, action) => {
      state.activeConnection = action.payload;
    },
    setCallDuration: (state, action) => {
      state.callDuration += action.payload; //for increment logic
    },
    resetCallDurationCounter: (state) => {
      state.callDuration = 0;
    },
    setCallInterval: (state, action) => {
      state.callInterval = action.payload;
    },
    setCallAccepted: (state, action) => {
      state.callAccepted = action.payload;
    },
    setDevice: (state, action) => {
      state.device = action.payload;
    },
    setIsOnCall: (state, action) => {
      state.isOnCall = action.payload;
    },
    setCallRunningInBG: (state, action) => {
      state.callRunningInBG = action.payload;
    },
    setAudioCallStart: (state, action) => {
      state.audioCallStart = action.payload;
    },
    setShowChatSectionDuringCall: (state, action) => {
      state.showChatSectionDuringCall = action.payload;
    },
    setUserById: (state, action) => {
      state.userById = action.payload;
    },
    setCallerName: (state, action) => {
      state.callerName = action.payload;
    },
    setContactImage: (state, action) => {
      state.contactImage = action.payload;
    },
    setIsReceiveMuted: (state, action) => {
      state.isReceiveMuted = action.payload;
    },
    setIsReceiveRecording: (state, action) => {
      state.isReceiveRecording = action.payload;
    },
    setgroupCallIds: (state, action) => {
      state.groupCallIds = action.payload;
    },
    setIsShareScreen: (state, action) => {
      state.isShareScreen = action.payload;
    },
    setCallStartTime: (state, action) => {
      state.callStartTime = action.payload;
    },
    setIsSendingRoomData: (state, action) => {
      state.isSendingRoomData = action.payload;
    },
    setRoom: (state, action) => {
      state.room = action.payload;
    },
    setParticipants: (state, action) => {
      state.participants = action.payload;
    },
    setMainParticipant: (state, action) => {
      state.mainParticipant = action.payload;
    },
  },
});

export const {
  setIncomingCall,
  setClearIncomingCall,
  setCallStatus,
  setCaller,
  setConnectCall,
  setDisconnectCall,
  setActiveConnection,
  setCallDuration,
  setCallInterval,
  setCallAccepted,
  resetCallDurationCounter,
  setDevice,
  setIsOnCall,
  setCallRunningInBG,
  setAudioCallStart,
  setShowChatSectionDuringCall,
  setCallerName,
  setUserById,
  setContactImage,
  setIsReceiveMuted,
  setIsReceiveRecording,
  setgroupCallIds,
  setIsShareScreen,
  setCallStartTime,
  setIsSendingRoomData,
  setRoom,
  setParticipants,
  setMainParticipant,
} = callSlice.actions;
export default callSlice.reducer;
