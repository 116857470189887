import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

const LoadingSpinner = () => {
  return (
    <div className="loading-spinner" style={spinnerStyles}>
      {/* <div className="spinner-icon">Loading...</div> */}
      <CircularProgress size="3rem" />
    </div>
  );
};

const spinnerStyles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  gap: "1rem",
  fontSize: "1.5rem",
  color: "#555",
};

export default LoadingSpinner;
