import React from "react";
import { Navigate } from "react-router-dom";

// Function to check if the user is authenticated
const isAuthenticated = () => {
  return !!localStorage.getItem("access_token");
};

const PrivateRoute = ({ element: Component, ...rest }) => {
  return isAuthenticated() ? Component : <Navigate to="/" />;
};

export default PrivateRoute;
