import React, { useState } from "react";
import { Box, Paper, Typography, Button, Alert, Snackbar } from "@mui/material";
import loginImage from "../../../../assets/img/max.png";
import favicon from "../../../../assets/img/x.png";
import "./login.css";
import { TextField, InputAdornment } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import Lock from "../../../../assets/img/Lock.png";
import eyeOn from "../../../../assets/img/Eyeon.svg";
import eyeOff from "../../../../assets/img/visibilityoff.svg";
import { useNavigate } from "react-router-dom";
import { resetPassword } from "../../../services/api/apiManager";

const Reset = () => {
  const navigate = useNavigate();
  const receiveEmail = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState({
    openSnack: false,
    snackMessage: "",
  });

  // Toggle password visibility
  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };
  // Toggle confirm password visibility
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword((prev) => !prev);
  };
  // Prevent default action on mouse down
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };
  // api integration
  const newPassword = async (email, password) => {
    try {
      const response = await resetPassword(email, password);
      if (
        response?.status === 200 &&
        response?.data?.message === "Password Reset Successfully"
      ) {
        setOpenSnackbar({
          openSnack: true,
          snackMessage: "success",
        });
        setPassword("");
        setConfirmPassword("");
      } else {
        setPassword("");
        setConfirmPassword("");
        setOpenSnackbar({
          openSnack: true,
          snackMessage: "error",
        });
      }
    } catch (err) {
      console.warn(err);
    } finally {
      setTimeout(() => {
        navigate("/");
      }, 1000);
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100vh", // Full height of the viewport
        backgroundImage: `url(${loginImage})`,
        backgroundSize: "cover", // Cover the entire container
        backgroundPosition: "center", // Center the image
        backgroundRepeat: "no-repeat", // Prevent repeating the image
      }}
    >
      <Paper
        elevation={3}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          padding: "31px",
          backgroundColor: "#ffffff", // Optional: semi-transparent background
          borderRadius: "10px",
          textAlign: "center",
          width: {
            xl: "25%",
            lg: "25%",
            md: "40%",
            sm: "75%",
            xs: "75%",
          },
        }}
      >
        <Box pt={2.5} pb={2.5}>
          <Typography variant="h4" component="h1">
            <img
              src={favicon}
              alt="Description of the image"
              // className="favicon"
            />
          </Typography>
          <Typography
            variant="h5"
            component="h1"
            style={{ fontWeight: "600", fontSize: "19px" }}
          >
            <span style={{ color: "#2f2f2f" }}>Reset Password</span>
          </Typography>
          <Typography
            variant="h6"
            component="h1"
            style={{ fontSize: "14px" }}
            pt={0.5}
          >
            <span style={{ color: "#90919b" }}>Please Enter New Password</span>
          </Typography>

          <Box pt={2} width={"90%"} m={"0 auto"}>
            <ThemeProvider theme={theme}>
              <Box
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <TextField
                  size="small"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  variant="outlined"
                  placeholder="New Password"
                  type={showPassword ? "text" : "password"}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src={Lock}
                          alt="Lock icon"
                          className="icons-width"
                          style={{
                            height: "20px",
                            width: "20px",
                          }}
                        />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        {showPassword ? (
                          <img
                            src={eyeOff}
                            alt="Hide password"
                            className="icons-width"
                            style={{
                              height: "20px",
                              width: "20px",
                              cursor: "pointer",
                              marginRight: "10px",
                            }}
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          />
                        ) : (
                          <img
                            src={eyeOn}
                            alt="Show password"
                            className="icons-width"
                            style={{
                              height: "20px",
                              width: "20px",
                              cursor: "pointer",
                              marginRight: "10px",
                            }}
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          />
                        )}
                      </InputAdornment>
                    ),
                    style: {
                      width: "100%",
                    },
                  }}
                  inputProps={{
                    style: {
                      padding: "16px",
                      fontSize: "12px",
                    },
                  }}
                />
              </Box>
            </ThemeProvider>
          </Box>
          <Box pt={1}>
            <ThemeProvider theme={theme}>
              <Box pt={0.5} width={"90%"} m={"0 auto"}>
                <TextField
                  size="small"
                  error={confirmPassword !== "" && confirmPassword !== password}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  variant="outlined"
                  placeholder="Confirm Password"
                  type={showConfirmPassword ? "text" : "password"}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src={Lock}
                          alt="Lock icon"
                          className="icons-width"
                          style={{
                            height: "20px",
                            width: "20px",
                            // marginLeft: "10px",
                          }}
                        />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        {showConfirmPassword ? (
                          <img
                            src={eyeOff}
                            alt="Hide confirm password"
                            className="icons-width"
                            style={{
                              height: "20px",
                              width: "20px",
                              cursor: "pointer",
                              marginRight: "10px",
                            }}
                            onClick={handleClickShowConfirmPassword}
                            onMouseDown={handleMouseDownConfirmPassword}
                          />
                        ) : (
                          <img
                            src={eyeOn}
                            alt="Show confirm password"
                            className="icons-width"
                            style={{
                              height: "20px",
                              width: "20px",
                              cursor: "pointer",
                              marginRight: "10px",
                            }}
                            onClick={handleClickShowConfirmPassword}
                            onMouseDown={handleMouseDownConfirmPassword}
                          />
                        )}
                      </InputAdornment>
                    ),
                    style: {
                      width: "100%",
                    },
                  }}
                  inputProps={{
                    style: {
                      padding: "16px",
                      fontSize: "12px",
                    },
                  }}
                />
                {confirmPassword !== "" && confirmPassword !== password && (
                  <Typography
                    textAlign={"start"}
                    color={"rgb(247, 50, 50)"}
                    fontSize={"11px"}
                    fontWeight={"bold"}
                  >
                    Invalid Password
                  </Typography>
                )}
              </Box>
            </ThemeProvider>
          </Box>

          <Box pt={5}>
            <Button
              disabled={
                confirmPassword === "" ||
                password === "" ||
                password !== confirmPassword
                  ? true
                  : false
              }
              variant="contained"
              className="btn"
              onClick={(e) => {
                newPassword(receiveEmail?.state?.email, password);
              }}
            >
              Log in
            </Button>
          </Box>
        </Box>
      </Paper>
      <Snackbar
        open={openSnackbar?.openSnack}
        autoHideDuration={2000}
        onClose={() => setOpenSnackbar({ openSnack: false, snackMessage: "" })}
        anchorOrigin={{ vertical: "center", horizontal: "center" }}
      >
        <Alert
          onClose={() =>
            setOpenSnackbar({ openSnack: false, snackMessage: "" })
          }
          severity={
            openSnackbar?.snackMessage === "success" ? "success" : "error"
          }
          variant="filled"
          sx={{
            width: "100%",
            height: "2.5rem",
            fontSize: "0.9rem",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          action={
            <>
              <span
                onClick={() =>
                  setOpenSnackbar({ openSnack: false, snackMessage: "" })
                }
                style={{
                  cursor: "pointer",
                  m: "auto 0",
                  fontSize: "13px",
                  color: "rgb(231, 231, 231)",
                }}
              >
                Close
              </span>
            </>
          }
        >
          {openSnackbar.snackMessage === "success"
            ? "Successfully change password"
            : "Athentication Error, Please try again"}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Reset;
const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          margin: "0 auto",
          "& .MuiInput-underline:before": {
            borderBottomColor: "#6f45a4 !important", // Inactive underline color
          },
          "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
            borderBottomColor: "#6f45a4 !important", // Hover underline color
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "#6f45a4 !important", // Focused underline color
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: "12px",
          color: "#90919b",
          width: "85%",
          "&:hover:not(.Mui-disabled):before": {
            borderBottomColor: "#6f45a4",
          },
        },
      },
    },
  },
});
