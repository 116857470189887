import { createSlice } from "@reduxjs/toolkit";
import { act } from "react";

const initialState = {
  open: false,
  message: [],
  senderName: "",
  chatId: null, // Add chatId to the state
  callNotification: [], // Add notification to call
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    showNotification: (state, action) => {
      const { message, chatId } = action.payload;
      state.open = true;
      state.message = message;
      state.chatId = chatId; // Save chatId
    },
    hideNotification: (state) => {
      state.open = false;
      state.message = "";
      state.senderName = "";
      state.chatId = null; // Clear chatId
    },
    setCallNotification: (state, action) => {
      state.callNotification = action.payload;
    },
  },
});

export const { showNotification, hideNotification, setCallNotification } =
  notificationSlice.actions;
export default notificationSlice.reducer;
