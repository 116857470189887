import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Snackbar, Alert, Box, Avatar, Typography } from "@mui/material";
import { setRenderNewEmail } from "../../../services/store/slice/EmailSlice";
import { settings as s } from "../../../services/Settings";
import { useNavigate } from "react-router-dom";

const GlobalSnackbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [newEmailData, setNewEmailData] = useState([]);
  const [open, setOpen] = useState(false);
  const realTimeEmailData = useSelector(
    (state) => state?.email?.realTimeEmailData
  );
  const renderNewEmail = useSelector((state) => state?.email?.renderNewEmail);
  const handleClose = () => {
    setOpen(false);
  };

  // region useEffects
  useEffect(() => {
    setNewEmailData(realTimeEmailData);
  }, [realTimeEmailData]);
  useEffect(() => {
    if (renderNewEmail) {
      setOpen(renderNewEmail);
      dispatch(setRenderNewEmail(false));
    }
  }, [renderNewEmail]);

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
      onClick={() => {
        navigate("/emails/inbox");
      }}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <Alert
        onClose={handleClose}
        severity={"info"}
        variant="filled"
        icon={false}
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          bgcolor: "gray",
        }}
      >
        <Box display={"flex"} alignItems={"center"}>
          <Avatar
            src={`${s?.baseUrl}/${newEmailData?.sender?.profilePicPath}`}
            alt={`${newEmailData?.sender?.firstName} ${newEmailData?.sender?.lastName}`}
            sx={{
              marginRight: 2,
              width: { md: "35px", xs: "35px" },
              height: { md: "35px", xs: "35px" },
            }}
          />
          <Box>
            <Typography sx={{ curser: "pointer" }}>
              {`${newEmailData?.sender?.firstName} ${newEmailData?.sender?.lastName}`}
            </Typography>
            <Box
              dangerouslySetInnerHTML={{
                __html:
                  newEmailData?.body && newEmailData?.body?.length > 20
                    ? newEmailData?.body.slice(0, 20) + "..."
                    : newEmailData?.body || "",
              }}
            />
          </Box>
        </Box>
      </Alert>
    </Snackbar>
  );
};

export default GlobalSnackbar;
