import { Grid } from "@mui/material";
import React, { lazy, Suspense, useState } from "react";
import EmailSidebar from "./emailSidebar/emailSidebar";
import EmailWindow from "./emailWindow/emailWindow";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

export default function Emails() {
  const emailIds = useSelector((state) => state?.chat?.emailId);
  return (
    <Grid container sx={{ background: "#f5f5f5" }} justifyContent={"center"}>
      <Grid
        sx={{
          background: "white",
          borderRadius: "6px",
          border: "1px solid lightgray",
          height: { xl: "97vh", lg: "97vh", md: "97vh" },
          m: 0.5,
          p: 1,
        }}
        item
        xs={11}
        sm={11}
        md={1.8}
        lg={1.8}
        xl={1.8}
      >
        <EmailSidebar />
      </Grid>
      {/* {emailIds && (
        <Grid
          sx={{
            background: "white",
            borderRadius: "6px",
            border: "1px solid lightgray",
            height: { xl: "97vh", lg: "97vh", md: "97vh" },
            m: 0.5,
            //p: 1,
            display: { xs: "none", sm: "none", md: "block" },
          }}
          item
          xs={11}
          sm={11}
          md={2.7}
          lg={2.7}
          xl={2.7}
        >
          <AllEmailsSideBar />
        </Grid>
      )} */}

      <Grid
        sx={{
          // background: "white",
          borderRadius: "6px",
          // border: "1px solid lightgray",
          m: 0.5,
          // p: "5px",
          height: { xs: "90vh", xl: "97vh", lg: "97vh", md: "97vh" },
          overflowY: "auto",
        }}
        item
        xs={11}
        sm={11}
        md={10}
        lg={10}
        xl={10}
      >
        <Outlet />
      </Grid>
    </Grid>
  );
}
