import {
  Typography,
  Avatar,
  IconButton,
  MenuItem,
  Menu,
  Box,
  Tooltip,
  Modal,
  Divider,
  TextField,
  Button,
  Autocomplete,
  Input,
  Grid,
  Chip,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import UndoRoundedIcon from "@mui/icons-material/UndoRounded";
import RedoRoundedIcon from "@mui/icons-material/RedoRounded";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./Reply.css";
import { settings as s } from "../../../../services/Settings";
import {
  sentEmailData,
  getUsersList,
  getUsersById,
} from "../../../../services/api/apiManager";
import { useSnackbar } from "notistack";
import excel from "../../../../../assets/images/excel.png";
import pdf from "../../../../../assets/images/pdf.png";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Quill } from "react-quill";
import EmojiPicker from "emoji-picker-react";

function Reply({
  bodyData,
  replyShow,
  toEmail,
  toName,
  dateTime,
  replyprofilePic,
  replyName,
  replyEmail,
  subject,
  forwardShow,
  parentEmailId,
  senderId,
  setReplyShow,
  setForwardShow,
  setDateTime,
  setToName,
  setToEmail,
  emailTypeReply,
}) {
  const { enqueueSnackbar } = useSnackbar();
  let UserId = localStorage.getItem("user_id");
  const [bodyValue, setBodyValue] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteFile, setDeleteFile] = useState(null);
  const [starColor, setStarColor] = useState("#828282");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptionsCc, setSelectedOptionsCc] = useState([]);
  const [selectedOptionsBcc, setSelectedOptionsBcc] = useState([]);
  const [selectedFile, setSelectedFile] = useState([]);
  const [showCc, setShowCc] = useState(false);
  const [showBcc, setShowBcc] = useState(false);
  const [open, setOpen] = useState(false);
  const [openCc, setOpenCc] = useState(false);
  const [openBcc, setOpenBcc] = useState(false);
  const [userdata, setUserdata] = useState([]);
  const [emailType, setEmailType] = useState("Internal");
  const [singleUserdata, setSingleUserdata] = useState([]);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [toValue, setToValue] = useState("");
  const [ccValue, setCcValue] = useState("");
  const [bccValue, setBccValue] = useState("");

  const fileInputRef = useRef(null);
  const boxRef = useRef(null);
  const autocompleteRef = useRef(null);
  const quillRef = useRef(null);
  const pickerRef = useRef(null); // Reference for the emoji picker box

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setShowEmojiPicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleEmojiClick = (emojiObject, event) => {
    quillRef?.current?.focus();
    if (emojiObject && emojiObject.emoji) {
      const editor = quillRef.current.getEditor();
      const range = editor.getSelection(); // Get the current cursor position
      const emoji = emojiObject.emoji;

      if (range) {
        editor.insertText(range.index, emoji); // Insert emoji at the cursor position
        editor.setSelection(range.index + emoji.length); // Move cursor after the emoji
      }
    } else {
      console.error("Emoji object or emoji property is undefined.");
    }
  };
  useEffect(() => {
    pickerRef?.current?.focus();
  }, [bodyValue]);

  // Forwarded content in HTML
  const forwardedContent = `
  <div class="non-editable">
    <p><strong>------ Forwarded Message ------</strong></p>
    <p>From: <strong>${replyName}</strong> &lt;${replyEmail}&gt;</p>
    <p>Date: ${new Date(dateTime).toLocaleString("en-GB")}</p>
    <p>Subject: ${subject}</p>
    <p>To: <strong>${toName}</strong> &lt;${toEmail}&gt;</p>
    <p contenteditable="true" class="editable-body">${bodyData}</p>
    <hr>
  </div>
`;

  useEffect(() => {
    if (forwardShow) {
      setBodyValue(`${forwardedContent}<p><br></p>`);
    } else {
      setBodyValue("");
    }
  }, [forwardedContent, forwardShow]);

  // handle outside the box
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        boxRef.current &&
        !boxRef.current.contains(event.target) &&
        autocompleteRef.current &&
        !autocompleteRef.current.contains(event.target) &&
        !isMenuOpen
      ) {
        setReplyShow(false);
        setForwardShow(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // region handle emain type
  const handleEmailTypeChange = (event) => {
    setEmailType(event.target.value);
  };

  // region getUsersById
  const getUserById = async (userId) => {
    try {
      const response = await getUsersById(userId);
      if (response?.status === 200) {
        setSingleUserdata(response?.data?.result);
      }
    } catch (error) {
      console.warn(error);
    }
  };
  useEffect(() => {
    getUserById(UserId);
  }, [UserId]);

  useEffect(() => {
    getUserListData();
  }, [isMenuOpen]);

  // Handle file selection
  const handleFileChange = (event) => {
    const files = event.target.files;
    setSelectedFile(Array.from(files));
  };

  // Trigger file input when the IconButton is clicked
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  // region get userListData
  const getUserListData = async () => {
    try {
      const response = await getUsersList();
      if (response?.status === 200) {
        setUserdata(response?.data?.result);
      }
    } catch (error) {
      console.warn(error);
    }
  };
  // region handle Autocomplete
  //  region To handle
  const handleInputChange = (event, newInputValue) => {
    // setInputValue(newInputValue);
    // Open dropdown only if 3 or more characters are typed
    if (newInputValue.length >= 3) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };
  const handleChange = (event, newValue) => {
    setSelectedOptions(newValue);
    if (newValue) {
      setOpen(false);
    }
  };
  // region Cc handle
  const handleInputChangeCc = (event, newInputValue) => {
    if (newInputValue.length >= 3) {
      setOpenCc(true);
    } else {
      setOpenCc(false);
    }
  };
  const handleChangeCc = (event, newValue) => {
    setSelectedOptionsCc(newValue);
    if (newValue) {
      setOpenCc(false);
    }
  };
  // region BCc handle
  const handleInputChangeBcc = (event, newInputValue) => {
    if (newInputValue.length >= 3) {
      setOpenBcc(true);
    } else {
      setOpenBcc(false);
    }
  };
  const handleChangeBcc = (event, newValue) => {
    setSelectedOptionsBcc(newValue);
    if (newValue) {
      setOpenBcc(false);
    }
  };

  // region Reply email
  const ReplyEmails = async (data) => {
    try {
      const formData = new FormData();
      {
        data?.externalEmailType === "Internal" &&
          formData.append("ParentEmailId", data?.parentEmailId);
      }

      formData.append("SenderId", Number(data?.UserId));
      formData.append("Subject", data?.subject);
      formData.append("Body", data?.bodyValue);
      formData.append("EmailType", data?.EmailType);
      formData.append("IsRead", false);
      formData.append("IsFav", false);
      formData.append("IsDraft", false);
      {
        data?.externalEmailType === "Internal" &&
          formData.append(`Recipients[0].UserId`, data?.senderId);
      }

      formData.append(`Recipients[0].recipientemail`, data?.replyEmail);
      formData.append(`Recipients[0].RecipientType`, "To");
      for (let index = 0; index < data?.File?.length; index++) {
        formData.append("Files", data?.File[index]);
      }
      const response = await sentEmailData(formData);
      if (response?.status === 200) {
        setBodyValue("");
        setReplyShow(false);
        setForwardShow(false);
        setSelectedFile([]);
        enqueueSnackbar("Sent Email", { variant: "success" });
      } else {
        enqueueSnackbar("Error", { variant: "error" });
      }
    } catch (error) {
      console.warn(error);
      enqueueSnackbar("Error", { variant: "error" });
    }
  };

  // region Reply email
  const ForwardEmail = async (data) => {
    try {
      const formData = new FormData();
      // formData.append("ParentEmailId", data?.ParentEmailId);
      formData.append("SenderId", Number(data?.SenderId));
      formData.append("Subject", data?.Subject);
      formData.append("Body", data?.Body);
      formData.append("EmailType", data?.EmailType);
      formData.append("IsRead", false);
      formData.append("IsFav", false);
      formData.append("IsDraft", false);
      for (let index = 0; index < data?.File?.length; index++) {
        formData.append("Files", data?.File[index]);
      }

      // For Internal
      // if (data?.EmailType === "Internal") {
      data?.RecipentData?.forEach((toData, index) => {
        formData.append(`Recipients[${index}].UserId`, toData?.userId);
        formData.append(`Recipients[${index}].recipientemail`, toData?.email);
        formData.append(`Recipients[${index}].RecipientType`, "To");
      });
      data?.RecipentDataCc?.forEach((toData, index) => {
        formData.append(
          `Recipients[${data?.RecipentData?.length + index}].UserId`,
          toData?.userId
        );
        formData.append(
          `Recipients[${data?.RecipentData?.length + index}].recipientemail`,
          toData?.email
        );
        formData.append(
          `Recipients[${data?.RecipentData?.length + index}].RecipientType`,
          "CC"
        );
      });
      data?.RecipentDataBcc?.forEach((toData, index) => {
        formData.append(
          `Recipients[${
            data?.RecipentDataCc?.length + data?.RecipentData?.length + index
          }].UserId`,
          toData?.userId
        );
        formData.append(
          `Recipients[${
            data?.RecipentDataCc?.length + data?.RecipentData?.length + index
          }].recipientemail`,
          toData?.email
        );
        formData.append(
          `Recipients[${
            data?.RecipentDataCc?.length + data?.RecipentData?.length + index
          }].RecipientType`,
          "BCC"
        );
      });
      // }
      // for External Email
      if (data?.EmailType === "External") {
        formData.append(`Recipients[0].recipientemail`, data?.ToValue);
        formData.append(`Recipients[0].RecipientType`, "To");
        if (data?.CcValue?.length > 0) {
          formData.append(`Recipients[1].recipientemail`, data?.CcValue);
          formData.append(`Recipients[1].RecipientType`, "CC");
        }
        if (data?.BccValue?.length > 0) {
          formData.append(
            `Recipients[${data?.CcValue?.length > 0 ? 2 : 1}].recipientemail`,
            data?.BccValue
          );
          formData.append(
            `Recipients[${data?.CcValue?.length > 0 ? 2 : 1}].RecipientType`,
            "BCC"
          );
        }
      }
      const response = await sentEmailData(formData);

      if (response?.status === 200) {
        setBodyValue("");
        setReplyShow(false);
        setForwardShow(false);
        setSelectedOptions([]);
        setSelectedOptionsCc([]);
        setSelectedOptionsBcc([]);
        setSelectedFile([]);
        enqueueSnackbar("Forward Email", { variant: "success" });
      } else {
        enqueueSnackbar("Error", { variant: "error" });
      }
    } catch (error) {
      console.warn(error);
      enqueueSnackbar("Error", { variant: "error" });
    }
  };

  const modules = {
    toolbar: {
      container: "#toolbar",
    },
  };

  // const modules = {
  //   toolbar: [["bold", "italic", "underline"]],
  // };

  // Handle opening of the menu
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setIsMenuOpen(true);
  };

  // Handle closing of the menu
  const handleMenuClose = () => {
    setAnchorEl(null);
    setIsMenuOpen(false);
  };

  return (
    <Box>
      {(replyShow || forwardShow) && (
        <Grid ref={boxRef} container spacing={1}>
          {/* Avatar */}
          <Grid item xs={1} md={0.8} sm={1} xl={1} lg={1}>
            <Box>
              <Avatar
                src={`${s?.baseUrl}/${replyprofilePic}`}
                alt={replyName}
                sx={{
                  marginRight: 2,
                  width: { xs: "20px", sm: "25px", md: "30px", lg: "40px" },
                  height: { xs: "20px", sm: "25px", md: "30px", lg: "40px" },
                  boxShadow: "1px 1px 5px 0 black",
                }}
              />
            </Box>
          </Grid>
          {/* Reply Container */}
          <Grid
            item
            xs={10.6}
            sm={10.6}
            md={10.6}
            xl={11}
            lg={11}
            sx={{
              boxShadow: "1px 1px 6px 0 black",
              borderRadius: "10px ",
              bgcolor: "white",
            }}
          >
            {replyShow && (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "left",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: "3px" }}>
                  <UndoRoundedIcon fontSize="small" sx={{ color: "gray" }} />
                  <Typography sx={{ fontSize: "14px", color: "grey" }}>
                    Reply
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: "grey" }}>
                    ({replyEmail})
                  </Typography>
                </Box>
              </Box>
            )}

            {/* Forward */}

            {forwardShow && (
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginRight: "10px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { md: "row", xs: "column" },
                      alignItems: { md: "center" },
                      gap: { md: 2 },
                    }}
                  >
                    <Typography sx={{ fontSize: "12px" }}>
                      Email Type:
                    </Typography>
                    <RadioGroup
                      row
                      value={emailType}
                      onChange={handleEmailTypeChange}
                      sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                    >
                      <FormControlLabel
                        value="Internal"
                        control={
                          <Radio
                            color="success"
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 12,
                              },
                            }}
                          />
                        }
                        label={
                          <Typography sx={{ fontSize: "12px" }}>
                            Internal
                          </Typography>
                        }
                      />
                      {singleUserdata[0]?.isExternalEmailAllowed === true && (
                        <FormControlLabel
                          value="External"
                          control={
                            <Radio
                              color="success"
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  fontSize: 15,
                                },
                              }}
                            />
                          }
                          label={
                            <Typography variant="subtitle2">
                              External
                            </Typography>
                          }
                        />
                      )}
                    </RadioGroup>
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "left",
                  }}
                >
                  <RedoRoundedIcon
                    fontSize="small"
                    sx={{ color: "gray", mt: "10px" }}
                  />

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "3px",

                      width: "100%",
                    }}
                  >
                    {/* <Typography sx={{ fontSize: "14px", color: "grey" }}>
                    Fly
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: "grey" }}>
                    ({replyEmail})
                  </Typography> */}
                    {/* To CC And BCC */}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      {/* To field */}

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          //alignItems: "center",
                          flex: 1,
                          borderBottom: "1px solid lightgray",
                          p: 1,
                        }}
                      >
                        <Box display={"flex"} gap={"1rem"}>
                          <Typography
                            sx={{
                              fontSize: "11px",
                              fontWeight: "bold",
                              border: "1px solid lightgray",
                              borderRadius: 1,
                              p: "5px 18px 0px 18px",
                              height: "28px",
                            }}
                          >
                            To
                          </Typography>
                          {emailType === "External" ? (
                            <TextField
                              size="small"
                              type="email"
                              value={toValue}
                              onChange={(e) => setToValue(e.target.value)}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    border: "none", // Removes the border
                                  },
                                  "&:hover fieldset": {
                                    border: "none", // Ensures no border on hover
                                  },
                                  "&.Mui-focused fieldset": {
                                    border: "none", // No border when focused
                                  },
                                },
                                ml: "-15px",
                                mt: "-7px",
                              }}
                            />
                          ) : (
                            <Autocomplete
                              multiple
                              disableClearable
                              size="small"
                              open={open}
                              onInputChange={handleInputChange}
                              onChange={handleChange}
                              options={userdata}
                              getOptionLabel={(option) =>
                                `${option?.firstName} ${option?.lastName} (${
                                  option.email.length > 10
                                    ? option.email.slice(0, 10)
                                    : option?.email
                                })`
                              }
                              value={selectedOptions}
                              renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                  <Tooltip
                                    arrow
                                    title={
                                      <span style={{ fontSize: "0.75rem" }}>
                                        {option?.email}
                                      </span>
                                    }
                                    key={option?.email}
                                  >
                                    <Chip
                                      label={`${option?.firstName} ${option?.lastName}`}
                                      {...getTagProps({ index })}
                                      key={index}
                                      sx={{
                                        height: 24,
                                        fontSize: 12,
                                      }}
                                    />
                                  </Tooltip>
                                ))
                              }
                              renderOption={(props, option) => (
                                <li {...props}>
                                  <Avatar
                                    src={`${s?.baseUrl}/${option?.profilePicPath}`}
                                    alt={`${option?.firstName} ${option?.lastName}`}
                                    sx={{
                                      marginRight: 2,
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      width: "100%",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: "10px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {option?.firstName} {option?.lastName}
                                    </span>
                                    <span
                                      style={{
                                        fontSize: "10px",
                                        color: "#888",
                                      }}
                                    >
                                      {option?.email}
                                    </span>
                                  </div>
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: null,
                                    sx: {
                                      minWidth: 190,
                                      height: "auto",
                                      //minHeight: 25,
                                      paddingTop: "2px",
                                      paddingBottom: "2px",
                                      display: "flex",
                                      flexWrap: "wrap",
                                      alignItems: "center",
                                      "& .MuiOutlinedInput-notchedOutline": {
                                        border: "none",
                                      },
                                    },
                                  }}
                                  sx={{
                                    "& .MuiInputLabel-root": {
                                      fontSize: 12,
                                    },
                                    "& .MuiAutocomplete-tag": {
                                      fontSize: 12,
                                    },
                                  }}
                                />
                              )}
                              openOnFocus={false}
                              sx={{
                                "& .MuiAutocomplete-option": {
                                  fontSize: 12, // Reduce option font size
                                },
                                marginLeft: "-20px",
                                marginTop: "-8px",
                                flexWrap: "wrap",
                              }}
                            />
                          )}

                          {/* autocomplete */}
                        </Box>
                        <Box sx={{ display: "flex", gap: 1 }}>
                          {/* Toggle the visibility of Cc and Bcc fields on click */}
                          <Typography
                            sx={{
                              cursor: "pointer",
                              color: showCc ? "#085F99" : "inherit",
                            }}
                            onClick={() => setShowCc(!showCc)}
                          >
                            Cc
                          </Typography>
                          <Typography
                            sx={{
                              cursor: "pointer",
                              color: showBcc ? "#085F99" : "inherit",
                            }}
                            onClick={() => setShowBcc(!showBcc)}
                          >
                            Bcc
                          </Typography>
                        </Box>
                      </Box>

                      {/* Conditionally render Cc field when "showCc" is true */}
                      {showCc && (
                        <Box>
                          <Box
                            sx={{
                              display: "flex",
                              // justifyContent: "space-between",
                              gap: 1,
                              p: 1,
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "11px",
                                fontWeight: "bold",
                                border: "1px solid lightgray",
                                borderRadius: 1,
                                p: "5px 18px 0px 18px",
                                height: "28px",
                              }}
                            >
                              Cc
                            </Typography>
                            {emailType === "External" ? (
                              <TextField
                                size="small"
                                type="email"
                                value={ccValue}
                                onChange={(e) => setCcValue(e.target.value)}
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                      border: "none", // Removes the border
                                    },
                                    "&:hover fieldset": {
                                      border: "none", // Ensures no border on hover
                                    },
                                    "&.Mui-focused fieldset": {
                                      border: "none", // No border when focused
                                    },
                                  },
                                  ml: "-15px",
                                  mt: "-7px",
                                }}
                              />
                            ) : (
                              <Autocomplete
                                multiple
                                disableClearable
                                size="small"
                                open={openCc}
                                onInputChange={handleInputChangeCc}
                                onChange={handleChangeCc}
                                options={userdata}
                                getOptionLabel={(option) =>
                                  `${option?.firstName} ${option?.lastName} (${
                                    option.email.length > 10
                                      ? option.email.slice(0, 10)
                                      : option?.email
                                  })`
                                }
                                value={selectedOptionsCc}
                                renderTags={(value, getTagProps) =>
                                  value.map((option, index) => (
                                    <Tooltip
                                      arrow
                                      title={
                                        <span style={{ fontSize: "0.75rem" }}>
                                          {option.email}
                                        </span>
                                      }
                                      key={option.email}
                                    >
                                      <Chip
                                        label={`${option?.firstName} ${option?.lastName}`}
                                        {...getTagProps({ index })}
                                        key={index}
                                        sx={{
                                          height: 24,
                                          fontSize: 12,
                                        }}
                                      />
                                    </Tooltip>
                                  ))
                                }
                                renderOption={(props, option) => (
                                  <li {...props}>
                                    <Avatar
                                      src={`${s?.baseUrl}/${option?.profilePicPath}`}
                                      alt={`${option?.firstName} ${option?.lastName}`}
                                      sx={{
                                        marginRight: 2,
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "100%",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: "10px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {option?.firstName} {option?.lastName}
                                      </span>
                                      <span
                                        style={{
                                          fontSize: "10px",
                                          color: "#888",
                                        }}
                                      >
                                        {option?.email}
                                      </span>
                                    </div>
                                  </li>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: null,
                                      sx: {
                                        minWidth: 190,
                                        height: "auto",
                                        // minHeight: 25,
                                        paddingTop: "2px",
                                        paddingBottom: "2px",
                                        display: "flex",
                                        flexWrap: "wrap",
                                        alignItems: "center",
                                        "& .MuiOutlinedInput-notchedOutline": {
                                          border: "none",
                                        },
                                      },
                                    }}
                                    sx={{
                                      "& .MuiInputLabel-root": {
                                        fontSize: 12,
                                      },
                                      "& .MuiAutocomplete-tag": {
                                        fontSize: 12,
                                      },
                                    }}
                                  />
                                )}
                                openOnFocus={false}
                                sx={{
                                  "& .MuiAutocomplete-option": {
                                    fontSize: 12, // Reduce option font size
                                  },
                                  marginLeft: "-11px",
                                  marginTop: "-8px",
                                  flexWrap: "wrap",
                                }}
                              />
                            )}
                          </Box>
                          <Divider />
                        </Box>
                      )}

                      {/* Conditionally render Bcc field when "showBcc" is true */}
                      {showBcc && (
                        <Box>
                          <Box
                            sx={{
                              display: "flex",
                              // justifyContent: "space-between",
                              gap: 1,
                              p: 1,
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "11px",
                                fontWeight: "bold",
                                border: "1px solid lightgray",
                                borderRadius: 1,
                                p: "5px 16px 0px 15px",
                                height: "28px",
                              }}
                            >
                              Bcc
                            </Typography>
                            {emailType === "External" ? (
                              <TextField
                                size="small"
                                type="email"
                                value={bccValue}
                                onChange={(e) => setBccValue(e.target.value)}
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                      border: "none", // Removes the border
                                    },
                                    "&:hover fieldset": {
                                      border: "none", // Ensures no border on hover
                                    },
                                    "&.Mui-focused fieldset": {
                                      border: "none", // No border when focused
                                    },
                                  },
                                  ml: "-15px",
                                  mt: "-7px",
                                }}
                              />
                            ) : (
                              <Autocomplete
                                multiple
                                disableClearable
                                size="small"
                                open={openBcc}
                                onInputChange={handleInputChangeBcc}
                                onChange={handleChangeBcc}
                                options={userdata}
                                getOptionLabel={(option) =>
                                  `${option?.firstName} ${option?.lastName} (${
                                    option.email.length > 10
                                      ? option.email.slice(0, 10)
                                      : option?.email
                                  })`
                                }
                                value={selectedOptionsBcc}
                                renderTags={(value, getTagProps) =>
                                  value.map((option, index) => (
                                    <Tooltip
                                      arrow
                                      title={
                                        <span style={{ fontSize: "0.75rem" }}>
                                          {option.email}
                                        </span>
                                      }
                                      key={option.email}
                                    >
                                      <Chip
                                        label={`${option?.firstName} ${option?.lastName}`}
                                        {...getTagProps({ index })}
                                        key={index}
                                        sx={{
                                          height: 24,
                                          fontSize: 12,
                                        }}
                                      />
                                    </Tooltip>
                                  ))
                                }
                                renderOption={(props, option) => (
                                  <li {...props}>
                                    <Avatar
                                      src={`${s?.baseUrl}/${option?.profilePicPath}`}
                                      alt={`${option?.firstName} ${option?.lastName}`}
                                      sx={{
                                        marginRight: 2,
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "100%",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: "10px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {option?.firstName} {option?.lastName}
                                      </span>
                                      <span
                                        style={{
                                          fontSize: "10px",
                                          color: "#888",
                                        }}
                                      >
                                        {option?.email}
                                      </span>
                                    </div>
                                  </li>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: null,
                                      sx: {
                                        minWidth: 190,
                                        height: "auto",
                                        //minHeight: 25,
                                        paddingTop: "2px",
                                        paddingBottom: "2px",
                                        display: "flex",
                                        flexWrap: "wrap",
                                        alignItems: "center",
                                        "& .MuiOutlinedInput-notchedOutline": {
                                          border: "none",
                                        },
                                      },
                                    }}
                                    sx={{
                                      "& .MuiInputLabel-root": {
                                        fontSize: 12,
                                      },
                                      "& .MuiAutocomplete-tag": {
                                        fontSize: 12,
                                      },
                                    }}
                                  />
                                )}
                                openOnFocus={false}
                                sx={{
                                  "& .MuiAutocomplete-option": {
                                    fontSize: 12, // Reduce option font size
                                  },
                                  marginLeft: "-12px",
                                  marginTop: "-8px",
                                  flexWrap: "wrap",
                                }}
                              />
                            )}
                          </Box>
                          <Divider />
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}

            <ReactQuill
              ref={quillRef}
              value={bodyValue}
              onChange={(value) => setBodyValue(value)}
              modules={modules}
              className="quill-editor"
              style={{
                height: "180px",
                width: "100%",
              }}
            />

            {/*show Attachment */}
            {selectedFile?.length > 0 && (
              <Box
                display={"flex"}
                flexWrap={"wrap"}
                gap={"10px"}
                p={"5px"}
                sx={{
                  overflowX: "hidden",
                  overflowY: "auto",
                  maxHeight: "115px",
                  width: "100%",
                  mt: "-1px",
                }}
              >
                {selectedFile?.map((item, key) => {
                  const isPdf = item?.name?.toLowerCase().endsWith(".pdf");
                  const isExcel =
                    item?.name?.toLowerCase().endsWith(".xlsx") ||
                    item?.name?.toLowerCase().endsWith(".xls");
                  return (
                    <Box
                      key={key}
                      display={"flex"}
                      justifyContent={"space-between"}
                      border={"1px solid #00000026"}
                      p={"5px"}
                      borderRadius={"5px"}
                      height={"45px"}
                      width={"13rem"}
                    >
                      <Box
                        display={"flex"}
                        gap={"5px"}
                        alignItems={"center"}
                        width={"12rem"}
                        height={"35px"}
                      >
                        {(isPdf || isExcel) && (
                          <Box
                            sx={{ width: "25px", height: "25px" }}
                            component={"img"}
                            src={isPdf ? pdf : isExcel ? excel : null}
                          />
                        )}
                        <Tooltip arrow placement="top-start" title={item?.name}>
                          <Typography fontSize={"12px"} fontWeight={"500px"}>
                            {item?.name?.length > 23
                              ? item?.name?.slice(0, 23)
                              : item?.name}
                          </Typography>
                        </Tooltip>
                      </Box>
                      <IconButton
                        onClick={(event) => {
                          handleMenuOpen(event);
                          setDeleteFile(key);
                        }}
                        sx={{ width: "20px", height: "20px" }}
                      >
                        <MoreHorizIcon />
                      </IconButton>
                    </Box>
                  );
                })}
              </Box>
            )}
            <Box mr={"6px"}>
              <CustomToolbar />
            </Box>
            {/* Button below the editor */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                p: "0.5rem 1rem",
              }}
            >
              <Box display={"flex"} gap={"10px"} alignItems={"center"}>
                <Button
                  onClick={() => {
                    replyShow
                      ? ReplyEmails({
                          bodyValue: bodyValue,
                          replyEmail: replyEmail,
                          senderId: senderId,
                          parentEmailId: parentEmailId,
                          subject: subject,
                          UserId: UserId,
                          externalEmailType: emailTypeReply,
                          EmailType:
                            emailTypeReply === "External"
                              ? "External"
                              : emailType,
                          File: selectedFile,
                        })
                      : ForwardEmail({
                          // ParentEmailId: 1,
                          SenderId: UserId,
                          Subject: subject,
                          Body: bodyValue,
                          File: selectedFile,
                          // internal
                          EmailType:
                            emailTypeReply === "External"
                              ? "External"
                              : emailType,
                          RecipentData: selectedOptions,
                          RecipentDataCc: selectedOptionsCc,
                          RecipentDataBcc: selectedOptionsBcc,
                          // External
                          BccValue: bccValue,
                          CcValue: ccValue,
                          ToValue: toValue,
                        });
                  }}
                  sx={{ textTransform: "capitalize", padding: "5px 18px" }}
                  // disabled={!replyShow && selectedOptions?.length === 0}
                  variant="contained"
                  color="primary"
                >
                  Send
                </Button>
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  multiple
                />
                <IconButton onClick={handleButtonClick}>
                  <AttachFileIcon />
                </IconButton>

                <Box
                  ref={pickerRef}
                  sx={{ position: "relative", display: "inline-block" }}
                >
                  <Typography
                    onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                    sx={{
                      marginTop: "5px",
                      fontSize: "1.2em",
                      borderRadius: "50%",
                      cursor: "pointer",
                    }}
                  >
                    🙂
                  </Typography>
                  {/* Emoji Picker */}
                  {showEmojiPicker && (
                    <Box
                      sx={{
                        position: "fixed",
                        top: "40px",
                        right: "16vw",
                        zIndex: 10,
                      }}
                    >
                      <EmojiPicker onEmojiClick={handleEmojiClick} />
                    </Box>
                  )}
                </Box>
              </Box>
              <IconButton
                onClick={() => {
                  setReplyShow(false);
                  setForwardShow(false);
                  setSelectedFile([]);
                }}
              >
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      )}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            handleMenuClose();
          }}
          sx={{ height: "30px" }}
        >
          Save As
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            handleMenuClose(); // Close menu
            setSelectedFile(
              selectedFile?.filter((item, key) => key !== deleteFile)
            );
          }}
          sx={{ color: "red", height: "30px" }}
        >
          Delete
        </MenuItem>
      </Menu>
    </Box>
  );
}

export default Reply;

const CustomToolbar = () => (
  <div id="toolbar">
    {/* Font Style */}
    <select className="ql-font" defaultValue="" title="Font">
      <option value="sans-serif">Sans Serif</option>
      <option value="serif">Serif</option>
      <option value="monospace">Monospace</option>
    </select>

    {/* Font Size */}
    <select className="ql-size" defaultValue="" title="Font Size">
      <option value="small">Small</option>
      <option value="">Normal</option>
      <option value="large">Large</option>
      <option value="huge">Huge</option>
    </select>

    {/* Bold, Italic, Underline, Strikethrough */}
    <button className="ql-bold" title="Bold"></button>
    <button className="ql-italic" title="Italic"></button>
    <button className="ql-underline" title="Underline"></button>
    <button className="ql-strike" title="Strikethrough"></button>

    {/* Color */}
    <select className="ql-color" title="Text Color"></select>
    <select className="ql-background" title="Background Color"></select>

    {/* Subscript / Superscript */}
    <button className="ql-script" value="sub" title="Subscript"></button>
    <button className="ql-script" value="super" title="Superscript"></button>

    {/* Headers */}
    <select className="ql-header" defaultValue="" title="Header">
      <option value="1">Heading 1</option>
      <option value="2">Heading 2</option>
      <option value="" defaultValue>
        Normal
      </option>
    </select>

    {/* List */}
    <button className="ql-list" value="ordered" title="Ordered List"></button>
    <button className="ql-list" value="bullet" title="Bullet List"></button>

    {/* Indent */}
    <button className="ql-indent" value="-1" title="Decrease Indent"></button>
    <button className="ql-indent" value="+1" title="Increase Indent"></button>

    {/* Align */}
    <select
      className="ql-align"
      defaultValue=""
      title="Text Alignment"
    ></select>

    {/* Links, Images, Video */}
    <button className="ql-link" title="Insert Link"></button>
    {/* <button className="ql-image" title="Insert Image"></button> */}
    {/* <button className="ql-video" title="Insert Video"></button> */}

    {/* Clean */}
    <button className="ql-clean" title="Remove Formatting"></button>
  </div>
);
