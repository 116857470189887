import React, { useState } from "react";
import { BgCallPopupContext } from "../../../services/context/BgCallPopupContext";
import { useLocation, useNavigate } from "react-router-dom";
import { Avatar, Box } from "@mui/material";
import { settings as s } from "../../../services/Settings";
import { useSelector } from "react-redux";

const BackgroundCallPopup = () => {
  const { callDuration, userById, callerName, contactImage } = useSelector(
    (state) => state.call
  );
  const { isCallActive } = BgCallPopupContext();
  const [position, setPosition] = useState({ x: 150, y: 20 });
  const navigator = useNavigate();
  const currentLocation = useLocation();
  const handleDrag = (e) => {
    setPosition({ x: e.clientX, y: e.clientY });
  };

  // Format duration to MM:SS
  const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(
      2,
      "0"
    )}`;
  };
  return (
    <Box
      draggable
      onDrag={handleDrag}
      onDragEnd={(e) => {
        setPosition({ x: e.clientX, y: e.clientY });
      }}
      style={{
        display:
          isCallActive === false
            ? "none"
            : isCallActive && currentLocation.pathname === "/chats"
            ? "none"
            : isCallActive && currentLocation.pathname === "/meetings"
            ? "none"
            : "block",
        position: "absolute",
        left: position.x,
        top: position.y,
        width: "16%",
        height: "20vh",
        backgroundColor: "#081C27",
        cursor: "move",
        zIndex: 999,
      }}
    >
      <Box>
        <Box
          width={"95%"}
          height={"30px"}
          display={"flex"}
          justifyContent={"flex-end"}
        >
          {/* <span style={{ color: "red", fontWeight: "bold", cursor: "pointer" }}>
            X
          </span> */}
        </Box>

        <Avatar
          sx={{
            fontSize: "30px",
            fontWeight: "bold",
            cursor: "pointer",
            margin: "auto",
            width: { xs: 40, sm: 40 },
            height: { xs: 40, sm: 40 },
            objectFit: "cover",
          }}
          alt="Profile"
          src={
            userById?.profilePicPath
              ? `${s.baseUrl}${userById?.profilePicPath}`
              : `${s.baseUrl}${contactImage}`
          }
          loading="lazy"
        >
          {!userById?.profilePicPath &&
          userById?.firstName?.charAt(0) &&
          userById?.lastName?.charAt(0)
            ? userById?.firstName.charAt(0) + userById?.lastName.charAt(0)
            : callerName.charAt(0) + callerName.charAt(1)}
        </Avatar>

        <p style={{ textAlign: "center", color: "wheat" }}>
          Call with{" "}
          {userById?.firstName && userById?.lastName
            ? `${userById.firstName} ${userById.lastName}`
            : callerName}
        </p>
        <p style={{ textAlign: "center", color: "wheat", fontSize: "12px" }}>
          {formatDuration(callDuration)}
        </p>

        <Box
          width={"100%"}
          height={"30px"}
          display={"flex"}
          justifyContent={"center"}
        >
          <button
            onClick={() => {
              navigator("/chats");
            }}
            style={{
              width: "80px",
              height: "30px",
              backgroundColor: "wheat",
              color: "black",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Go To Call
          </button>
        </Box>
      </Box>
    </Box>
  );
};

export default BackgroundCallPopup;
