import {
  Box,
  createTheme,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  ThemeProvider,
  Typography,
  Input,
  IconButton,
  Button,
  Autocomplete,
  Chip,
  Avatar,
  ListItemText,
  Divider,
  Menu,
  Tooltip,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import DeleteIcon from "@mui/icons-material/Delete";
import { Close, Height } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./Editor.css";
import excel from "../../../../../../assets/images/excel.png";
import pdf from "../../../../../../assets/images/pdf.png";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useDispatch, useSelector } from "react-redux";
import {
  getEmailDetailList,
  getUsersList,
  updateEmail,
  getUsersById,
} from "../../../../../services/api/apiManager";
import { settings as s } from "../../../../../services/Settings";
import { sentEmailData } from "../../../../../services/api/apiManager";
import { useSnackbar } from "notistack";
import {
  setDraftEmails,
  setPendingDraftEmails,
} from "../../../../../services/store/slice/ChatSlice";
import {
  setOpenDraftModal,
  setEmptyEmailInputs,
  setEmptyEmails,
} from "../../../../../services/store/slice/EmailSlice";
import { newEmailNotification } from "../../../../../services/signalRService/signalRService";
import EmojiPicker from "emoji-picker-react";

function NewEmail() {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [emailDetailsData, setEmailDetailsData] = useState([]);
  const [bodyValue, setBodyValue] = useState("");
  const [subject, setSubject] = useState("");
  const [emailType, setEmailType] = useState("Internal");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptionsCc, setSelectedOptionsCc] = useState([]);
  const [selectedOptionsBcc, setSelectedOptionsBcc] = useState([]);
  const [selectedFile, setSelectedFile] = useState([]);
  const [showCc, setShowCc] = useState(false);
  const [showBcc, setShowBcc] = useState(false);
  const [open, setOpen] = useState(false);
  const [openCc, setOpenCc] = useState(false);
  const [openBcc, setOpenBcc] = useState(false);
  // External
  const [toValue, setToValue] = useState("");
  const [ccValue, setCcValue] = useState("");
  const [bccValue, setBccValue] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteFile, setDeleteFile] = useState(null);
  const [downloadFile, setDownloadFile] = useState(null);
  const [userdata, setUserdata] = useState([]);
  const [singleUserdata, setSingleUserdata] = useState([]);
  const userId = localStorage.getItem("user_id");

  const pickerRef = useRef(null); // Reference for the emoji picker box

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setShowEmojiPicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const emptyEmails = useSelector((state) => state?.email?.emptyEmails);
  useEffect(() => {
    if (emptyEmails) {
      setSelectedFile([]);
      setSelectedOptionsBcc([]);
      setSelectedOptionsCc([]);
      setSelectedOptions([]);
      setSubject("");
      setBodyValue("");
      setToValue("");
      setCcValue("");
      setBccValue("");
      setShowCc(false);
      setShowBcc(false);
      dispatch(setEmptyEmails(false));
    }
  }, [emptyEmails]);

  // region getting pending EmailsData from DraftMails.js file

  const pendingEmailsId = useSelector(
    (state) => state?.chat?.pendingDraftEmails
  );

  const { isDraftEmail } = useSelector((state) => state?.email);

  const actionTypeRestDraftEmails = useSelector(
    (state) => state?.chat?.resetDraftEmails
  );
  // region empty
  const emptyEmailInputs = useSelector(
    (state) => state?.email?.emptyEmailInputs
  );

  const [anchorElValue, setAnchorElValue] = useState(null);
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);

  // Handle file selection
  const handleFileChange = (event) => {
    const newfiles = Array.from(event.target.files);
    setSelectedFile((prevFiles) => [...prevFiles, ...newfiles]);
    event.target.value = null;
  };
  // Download Attachment File
  const handleDownload = (file) => {
    if (!file || !(file instanceof Blob)) {
      console.error("Invalid file type");
      return;
    }
    // Create a download link
    const link = document.createElement("a");
    link.href = URL.createObjectURL(file);
    link.download = file.name;
    link.click();
    // Clean up the URL object after the download
    URL.revokeObjectURL(link.href);
  };
  // Trigger file input when the IconButton is clicked
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  // region To handle

  const handleInputChange = (event, newInputValue) => {
    // setInputValue(newInputValue);
    // Open dropdown only if 3 or more characters are typed
    if (newInputValue?.length >= 3) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };
  const handleChange = (event, newValue) => {
    setSelectedOptions(newValue);
    if (newValue) {
      setOpen(false);
    }
  };
  // region Cc handle
  const handleInputChangeCc = (event, newInputValue) => {
    if (newInputValue.length >= 3) {
      setOpenCc(true);
    } else {
      setOpenCc(false);
    }
  };
  const handleChangeCc = (event, newValue) => {
    setSelectedOptionsCc(newValue);
    if (newValue) {
      setOpenCc(false);
    }
  };
  // region BCc handle
  const handleInputChangeBcc = (event, newInputValue) => {
    if (newInputValue?.length >= 3) {
      setOpenBcc(true);
    } else {
      setOpenBcc(false);
    }
  };
  const handleChangeBcc = (event, newValue) => {
    setSelectedOptionsBcc(newValue);
    if (newValue) {
      setOpenBcc(false);
    }
  };
  // region handle emain type
  const handleEmailTypeChange = (event) => {
    setEmailType(event.target.value);
  };

  // region get userListData
  const getUserListData = async () => {
    try {
      const response = await getUsersList();
      if (response?.status === 200) {
        setUserdata(response?.data?.result);
      }
    } catch (error) {
      console.warn(error);
    }
  };
  // region getUsersById
  const getUserById = async (userId) => {
    try {
      const response = await getUsersById(userId);
      if (response?.status === 200) {
        setSingleUserdata(response?.data?.result);
      }
    } catch (error) {
      console.warn(error);
    }
  };
  useEffect(() => {
    getUserById(userId);
  }, [userId]);
  // region sentEmail
  const sentEmails = async (data) => {
    try {
      const formData = new FormData();
      // formData.append("ParentEmailId", data?.ParentEmailId);
      formData.append("SenderId", Number(data?.SenderId));
      formData.append("Subject", data.Subject);
      formData.append("Body", data?.Body);
      formData.append("EmailType", data?.EmailType);
      formData.append("IsRead", false);
      formData.append("IsFav", false);
      formData.append("IsDraft", false);
      formData.append("CreatedBy", userId);
      for (let index = 0; index < data?.File?.length; index++) {
        formData.append("Files", data?.File[index]);
      }

      // For Internal
      if (data?.EmailType === "Internal") {
        data?.RecipentData?.forEach((toData, index) => {
          formData.append(
            `Recipients[${index}].UserId`,
            toData?.userId || toData?.recipientId
          );
          formData.append(`Recipients[${index}].recipientemail`, toData?.email);
          formData.append(`Recipients[${index}].RecipientType`, "To");
        });
        data?.RecipentDataCc?.forEach((toData, index) => {
          formData.append(
            `Recipients[${data?.RecipentData?.length + index}].UserId`,
            toData?.userId || toData?.recipientId
          );
          formData.append(
            `Recipients[${data?.RecipentData?.length + index}].recipientemail`,
            toData?.email
          );
          formData.append(
            `Recipients[${data?.RecipentData?.length + index}].RecipientType`,
            "CC"
          );
        });
        data?.RecipentDataBcc?.forEach((toData, index) => {
          formData.append(
            `Recipients[${
              data?.RecipentDataCc?.length + data?.RecipentData?.length + index
            }].UserId`,
            toData?.userId || toData?.recipientId
          );
          formData.append(
            `Recipients[${
              data?.RecipentDataCc?.length + data?.RecipentData?.length + index
            }].recipientemail`,
            toData?.email
          );
          formData.append(
            `Recipients[${
              data?.RecipentDataCc?.length + data?.RecipentData?.length + index
            }].RecipientType`,
            "BCC"
          );
        });
      }
      // for External Email
      if (data?.EmailType === "External") {
        formData.append(`Recipients[0].recipientemail`, data?.ToValue);
        formData.append(`Recipients[0].RecipientType`, "To");
        if (data?.CcValue?.length > 0) {
          formData.append(`Recipients[1].recipientemail`, data?.CcValue);
          formData.append(`Recipients[1].RecipientType`, "CC");
        }
        if (data?.BccValue?.length > 0) {
          formData.append(
            `Recipients[${data?.CcValue?.length > 0 ? 2 : 1}].recipientemail`,
            data?.BccValue
          );
          formData.append(
            `Recipients[${data?.CcValue?.length > 0 ? 2 : 1}].RecipientType`,
            "BCC"
          );
        }
      }

      const response = await sentEmailData(formData);
      if (response?.status === 200) {
        dispatch(setDraftEmails(actionTypeRestDraftEmails));
        setSelectedFile([]);
        setSelectedOptionsBcc([]);
        setSelectedOptionsCc([]);
        setSelectedOptions([]);
        setSubject("");
        setBodyValue("");
        setToValue("");
        setCcValue("");
        setBccValue("");
        setShowCc(false);
        setShowBcc(false);
        if (pendingEmailsId) {
          updateEmailData({
            emailId: [pendingEmailsId],
            UserId: userId,
            Deleted: true,
          });
        }
        enqueueSnackbar("Email Sent", { variant: "success" });
      } else {
        enqueueSnackbar("Error", { variant: "error" });
      }
    } catch (error) {
      console.warn(error);
      enqueueSnackbar("Error", { variant: "error" });
    }
  };

  const updateEmailData = async (data) => {
    try {
      const respose = await updateEmail(data);
      if (respose?.status === 200) {
        dispatch(setPendingDraftEmails(null));
      }
    } catch (error) {
      console.warn(error);
    }
  };

  useEffect(() => {
    getUserListData();
  }, []);

  useEffect(() => {
    if (isDraftEmail === false) {
      setSelectedFile([]);
      setSelectedOptionsBcc([]);
      setSelectedOptionsCc([]);
      setSelectedOptions([]);
      setSubject("");
      setBodyValue("");
      setToValue("");
      setCcValue("");
      setBccValue("");
      setShowCc(false);
      setShowBcc(false);
      setOpenDraftModal(true);
    }
  }, []);

  //region draft Email Dispatching
  useEffect(() => {
    dispatch(
      setDraftEmails({
        ParentEmailId: 1,
        SenderId: userId,
        Subject: subject,
        Body: bodyValue,
        File: selectedFile,
        // internal
        EmailType: emailType,
        RecipentData: selectedOptions,
        RecipentDataCc: selectedOptionsCc,
        RecipentDataBcc: selectedOptionsBcc,
        // External
        BccValue: bccValue,
        CcValue: ccValue,
        ToValue: toValue,
      })
    );
  }, [
    selectedOptions,
    selectedOptionsCc,
    selectedOptionsBcc,
    subject,
    bodyValue,
  ]);

  // region Empty form data
  const handleDiscardBtn = () => {
    // dispatch(setDraftEmails(actionTypeRestDraftEmails));
    setSelectedFile([]);
    setSelectedOptionsBcc([]);
    setSelectedOptionsCc([]);
    setSelectedOptions([]);
    setSubject("");
    setBodyValue("");
    setToValue("");
    setCcValue("");
    setBccValue("");
    setShowCc(false);
    setShowBcc(false);
    setOpenDraftModal(true);
  };
  // region handle Emoji Click
  const quillRef = useRef(null);

  const handleEmojiClick = (emojiObject, event) => {
    quillRef?.current?.focus();
    if (emojiObject && emojiObject.emoji) {
      const editor = quillRef.current.getEditor();
      const range = editor.getSelection(); // Get the current cursor position
      const emoji = emojiObject.emoji;

      if (range) {
        editor.insertText(range.index, emoji); // Insert emoji at the cursor position
        editor.setSelection(range.index + emoji.length); // Move cursor after the emoji
      }
    } else {
      console.error("Emoji object or emoji property is undefined.");
    }
  };

  useEffect(() => {
    pickerRef?.current?.focus();
  }, [bodyValue]);
  useEffect(() => {
    if (emptyEmailInputs) {
      handleDiscardBtn();
      dispatch(setEmptyEmailInputs(false));
    }
  }, [emptyEmailInputs]);

  const EmailDetails = async (EmailId) => {
    try {
      const resp = await getEmailDetailList(EmailId);
      const result = resp?.data?.result;
      if (result && result?.length > 0) {
        setEmailDetailsData(resp?.data?.result);
        setBodyValue(resp?.data?.result[0]?.body);
        setSubject(resp?.data?.result[0]?.subject);
        setSelectedOptions(
          resp?.data?.result[0]?.recipients?.filter(
            (item) => item?.recipientType === "To"
          )
        );
        setSelectedOptionsBcc(
          resp?.data?.result[0]?.recipients?.filter(
            (item) => item?.recipientType === "BCC"
          )
        );
        setSelectedOptionsCc(
          resp?.data?.result[0]?.recipients?.filter(
            (item) => item?.recipientType === "CC"
          )
        );
      } else {
        console.warn("No email details found");
        setEmailDetailsData([]);
        setBodyValue("");
        setSubject("");
        setSelectedOptions([]);
        setSelectedOptionsBcc([]);
        setSelectedOptionsCc([]);
      }
    } catch (error) {
      console.error("EmailDetailsAPIError", error);
    }
  };

  useEffect(() => {
    if (pendingEmailsId) {
      EmailDetails(pendingEmailsId);
    }
  }, [pendingEmailsId]);

  // region return
  return (
    <ThemeProvider theme={theme}>
      <Grid container>
        <Grid
          item
          xs={12}
          md={12}
          sm={12}
          xl={12}
          lg={12}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography>{isDraftEmail ? "Draft Eamil" : "New Email"}</Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Typography variant="h5">Email Type:</Typography>

            <RadioGroup
              row
              value={emailType}
              onChange={handleEmailTypeChange}
              sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
            >
              <FormControlLabel
                value="Internal"
                control={
                  <Radio
                    color="success"
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 15,
                      },
                    }}
                  />
                }
                label={<Typography variant="subtitle2">Internal</Typography>}
              />
              {singleUserdata[0]?.isExternalEmailAllowed === true && (
                <FormControlLabel
                  value="External"
                  control={
                    <Radio
                      color="success"
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 15,
                        },
                      }}
                    />
                  }
                  label={<Typography variant="subtitle2">External</Typography>}
                />
              )}
            </RadioGroup>
          </Box>
        </Grid>
        {/* Main Email Section */}
        <Grid item xs={12} md={12} sm={12} xl={12} lg={12}>
          <Box
            sx={{
              border: "1px solid lightgray",
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
              mb: 1,
            }}
          >
            <CustomToolbar />
            {/* TO sender bar */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                // gap: 1,
              }}
            >
              {/* To field */}

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  //alignItems: "center",
                  flex: 1,
                  borderBottom: "1px solid lightgray",
                  p: 1,
                }}
              >
                <Box display={"flex"} gap={"1rem"}>
                  <Typography
                    sx={{
                      fontSize: "11px",
                      fontWeight: "bold",
                      border: "1px solid lightgray",
                      borderRadius: 1,
                      p: "5px 18px 0px 18px",
                      height: "28px",
                    }}
                  >
                    To
                  </Typography>
                  {emailType === "External" ? (
                    <TextField
                      size="small"
                      type="email"
                      value={toValue}
                      onChange={(e) => setToValue(e.target.value)}
                      sx={{
                        width: "30vw",

                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none", // Removes the border
                          },
                          "&:hover fieldset": {
                            border: "none", // Ensures no border on hover
                          },
                          "&.Mui-focused fieldset": {
                            border: "none", // No border when focused
                          },
                        },
                        ml: "-15px",
                        mt: "-7px",
                      }}
                    />
                  ) : (
                    <Autocomplete
                      multiple
                      disableClearable
                      size="small"
                      open={open}
                      onInputChange={handleInputChange}
                      onChange={handleChange}
                      options={userdata}
                      getOptionLabel={(option) =>
                        `${option?.firstName} ${option?.lastName} (${
                          option.email.length > 10
                            ? option.email.slice(0, 10)
                            : option?.email
                        })`
                      }
                      value={selectedOptions}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Tooltip
                            arrow
                            title={
                              <span style={{ fontSize: "0.75rem" }}>
                                {option?.email}
                              </span>
                            }
                            key={option?.email}
                          >
                            <Chip
                              label={`${option?.firstName} ${option?.lastName}`}
                              {...getTagProps({ index })}
                              key={index}
                              sx={{
                                height: 24,
                                fontSize: 12,
                              }}
                            />
                          </Tooltip>
                        ))
                      }
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Avatar
                            src={`${s?.baseUrl}/${option?.profilePicPath}`}
                            alt={`${option?.firstName} ${option?.lastName}`}
                            sx={{
                              marginRight: 2,
                              width: "30px",
                              height: "30px",
                            }}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                          >
                            <span
                              style={{ fontSize: "10px", fontWeight: "bold" }}
                            >
                              {option?.firstName} {option?.lastName}
                            </span>
                            <span style={{ fontSize: "10px", color: "#888" }}>
                              {option?.email}
                            </span>
                          </div>
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: null,
                            sx: {
                              minWidth: 190,
                              height: "auto",
                              //minHeight: 25,
                              paddingTop: "2px",
                              paddingBottom: "2px",
                              display: "flex",
                              flexWrap: "wrap",
                              alignItems: "center",
                              "& .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                            },
                          }}
                          sx={{
                            "& .MuiInputLabel-root": {
                              fontSize: 12,
                            },
                            "& .MuiAutocomplete-tag": {
                              fontSize: 12,
                            },
                          }}
                        />
                      )}
                      openOnFocus={false}
                      sx={{
                        "& .MuiAutocomplete-option": {
                          fontSize: 12, // Reduce option font size
                        },
                        marginLeft: "-20px",
                        marginTop: "-8px",
                        flexWrap: "wrap",
                      }}
                    />
                  )}

                  {/* autocomplete */}
                </Box>
                <Box sx={{ display: "flex", gap: 1 }}>
                  {/* Toggle the visibility of Cc and Bcc fields on click */}
                  <Typography
                    sx={{
                      cursor: "pointer",
                      color: showCc ? "#085F99" : "inherit",
                    }}
                    onClick={() => setShowCc(!showCc)}
                  >
                    Cc
                  </Typography>
                  <Typography
                    sx={{
                      cursor: "pointer",
                      color: showBcc ? "#085F99" : "inherit",
                    }}
                    onClick={() => setShowBcc(!showBcc)}
                  >
                    Bcc
                  </Typography>
                </Box>
              </Box>

              {/* Conditionally render Cc field when "showCc" is true */}
              {showCc && (
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      // justifyContent: "space-between",
                      gap: 1,
                      p: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "11px",
                        fontWeight: "bold",
                        border: "1px solid lightgray",
                        borderRadius: 1,
                        p: "5px 18px 0px 18px",
                        height: "28px",
                      }}
                    >
                      Cc
                    </Typography>
                    {emailType === "External" ? (
                      <TextField
                        size="small"
                        type="email"
                        value={ccValue}
                        onChange={(e) => setCcValue(e.target.value)}
                        sx={{
                          width: "30vw",

                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none", // Removes the border
                            },
                            "&:hover fieldset": {
                              border: "none", // Ensures no border on hover
                            },
                            "&.Mui-focused fieldset": {
                              border: "none", // No border when focused
                            },
                          },
                          ml: "-15px",
                          mt: "-7px",
                        }}
                      />
                    ) : (
                      <Autocomplete
                        multiple
                        disableClearable
                        size="small"
                        open={openCc}
                        onInputChange={handleInputChangeCc}
                        onChange={handleChangeCc}
                        options={userdata}
                        getOptionLabel={(option) =>
                          `${option?.firstName} ${option?.lastName} (${
                            option.email.length > 10
                              ? option.email.slice(0, 10)
                              : option?.email
                          })`
                        }
                        value={selectedOptionsCc}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Tooltip
                              arrow
                              title={
                                <span style={{ fontSize: "0.75rem" }}>
                                  {option.email}
                                </span>
                              }
                              key={option.email}
                            >
                              <Chip
                                label={`${option?.firstName} ${option?.lastName}`}
                                {...getTagProps({ index })}
                                key={index}
                                sx={{
                                  height: 24,
                                  fontSize: 12,
                                }}
                              />
                            </Tooltip>
                          ))
                        }
                        renderOption={(props, option) => (
                          <li {...props}>
                            <Avatar
                              src={`${s?.baseUrl}/${option?.profilePicPath}`}
                              alt={`${option?.firstName} ${option?.lastName}`}
                              sx={{
                                marginRight: 2,
                                width: "30px",
                                height: "30px",
                              }}
                            />
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                              }}
                            >
                              <span
                                style={{ fontSize: "10px", fontWeight: "bold" }}
                              >
                                {option?.firstName} {option?.lastName}
                              </span>
                              <span style={{ fontSize: "10px", color: "#888" }}>
                                {option?.email}
                              </span>
                            </div>
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: null,
                              sx: {
                                minWidth: 190,
                                height: "auto",
                                // minHeight: 25,
                                paddingTop: "2px",
                                paddingBottom: "2px",
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "center",
                                "& .MuiOutlinedInput-notchedOutline": {
                                  border: "none",
                                },
                              },
                            }}
                            sx={{
                              "& .MuiInputLabel-root": {
                                fontSize: 12,
                              },
                              "& .MuiAutocomplete-tag": {
                                fontSize: 12,
                              },
                            }}
                          />
                        )}
                        openOnFocus={false}
                        sx={{
                          "& .MuiAutocomplete-option": {
                            fontSize: 12, // Reduce option font size
                          },
                          marginLeft: "-11px",
                          marginTop: "-8px",
                          flexWrap: "wrap",
                        }}
                      />
                    )}
                  </Box>
                  <Divider />
                </Box>
              )}

              {/* Conditionally render Bcc field when "showBcc" is true */}
              {showBcc && (
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      // justifyContent: "space-between",
                      gap: 1,
                      p: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "11px",
                        fontWeight: "bold",
                        border: "1px solid lightgray",
                        borderRadius: 1,
                        p: "5px 16px 0px 15px",
                        height: "28px",
                      }}
                    >
                      Bcc
                    </Typography>
                    {emailType === "External" ? (
                      <TextField
                        size="small"
                        type="email"
                        value={bccValue}
                        onChange={(e) => setBccValue(e.target.value)}
                        sx={{
                          width: "30vw",

                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none", // Removes the border
                            },
                            "&:hover fieldset": {
                              border: "none", // Ensures no border on hover
                            },
                            "&.Mui-focused fieldset": {
                              border: "none", // No border when focused
                            },
                          },
                          ml: "-15px",
                          mt: "-7px",
                        }}
                      />
                    ) : (
                      <Autocomplete
                        multiple
                        disableClearable
                        size="small"
                        open={openBcc}
                        onInputChange={handleInputChangeBcc}
                        onChange={handleChangeBcc}
                        options={userdata}
                        getOptionLabel={(option) =>
                          `${option?.firstName} ${option?.lastName} (${
                            option.email.length > 10
                              ? option.email.slice(0, 10)
                              : option?.email
                          })`
                        }
                        value={selectedOptionsBcc}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Tooltip
                              arrow
                              title={
                                <span style={{ fontSize: "0.75rem" }}>
                                  {option.email}
                                </span>
                              }
                              key={option.email}
                            >
                              <Chip
                                label={`${option?.firstName} ${option?.lastName}`}
                                {...getTagProps({ index })}
                                key={index}
                                sx={{
                                  height: 24,
                                  fontSize: 12,
                                }}
                              />
                            </Tooltip>
                          ))
                        }
                        renderOption={(props, option) => (
                          <li {...props}>
                            <Avatar
                              src={`${s?.baseUrl}/${option?.profilePicPath}`}
                              alt={`${option?.firstName} ${option?.lastName}`}
                              sx={{
                                marginRight: 2,
                                width: "30px",
                                height: "30px",
                              }}
                            />
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                              }}
                            >
                              <span
                                style={{ fontSize: "10px", fontWeight: "bold" }}
                              >
                                {option?.firstName} {option?.lastName}
                              </span>
                              <span style={{ fontSize: "10px", color: "#888" }}>
                                {option?.email}
                              </span>
                            </div>
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: null,
                              sx: {
                                minWidth: 190,
                                height: "auto",
                                //minHeight: 25,
                                paddingTop: "2px",
                                paddingBottom: "2px",
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "center",
                                "& .MuiOutlinedInput-notchedOutline": {
                                  border: "none",
                                },
                              },
                            }}
                            sx={{
                              "& .MuiInputLabel-root": {
                                fontSize: 12,
                              },
                              "& .MuiAutocomplete-tag": {
                                fontSize: 12,
                              },
                            }}
                          />
                        )}
                        openOnFocus={false}
                        sx={{
                          "& .MuiAutocomplete-option": {
                            fontSize: 12, // Reduce option font size
                          },
                          marginLeft: "-12px",
                          marginTop: "-8px",
                          flexWrap: "wrap",
                        }}
                      />
                    )}
                  </Box>
                  <Divider />
                </Box>
              )}
            </Box>
            {/* Email Subject */}
            <Box sx={{ p: 1, pl: 3 }}>
              <Input
                value={subject}
                onChange={(e) => {
                  if (e.target.value.length <= 100) {
                    setSubject(e.target.value);
                  }
                }}
                size="small"
                fullWidth
                placeholder="Add a Subject"
                disableUnderline
                sx={{ fontSize: "13px" }}
              />
            </Box>
          </Box>
          <Box sx={{ border: "1px solid #00000026" }}>
            <ReactQuill
              ref={quillRef}
              theme="snow"
              value={bodyValue}
              onChange={(value) => setBodyValue(value)}
              modules={modules}
              placeholder="Compose your email..."
              className="quill-editor"
            />
          </Box>
          {/* show Attachment */}
          {selectedFile?.length > 0 && (
            <Box
              sx={{
                border: "1px solid lightgray",
                mt: 1,
                p: 1,
                marginTop: "-1px",
              }}
            >
              <Typography fontSize={"14px"} fontWeight={"bold"}>
                {selectedFile?.length} Attachments
              </Typography>
              <Box
                display={"flex"}
                gap={"10px"}
                flexWrap={"wrap"}
                sx={{
                  overflowX: "hidden",
                  overflowY: "auto",
                  maxHeight: "115px",
                  width: "100%",
                }}
              >
                {selectedFile?.map((item, key) => {
                  const isPdf = item?.name?.toLowerCase().endsWith(".pdf");
                  const isExcel =
                    item?.name?.toLowerCase().endsWith(".xlsx") ||
                    item?.name?.toLowerCase().endsWith(".xls");
                  return (
                    <Box
                      key={key}
                      display={"flex"}
                      justifyContent={"space-between"}
                      border={"1px solid #00000026"}
                      p={"5px"}
                      width={"12rem"}
                      // alignItems={"center"}
                      borderRadius={"5px"}
                    >
                      <Box display={"flex"} gap={"5px"} alignItems={"center"}>
                        {(isPdf || isExcel) && (
                          <Box
                            sx={{ width: "25px", height: "25px" }}
                            component={"img"}
                            src={isPdf ? pdf : isExcel ? excel : null} // Show pdf or excel based on file type
                          />
                        )}
                        <Tooltip
                          title={item?.name}
                          placement="top"
                          arrow
                          componentsProps={{
                            tooltip: {
                              sx: {
                                fontSize: "12px", // Set font size for Tooltip
                              },
                            },
                          }}
                        >
                          <Typography fontSize={"12px"} fontWeight={"500px"}>
                            {item?.name?.length > 15
                              ? item?.name?.slice(0, 15) + "..."
                              : item?.name}
                          </Typography>
                        </Tooltip>
                      </Box>
                      <IconButton
                        onClick={(event) => {
                          setAnchorEl(event.currentTarget);
                          setDeleteFile(key);
                          setDownloadFile(key);
                        }}
                        sx={{ width: "18px", height: "18px" }}
                      >
                        <MoreHorizIcon />
                      </IconButton>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          )}

          {/* bottom buttons */}
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={"1rem"}
            sx={{
              border: "1px solid lightgray",
              mt: 1,
              p: 1,
              bgcolor: "#e9e9e9",
              marginTop: "-1px",
              borderRadius: "0 0 10px 10px",
            }}
          >
            {/* sentButton */}
            <Button
              onClick={() => {
                if (
                  selectedOptions?.length > 0 ||
                  selectedOptionsCc?.length > 0 ||
                  selectedOptionsBcc?.length > 0 ||
                  toValue?.length > 0 ||
                  ccValue?.length > 0 ||
                  bccValue?.length > 0
                ) {
                  sentEmails({
                    ParentEmailId: 1,
                    SenderId: userId,
                    Subject: subject,
                    Body: bodyValue,
                    File: selectedFile,
                    // internal
                    EmailType: emailType,
                    RecipentData: selectedOptions,
                    RecipentDataCc: selectedOptionsCc,
                    RecipentDataBcc: selectedOptionsBcc,
                    // External
                    BccValue: bccValue,
                    CcValue: ccValue,
                    ToValue: toValue,
                    CreatedBy: parseInt(userId)
                  });
                }
                newEmailNotification("Real Time Email Notification");
              }}
              variant="contained"
              sx={{ textTransform: "capitalize", width: "80px" }}
            >
              send
            </Button>
            <Button
              onClick={() => {
                dispatch(setOpenDraftModal(true));
              }}
              variant="contained"
              sx={{
                width: "80px",
                bgcolor: "white",
                color: "gray",
                border: "1px solid rgb(116, 116, 116)",
                "&:hover": { bgcolor: "white", color: "gray" },
                textTransform: "capitalize",
              }}
            >
              discard
            </Button>

            <Box>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
                multiple
              />
              <IconButton onClick={handleButtonClick} sx={{ padding: "0px" }}>
                <AttachFileIcon sx={{ fontSize: "20px" }} />
              </IconButton>
            </Box>

            <Box
              ref={pickerRef}
              sx={{ position: "relative", display: "inline-block" }}
            >
              {/* Single Emoji Button */}
              <Typography
                onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                sx={{
                  fontSize: "20px",
                  cursor: "pointer",
                  padding: "0px",
                  marginTop: "5px",
                }}
              >
                🙂
              </Typography>

              {/* Emoji Picker */}
              {showEmojiPicker && (
                <Box sx={{ position: "fixed", top: "40px", zIndex: 10 }}>
                  <EmojiPicker onEmojiClick={handleEmojiClick} />
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(false);
            selectedFile.map((item, index) => {
              if (index === downloadFile) {
                handleDownload(item);
              }
            });
          }}
          sx={{ height: "30px" }}
        >
          Save As
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            setAnchorEl(false);
            setSelectedFile((previous) =>
              previous.filter((_, i) => i !== deleteFile)
            );
            // setSelectedFile(
            //   selectedFile?.filter((item, key) => key !== deleteFile)
            // );
          }}
          sx={{ color: "red", height: "30px" }}
        >
          Delete
        </MenuItem>
      </Menu>
    </ThemeProvider>
  );
}

export default NewEmail;
const theme = createTheme({
  typography: {
    fontFamily: "Poppins, sans-serif",
    fontSize: "10px",
  },
});
// region custom toolbar
const CustomToolbar = () => (
  <div id="toolbar">
    {/* Font Style */}
    <select className="ql-font" defaultValue="" title="Font">
      <option value="sans-serif">Sans Serif</option>
      <option value="serif">Serif</option>
      <option value="monospace">Monospace</option>
    </select>

    {/* Font Size */}
    <select className="ql-size" defaultValue="" title="Font Size">
      <option value="small">Small</option>
      <option value="">Normal</option>
      <option value="large">Large</option>
      <option value="huge">Huge</option>
    </select>

    {/* Bold, Italic, Underline, Strikethrough */}
    <button className="ql-bold" title="Bold"></button>
    <button className="ql-italic" title="Italic"></button>
    <button className="ql-underline" title="Underline"></button>
    <button className="ql-strike" title="Strikethrough"></button>

    {/* Color */}
    <select className="ql-color" title="Text Color"></select>
    <select className="ql-background" title="Background Color"></select>

    {/* Subscript / Superscript */}
    <button className="ql-script" value="sub" title="Subscript"></button>
    <button className="ql-script" value="super" title="Superscript"></button>

    {/* Headers */}
    <select className="ql-header" defaultValue="" title="Header">
      <option value="1">Heading 1</option>
      <option value="2">Heading 2</option>
      <option value="" defaultValue>
        Normal
      </option>
    </select>

    {/* List */}
    <button className="ql-list" value="ordered" title="Ordered List"></button>
    <button className="ql-list" value="bullet" title="Bullet List"></button>

    {/* Indent */}
    <button className="ql-indent" value="-1" title="Decrease Indent"></button>
    <button className="ql-indent" value="+1" title="Increase Indent"></button>

    {/* Align */}
    <select
      className="ql-align"
      defaultValue=""
      title="Text Alignment"
    ></select>

    {/* Links, Images, Video */}
    <button className="ql-link" title="Insert Link"></button>
    {/* <button className="ql-image" title="Insert Image"></button>
    <button className="ql-video" title="Insert Video"></button> */}

    {/* Clean */}
    <button className="ql-clean" title="Remove Formatting"></button>
  </div>
);

const modules = {
  toolbar: {
    container: "#toolbar",
  },
};
