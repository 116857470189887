import { createContext, useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { refresh_Token } from "../api/apiManager";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const refreshToken = localStorage.getItem("refresh-token");
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("access_token") || ""
  );

  const navigate = useNavigate();
  const location = useLocation();
  const timeoutId = useRef(null);

  const logout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh-token");
    sessionStorage.removeItem("hasReloaded");
    setAccessToken("");
    // Check if the current path is not the login page before showing the alert
    if (location.pathname !== "/") {
      alert("You are inactive for the last 30 minutes. Session expired.");
    }
    // Navigate to login page
    navigate("/");
  };

  const resetTimer = () => {
    if (timeoutId.current) clearTimeout(timeoutId.current);
    // Set timeout for session expiration
    timeoutId.current = setTimeout(() => {
      // logout();
    }, 30 * 60 * 1000);
  };

  useEffect(() => {
    // Set up event listeners for user activity
    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keydown", resetTimer);
    window.addEventListener("click", resetTimer);
    resetTimer(); // Initial reset on mount

    return () => {
      // Clean up on component unmount
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keydown", resetTimer);
      window.removeEventListener("click", resetTimer);
      if (timeoutId.current) clearTimeout(timeoutId.current);
    };
  }, [location.pathname]); // Add dependency to track path changes

  const getRefreshToken = async (refreshToken) => {
    try {
      const response = await refresh_Token(refreshToken);
      if (response?.data?.status === 200) {
        const newToken = response?.data?.result?.token;
        localStorage.setItem("access_token", newToken);
        setAccessToken(newToken);
      }
    } catch (error) {
      console.warn(error);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      getRefreshToken(refreshToken);
    }, 2 * 60 * 60 * 1000); //this function run after-2hours automatically everytime
    return () => clearInterval(interval);
  }, []);

  return (
    <AuthContext.Provider value={{ accessToken, resetTimer }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
