import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
} from "react";
import { getTwilioUserToken, getVideoCallToken } from "../api/apiManager";
import { Device } from "twilio-client";
import {
  setActiveConnection,
  setCallAccepted,
  setCaller,
  setCallStatus,
  setClearIncomingCall,
  setDevice,
  setIncomingCall,
  setAudioCallStart,
  setShowChatSectionDuringCall,
  setCallRunningInBG,
  resetCallDurationCounter,
  setCallerName,
  setContactImage,
  setCallDuration,
  setIsReceiveMuted,
  setIsReceiveRecording,
  setCallStartTime,
  setIsSendingRoomData,
} from "../store/slice/CallSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  SendCallAccept,
  startGroupCall,
} from "../signalRService/signalRService";
import { useNavigate } from "react-router-dom";
import { BgCallPopupContext } from "./BgCallPopupContext";
import dayjs from "dayjs";
import { connect, createLocalAudioTrack } from "twilio-video";

const TwilioContext = createContext();

export const TwilioProvider = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user_id = localStorage.getItem("user_id");
  const [groupRoom, setGroupRoom] = React.useState(null);
  const [participants, setParticipants] = React.useState([]);
  const [mainParticipant, setMainParticipant] = React.useState(null);
  const { setIsCallActive } = BgCallPopupContext();
  const { device, callStatus } = useSelector((state) => state.call);
  const [noDeviceModal, setNoDeviceModal] = React.useState({
    isOpen: false,
    title: "",
    message: "",
    buttonText: "",
  });
  // console.log("currentPCTimeWithAPIFORMAT", currentPCTimeWithAPIFORMAT);

  const openNoDeviceModal = ({ title, message, buttonText }) => {
    setNoDeviceModal({
      isOpen: true,
      title,
      message,
      buttonText,
    });
  };

  const closeNoDeviceModal = () => {
    setNoDeviceModal((prev) => ({ ...prev, isOpen: false }));
  };

  // Initialize the Twilio connection

  useEffect(() => {
    const fetchTwilioToken = async (user_id) => {
      try {
        const response = await getTwilioUserToken(user_id);
        const token = response.data.token;
        const newDevice = new Device(token, {
          codecPreferences: ["opus", "pcmu"],
          fakeLocalDTMF: true,
          enableRingingState: true,
          debug: true,
        });
        dispatch(setDevice(newDevice));
        // const handleReady = () => {
        //   console.log("Twilio Device Ready!");
        // };

        const handleError = (error) => {
          if (error.message.includes("Requested device not found")) {
            openNoDeviceModal({
              title: "Message Status",
              message: "Microphone not found, kindly connect your device.",
              buttonText: "Close",
            });
          } else {
            console.error(`Twilio.Device Error: ${error.message}`);
          }
        };

        const handleIncoming = (conn) => {
          const fromUserId = conn.parameters.From;
          dispatch(setIncomingCall(conn));
          dispatch(setCallStatus("Incoming call from"));
          dispatch(setCaller(fromUserId));

          conn.on("mute", (isRemoteMuted) => {
            dispatch(setCallStatus(isRemoteMuted ? "Muted" : "Connected"));
          });

          conn.on("accept", () => {
            SendCallAccept("Connected");
            dispatch(setCallAccepted(true));
            dispatch(setIncomingCall(null));
            dispatch(setActiveConnection(conn));
            setIsCallActive(true);
            dispatch(setAudioCallStart(true));
            navigate("/chats");
          });
        };

        const handleDisconnect = (con) => {
          if (con) {
            dispatch(setCallStatus("Call Ended"));
            dispatch(setActiveConnection(null));
            dispatch(setIncomingCall(null));
            dispatch(setCallAccepted(false));
            dispatch(setClearIncomingCall(true));
            setIsCallActive(false);
            dispatch(setAudioCallStart(false));
            dispatch(setCallRunningInBG(false));
            dispatch(setShowChatSectionDuringCall(false));
            dispatch(resetCallDurationCounter());
            dispatch(setIsReceiveMuted([]));
            dispatch(setIsReceiveRecording([]));
            dispatch(setCallDuration(0));
            navigate("/chats");
          }
        };
        // newDevice.on("ready", handleReady);
        newDevice.on("error", handleError);
        newDevice.on("incoming", handleIncoming);
        newDevice.on("disconnect", handleDisconnect);
      } catch (error) {
        console.error("Error fetching token!");
      }
    };

    if (user_id) {
      fetchTwilioToken(user_id);
    }
  }, [user_id]);

  const handleDial = async (
    to,
    callerName,
    direction,
    contactName,
    contactImage,
    chat_Id,
    isUserOnline,
    userID
  ) => {
    debugger;
    dispatch(setCallerName(contactName));
    dispatch(setContactImage(contactImage));
    if (chat_Id !== null && !isUserOnline) {
      openNoDeviceModal({
        title: "User Status",
        message: `${contactName} is offline. Please try again later.`,
        buttonText: "Dismiss",
      });
      return;
    }
    //  await InProgressCallAPI(to);
    dispatch(setAudioCallStart(true));
    if (device) {
      const params = {
        To: to,
        Direction: direction,
        CallerName: callerName,
        From: userID,
      };
      const connection = device.connect(params);
      if (connection) {
        dispatch(setCallStatus("Calling..."));
        dispatch(setActiveConnection(connection));
        setIsCallActive(true);
        connection.on("ringing", () => {
          dispatch(setCallStatus("Ringing..."));
        });

        connection.on("disconnect", () => {
          dispatch(setCallStatus("Call Ended"));
          dispatch(setActiveConnection(null));
          dispatch(setIncomingCall(null));
          dispatch(setCallAccepted(false));
          dispatch(setClearIncomingCall(true));
          dispatch(setAudioCallStart(false));
          dispatch(setShowChatSectionDuringCall(false));
          dispatch(setCallRunningInBG(false));
          dispatch(setIsReceiveMuted([]));
          dispatch(setIsReceiveRecording([]));
          dispatch(setCallDuration(0));
          navigate("/chats");
        });
        connection.on("error", (error) => {
          if (error.message.includes("Requested device not found")) {
            openNoDeviceModal({
              title: "Message Status",
              message: "Microphone not found, kindly connect your device.",
              buttonText: "Close",
            });
          } else {
            console.error(`Twilio.Device Error: ${error.message}`);
          }
        });
      }
    }
  };
  useEffect(() => {
    dispatch(setCallStartTime(new Date()));
    let timer;
    if (callStatus === "Connected") {
      timer = setInterval(() => {
        dispatch(setCallDuration(1));
      }, 1000);
    }
    return () => {
      clearInterval(timer);
    };
  }, [callStatus]);

  // For Group Calling Function is here
  const handleTrackSubscribed = (track) => {
    if (track.kind === "audio") {
      const audioElement = track.attach();
      document.body.appendChild(audioElement);
    }
  };

  const handleParticipantConnected = (participant) => {
    setParticipants((prevParticipants) => {
      if (!prevParticipants.some((p) => p.identity == participant.identity)) {
        participant.tracks.forEach((publication) => {
          if (publication.isSubscribed) {
            handleTrackSubscribed(publication.track);
          }
        });
        participant.on("trackSubscribed", handleTrackSubscribed);
        return [...prevParticipants, participant];
      }
      return prevParticipants;
    });
  };

  const handleParticipantDisconnected = useCallback(() => {
    if (groupRoom) {
      // Stop and unpublish local tracks
      groupRoom.localParticipant.tracks.forEach((publication) => {
        const track = publication.track;
        if (track) {
          track.stop();
          groupRoom.localParticipant.unpublishTrack(track);
        }
      });

      // Detach and clean up remote tracks
      participants.forEach((participant) => {
        participant.tracks.forEach((publication) => {
          if (publication.track) {
            publication.track.detach().forEach((element) => element.remove());
          }
        });
      });

      // Disconnect the groupRoom and reset state
      groupRoom.disconnect();
      setParticipants([]);
      setGroupRoom(null);
      setMainParticipant(null);
      dispatch(setIsSendingRoomData([]));
    } else {
      dispatch(setIsSendingRoomData([]));
      // Detach and clean up remote tracks
      participants.forEach((participant) => {
        participant.tracks.forEach((publication) => {
          if (publication.track) {
            publication.track.detach().forEach((element) => element.remove());
          }
        });
      });

      // Optionally, reset state or perform other actions here if needed
      setParticipants([]);
      setGroupRoom(null);
      setMainParticipant(null);
    }
  }, [groupRoom]);

  const handleJoinAsMainParticipant = async (user_id, getGroupData) => {
    try {
      // let GroupCallId = getGroupData?.map((i) => i?.userId);
      const response = await getVideoCallToken(user_id);
      const token = response.data.token;

      const room = await connect(token, {
        name: "AudioGroupRoom",
        audio: true,
        video: false,
      });

      setGroupRoom(room);
      setMainParticipant(user_id);
      if (room) {
        // dispatch(setAudioCallStart(true));
        // Handle already connected participants
        room.participants.forEach(handleParticipantConnected);

        // Listen for participants joining or leaving
        room.on("participantConnected", handleParticipantConnected);
        room.on("participantDisconnected", handleParticipantDisconnected);

        startGroupCall(
          "Single",
          "AudioGroupRoom",
          user_id,
          // ["50048", ""]
          getGroupData?.map((i) => i?.userId.toString())
        );
        // Clean up on unmount
        return () => {
          room.disconnect();
          setGroupRoom(null);
          setParticipants([]);
          setMainParticipant(null);
        };
      }
    } catch (error) {
      console.error("Error connecting to the room:", error);
    }
  };

  useEffect(() => {
    if (mainParticipant) {
      createLocalAudioTrack().then((track) => {
        groupRoom.localParticipant.publishTrack(track);
      });
    }
  }, [mainParticipant, groupRoom]);

  // Listen for participants disconnecting
  useEffect(() => {
    if (groupRoom) {
      const handleParticipantDisconnectedWrapper = (participant) => {
        handleParticipantDisconnected(participant);
      };

      const handleDisconnectedWrapper = () => {
        console.log("Room disconnected");
        setGroupRoom(null);
        setParticipants([]);
        setMainParticipant(null);
        dispatch(setIsSendingRoomData([]));
      };

      // Attach event listeners
      groupRoom.on(
        "participantDisconnected",
        handleParticipantDisconnectedWrapper
      );
      groupRoom.on("disconnected", handleDisconnectedWrapper);

      // Cleanup event listeners on component unmount
      return () => {
        groupRoom.off(
          "participantDisconnected",
          handleParticipantDisconnectedWrapper
        );
        groupRoom.off("disconnected", handleDisconnectedWrapper);
      };
    }
  }, [groupRoom, mainParticipant]);

  return (
    <TwilioContext.Provider
      value={{
        // fetchTwilioToken,
        handleDial,
        noDeviceModal,
        closeNoDeviceModal,
        openNoDeviceModal,
        handleJoinAsMainParticipant,
        handleParticipantDisconnected,
        mainParticipant,
        participants,
        groupRoom,
      }}
    >
      {children}
    </TwilioContext.Provider>
  );
};

export const useTwilio = () => useContext(TwilioContext);
