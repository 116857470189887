import { configureStore } from "@reduxjs/toolkit";
import chatReducer from "../store/slice/ChatSlice";
import notificationReducer from "../store/slice/NotificationSlice";
import callReducer from "../store/slice/CallSlice";
import emailSlice from "../store/slice/EmailSlice";

export const Store = configureStore({
  reducer: {
    chat: chatReducer,
    notification: notificationReducer,
    call: callReducer,
    email: emailSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export default Store;
