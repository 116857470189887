import React, { useState } from "react";
import { Box, Paper, Typography, Button, Alert, Snackbar } from "@mui/material";
import loginImage from "../../../../assets/img/max.png";
import favicon from "../../../../assets/img/x.png";
import "./login.css";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import MailIcon from "@mui/icons-material/Email";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Lock from "@mui/icons-material/Lock"; // Import lock icon
import { Link, useLocation } from "react-router-dom"; // For navigation
import AccountCircle from "@mui/icons-material/AccountCircle";
import { verifyOtp } from "../../../services/api/apiManager";
import { useNavigate } from "react-router-dom";

const Submit = () => {
  const [verifyPassOtp, setVerifyPassOtp] = useState(null);

  const [openSnackbar, setOpenSnackbar] = useState({
    openSnack: false,
    snackMessage: "",
  });
  const navigate = useNavigate();
  const receiveEmail = useLocation();
  // Api VerifyOtp
  const verifySendOtp = async (email, otp) => {
    try {
      const response = await verifyOtp(email, otp);
      if (
        response?.status === 200 &&
        response?.data?.message === "OTP Matched"
      ) {
        setVerifyPassOtp("");
        setOpenSnackbar({
          openSnack: true,
          snackMessage: "success",
        });
      } else {
        setOpenSnackbar({
          openSnack: true,
          snackMessage: "error",
        });
        setVerifyPassOtp("");
      }
    } catch (err) {
      console.warn(err);
    } finally {
      setTimeout(() => {
        navigate("/reset", { state: { email: receiveEmail?.state?.email } });
      }, 1000);
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100vh", // Full height of the viewport
        backgroundImage: `url(${loginImage})`,
        backgroundSize: "cover", // Cover the entire container
        backgroundPosition: "center", // Center the image
        backgroundRepeat: "no-repeat", // Prevent repeating the image
      }}
    >
      <Paper
        elevation={3}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          padding: "31px",
          backgroundColor: "#ffffff", // Optional: semi-transparent background
          borderRadius: "10px",
          textAlign: "center",
          width: {
            xl: "25%",
            lg: "25%",
            md: "40%",
            sm: "75%",
            xs: "75%",
          },
        }}
      >
        <Box pt={2.5} pb={2.5}>
          <Typography variant="h4" component="h1">
            <img
              src={favicon}
              alt="Description of the image"
              // className="favicon"
            />
          </Typography>
          <Typography
            variant="h5"
            component="h1"
            style={{ fontWeight: "600", fontSize: "18px" }}
          >
            <span style={{ color: "#2f2f2f" }}>Verify Code</span>
          </Typography>
          <Typography
            variant="h6"
            component="h1"
            style={{ fontSize: "17px" }}
            pt={0.5}
          >
            <span style={{ color: "#90919b", fontSize: "14px" }}>
              Enter code send to you email s***2@gmail.com
            </span>
          </Typography>
          <Box pt={5}>
            <ThemeProvider theme={theme}>
              <TextField
                value={verifyPassOtp}
                onChange={(e) => setVerifyPassOtp(e.target.value)}
                sx={{
                  alignItems: "center",
                }}
                // label="Your Label"
                variant="standard"
                fullWidth
              />
            </ThemeProvider>
          </Box>

          <Box pt={5}>
            <Button
              disabled={verifyPassOtp === null || verifyPassOtp === ""}
              variant="contained"
              className="btn"
              onClick={(e) => {
                verifySendOtp(receiveEmail?.state?.email, verifyPassOtp);
              }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Paper>
      <Snackbar
        open={openSnackbar?.openSnack}
        autoHideDuration={2000}
        onClose={() => setOpenSnackbar({ openSnack: false, snackMessage: "" })}
        anchorOrigin={{ vertical: "center", horizontal: "center" }}
      >
        <Alert
          onClose={() =>
            setOpenSnackbar({ openSnack: false, snackMessage: "" })
          }
          severity={
            openSnackbar?.snackMessage === "success" ? "success" : "error"
          }
          variant="filled"
          sx={{
            width: "100%",
            height: "2.5rem",
            fontSize: "0.9rem",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          action={
            <>
              <span
                onClick={() =>
                  setOpenSnackbar({ openSnack: false, snackMessage: "" })
                }
                style={{
                  cursor: "pointer",
                  m: "auto 0",
                  fontSize: "13px",
                  color: "rgb(231, 231, 231)",
                }}
              >
                Close
              </span>
            </>
          }
        >
          {openSnackbar.snackMessage === "success"
            ? "Otp matched"
            : "Invalid Otp, Please Try again"}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Submit;
const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          margin: "0 auto",
          "& .MuiInput-underline:before": {
            borderBottomColor: "#6f45a4 !important", // Inactive underline color
          },
          "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
            borderBottomColor: "#6f45a4 !important", // Hover underline color
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "#6f45a4 !important", // Focused underline color
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: "12px",
          color: "#90919b",
          width: "85%",
          "&:hover:not(.Mui-disabled):before": {
            borderBottomColor: "#6f45a4",
          },
        },
      },
    },
  },
});
