import {
  Box,
  MenuItem,
  Select,
  Typography,
  InputAdornment,
  TextField,
  IconButton,
  Stack,
  CircularProgress,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { Search } from "@mui/icons-material";
import React, { startTransition, useEffect, useState } from "react";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import Grid from "@mui/material/Grid";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import dayjs from "dayjs";
import {
  getFolders,
  updateEmail,
  getIndividualFoldersEmails,
  moveToFolder,
} from "../../../../services/api/apiManager";
import CreateNewFolder from "../emailSidebar/CreateNewFolder";
import {
  setDispatchFoldersId,
  setEmailId,
  setFoldersName,
} from "../../../../services/store/slice/ChatSlice";
import {
  setEmailsLength,
  setHandleSentEmail,
} from "../../../../services/store/slice/EmailSlice";
import { setRenderEmailList } from "../../../../services/store/slice/EmailSlice";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import EmailWindow from "../emailWindow/emailWindow";

const NewFolders = ({ folder }) => {
  const [apiEmailListData, setApiEmailListData] = useState([]);
  const [apiFolderData, setApiFolderData] = useState([]);

  const [moveFolderFilter, setMoveFolderFilter] = useState([]);
  const userId = localStorage.getItem("user_id");
  const [openModal, setOpenModal] = useState(false);
  const [folderId, setFolderId] = useState(null);
  const [selectedID, setSelectedID] = useState([]);
  const EmailId = useSelector((state) => state?.chat?.emailId);
  const [count, setCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [isRead, setIsRead] = useState();
  const [selectFilter, setSelectFilter] = useState(true);
  const [inboxMenu, setInboxMenu] = useState("");
  const [storeFolderId, setStoreFolderId] = useState("");
  const [itemPerPage, setItemPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState(
    localStorage.getItem("selectedFilter") || "AllMails"
  );
  const [selectedDate, setSelectedDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const actionType = useSelector((state) => state?.chat?.renderFolder);
  const renderEmailList = useSelector((state) => state?.email?.renderEmailList);

  const dispatchFolderId = useSelector(
    (state) => state?.chat?.dispatchFolderId
  );

  useEffect(() => {
    if (renderEmailList) {
      getUserEmailList(
        userId,
        apiPage?.inPageNumber,
        apiPage?.inRows,
        dispatchFolderId
      );
      dispatch(setRenderEmailList(false));
    }
  }, [renderEmailList]);

  useEffect(() => {
    dispatch(setDispatchFoldersId(localStorage.getItem("selectedId")));
  }, []);

  const [search, setSearch] = useState("");

  // getFolders
  async function getUserFolders(id) {
    try {
      const response = await getFolders(id);
      if (response?.status === 200) {
        setApiFolderData(response?.data?.result);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getUserFolders(userId);
  }, [actionType]);

  // region getEmailList
  async function getUserEmailList(id, page, row, FolderId) {
    try {
      setLoading(true);
      const response = await getIndividualFoldersEmails(
        id,
        page,
        row,
        FolderId
      );

      if (response?.data?.result?.length === 0) {
        dispatch(setEmailId(null));
      }
      dispatch(setEmailsLength(response?.data?.result?.length));
      if (response?.status === 200) {
        setApiEmailListData(response?.data?.result);
        setCount(response?.data?.count);
        // if (defaultEmailShow === true) {
        //   dispatch(setEmailId(filterTodayData[0]?.emailId));
        //   dispatch(setDefaultEmailShow(false));
        // }
        // handleChange();
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }
  useEffect(() => {
    getUserEmailList(
      userId,
      apiPage?.inPageNumber,
      apiPage?.inRows,
      dispatchFolderId
    );
    setSelectedFilter(localStorage.getItem("selectedFilter") || "AllMails");
  }, [dispatchFolderId]);

  // region ReadEmail unread
  async function ReadUserEmail(data) {
    try {
      const response = await updateEmail(data);
      if (response?.status === 200) {
        getUserEmailList(
          userId,
          apiPage?.inPageNumber,
          apiPage?.inRows,
          dispatchFolderId
        );
        dispatch(setRenderEmailList(true));
        setSelectedID([]);
      }
    } catch (error) {
      console.error(error);
      setSelectedID([]);
    }
  }

  // region Move Emails from custom folder and move to Inbox
  const moveFromCustomFolder = async () => {
    try {
      const delTrashResp = await moveToFolder({
        emailId: [selectedID],
        folderId: dispatchFolderId,
        userId: userId,
        deleted: true,
      });

      if (delTrashResp.status === 200) {
        enqueueSnackbar("Email Moved successfully", { variant: "success" });
        dispatch(setRenderEmailList(true));
        getUserEmailList(
          userId,
          apiPage?.inPageNumber,
          apiPage?.inRows,
          dispatchFolderId
        );
      } else {
        enqueueSnackbar("Please Select Email", { variant: "error" });
      }
    } catch (error) {
      console.error("DelTrashMailsError", error);
    }
  };

  // region deleteEmail
  async function deleteUserEmail(data) {
    try {
      const response = await updateEmail(data);
      if (response?.status === 200) {
        dispatch(setRenderEmailList(true));
        getUserEmailList(
          userId,
          apiPage?.inPageNumber,
          apiPage?.inRows,
          dispatchFolderId
        );
        if (data?.IsTrash === true && selectedID.length != 0) {
          enqueueSnackbar("Deleted Successfully", { variant: "success" });
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  //new menu
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const [hoveredView, setHoveredView] = useState(false);

  const handleRightClick = (event) => {
    event.preventDefault();
    const { clientX: mouseX, clientY: mouseY } = event;
    setMenuPosition({ x: mouseX, y: mouseY });
    setIsContextMenuOpen(true);
  };

  const handleCloseContextMenu = () => {
    setIsContextMenuOpen(false);
  };

  const handleViewMouseEnter = () => {
    setHoveredView(true);
  };

  const handleViewMouseLeave = () => {
    setHoveredView(false);
  };
  // menu

  // paginations
  const [apiPage, setApiPage] = useState({
    inPageNumber: 1,
    inRows: 100,
  });

  // functions

  const handleIconClick = () => {
    setOpen(true);
  };

  // Define filter logic in a function
  const getFilteredData = () => {
    const todayDate = dayjs().format("YYYY-MM-DD");
    const yesterdayDate = dayjs().subtract(1, "day").format("YYYY-MM-DD");
    const thisMonth = dayjs().format("YYYY-MM");
    const previousMonth = dayjs().subtract(1, "month").format("YYYY-MM");

    switch (selectedFilter) {
      case "All Mails":
        return apiEmailListData;
      case "Today":
        return apiEmailListData?.filter(
          (item) => dayjs(item.createdDate).format("YYYY-MM-DD") === todayDate
        );
      case "Yesterday":
        return apiEmailListData?.filter(
          (item) =>
            dayjs(item.createdDate).format("YYYY-MM-DD") === yesterdayDate
        );
      case "ThisMonth":
        return apiEmailListData?.filter(
          (item) => dayjs(item.createdDate).format("YYYY-MM") === thisMonth
        );
      case "PreviousMonth":
        return apiEmailListData?.filter(
          (item) => dayjs(item.createdDate).format("YYYY-MM") === previousMonth
        );
      case "HasFiles":
        return apiEmailListData?.filter((item) => item.attachments.length > 0);
      default:
        return apiEmailListData;
    }
  };

  // Update the selected filter on change
  const handleChange = (event) => {
    setCurrentPage(0);
    const selectedValue = event.target.value;
    setSelectedFilter(selectedValue); // Update selected filter state
    localStorage.setItem("selectedFilter", selectedValue);
    setSelectFilter(true); // keeps the favorite button logic active
  };
  const dateFilteredData = apiEmailListData?.filter((item) =>
    dayjs(item.createdDate).format("YYYY-MM-DD").startsWith(selectedDate)
  );

  let allDataToDisplay =
    selectFilter === true ? getFilteredData() : dateFilteredData;

  // region Update emails fav
  const FavEmails = async (data) => {
    try {
      const response = await updateEmail(data);
      if (response?.status === 200) {
        dispatch(setRenderEmailList(true));
        getUserEmailList(
          userId,
          apiPage?.inPageNumber,
          apiPage?.inRows,
          dispatchFolderId
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setInboxMenu(storeFolderId);
  }, [storeFolderId]);
  useEffect(() => {
    setStoreFolderId(dispatchFolderId);
  }, [dispatchFolderId]);

  // Define inboxMenuChange to update inboxMenu and handle navigation
  const inboxMenuChange = (event) => {
    const selectedValue = event.target.value;
    setInboxMenu(selectedValue);

    // Handle navigation based on selected value
    startTransition(() => {
      switch (selectedValue) {
        case "inbox":
          navigate("/emails/inbox");
          break;
        case "sent":
          navigate("/emails/sent");
          break;
        case "draft":
          navigate("/emails/draft");
          break;
        case "favourite": // Updated value for Favourite to make it unique
          navigate("/emails/favourite");
          break;
        case "trash": // Updated value for Trash to make it unique
          navigate("/emails/trash");
          break;
        default:
          // Navigate to custom folder if selected
          const selectedFolder = apiFolderData.find(
            (item) => item.folderName === selectedValue
          );
          if (selectedFolder) {
            navigate(`/emails/folder/${selectedFolder.folderId}`, {
              state: {
                folderId: selectedFolder.folderId,
                folderName: selectedFolder.folderName,
              },
            });
            localStorage.setItem("selectedCategory", selectedFolder.folderName);
            localStorage.setItem("selectedId", selectedFolder.folderId);
            dispatch(setDispatchFoldersId(selectedFolder.folderId));
            dispatch(setFoldersName(selectedFolder.folderName));
          }
      }
    });
  };

  // region Pagination
  const startIndex = currentPage * itemPerPage;
  const endIndex = Math.min(startIndex + itemPerPage, count);
  const paginationData = allDataToDisplay?.slice(startIndex, endIndex);

  // Next page handler
  const handleNext = () => {
    if (endIndex < count) {
      setCurrentPage(currentPage + 1);
    }
    handlePaginationChange();
  };

  // Previous page handler
  const handlePrevious = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
    handlePaginationChange();
  };

  // region select pageSize menu

  const handlePagination = (event) => {
    setItemPerPage(event.target.value);
    setApiPage(() => {
      return {
        inPageNumber: 1,
        inRows: 100,
      };
    });
    setCurrentPage(0);
  };

  // region hit api in pagination function

  const handlePaginationChange = () => {
    if (currentPage != 0 && itemPerPage === 10 && currentPage % 9 === 0) {
      setApiPage((prev) => {
        return {
          inPageNumber: prev.inPageNumber,
          inRows: prev.inRows + 100,
        };
      });
    } else if (currentPage !== 0 && itemPerPage === 100) {
      setApiPage((prev) => {
        return {
          inPageNumber: prev.inPageNumber,
          inRows: prev.inRows + 100,
        };
      });
    } else if (currentPage === 0 && itemPerPage === 100) {
      setApiPage((prev) => {
        return {
          inPageNumber: prev.inPageNumber,
          inRows: prev.inRows + 100,
        };
      });
    } else if (
      currentPage != 0 &&
      itemPerPage === 25 &&
      currentPage % 3 === 0
    ) {
      setApiPage((prev) => {
        return {
          inPageNumber: prev.inPageNumber,
          inRows: prev.inRows + 100,
        };
      });
    } else if (
      currentPage != 0 &&
      itemPerPage === 50 &&
      currentPage % 1 === 0
    ) {
      setApiPage((prev) => {
        return {
          inPageNumber: prev.inPageNumber,
          inRows: prev.inRows + 100,
        };
      });
    }
  };

  useEffect(() => {
    if (apiFolderData && dispatchFolderId) {
      const result = apiFolderData.filter((item) => {
        return item?.folderId != dispatchFolderId;
      });
      setMoveFolderFilter(result);
    }
  }, [apiFolderData, dispatchFolderId]);

  return (
    <div>
      <Grid container spacing={1}>
        {/* left Email data */}
        <Grid item xs={11.9} sm={11.9} md={3.4} lg={3.4} xl={3.4}>
          <Box
            sx={{
              background: "white",
              borderRadius: "6px",
              border: "1px solid lightgray",
              height: { xs: "90vh", xl: "97vh", lg: "97vh", md: "97vh" },
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <Box>
              {/* Box1 filter */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  p: "5px 5px 0 5px",
                }}
              >
                <Stack
                  sx={{ position: "relative", padding: "0px", margin: "0px" }}
                >
                  <FormControl
                    sx={{
                      top: 0,
                      zIndex: 1,
                    }}
                  >
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedFilter} // Set the value to selectedFilter
                      label="Filter" // Updated label for clarity
                      onClick={() => {
                        setSelectFilter(true);
                      }}
                      onChange={handleChange}
                      sx={{
                        "& .MuiSelect-select": {
                          paddingRight: "30px", // Reduce space between value and arrow
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none", // Remove outline border
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "none", // Remove outline on hover
                        },
                        "& .MuiSelect-icon": {
                          right: "10px", // Adjust the position of the dropdown icon if needed
                        },
                      }}
                    >
                      <MenuItem value="AllMails">All Mails</MenuItem>
                      <MenuItem value="Today">Today</MenuItem>
                      <MenuItem value="Yesterday">Yesterday</MenuItem>
                      <MenuItem value="ThisMonth">This Month</MenuItem>
                      <MenuItem value="PreviousMonth">Previous Month</MenuItem>
                      <MenuItem value="HasFiles">Has Files</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  {open && (
                    <DatePicker
                      value={dayjs(selectedDate)}
                      onChange={(newDate) => {
                        const formattedDate =
                          newDate && newDate.isValid()
                            ? newDate.format("YYYY-MM-DD")
                            : null;
                        setSelectedDate(formattedDate);
                        setSelectFilter(false);
                      }}
                      open={open}
                      onClose={() => setOpen(false)}
                      renderInput={(params) => <TextField {...params} />}
                      sx={{
                        "& .MuiInputBase-root": {
                          visibility: "hidden",
                        },
                      }}
                    />
                  )}
                  <IconButton
                    onClick={handleIconClick}
                    sx={{ height: "40px", marginTop: "8px" }}
                  >
                    <CalendarMonthIcon />
                  </IconButton>
                </LocalizationProvider>
              </Box>
              {/* Box2 search */}
              <Box
                sx={{
                  display: "flex",
                  margin: "0 10px 10px 10px",
                }}
              >
                <Box>
                  <TextField
                    variant="outlined"
                    placeholder="Search mail"
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    sx={{
                      backgroundColor: "rgb(241,241,241)",
                      width: "100%",
                      borderRadius: "20px 0 0 20px",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "20px 0 0 20px",
                      },
                      "& input::placeholder": {
                        fontSize: "12px",
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment>
                          <Search />
                        </InputAdornment>
                      ),
                      inputProps: {
                        style: {
                          height: "35px",
                          padding: "0 0px",
                          fontSize: "90%",
                        },
                      },
                    }}
                  />
                </Box>
                <FormControl sx={{ minWidth: "107px" }}>
                  <Select
                    value={inboxMenu}
                    onChange={inboxMenuChange}
                    displayEmpty
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 300,
                        },
                      },
                    }}
                    sx={{
                      height: "35px",
                      backgroundColor: "rgb(241,241,241)",
                      borderRadius: "0 20px 20px 0",
                      fontSize: "80%",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "0 20px 20px 0",
                      },
                    }}
                  >
                    <MenuItem value="inbox">Inbox</MenuItem>
                    <MenuItem value="sent">Sent</MenuItem>
                    <MenuItem value="draft">Draft</MenuItem>
                    <MenuItem value="favourite">Favourite</MenuItem>
                    <MenuItem value="trash">Trash</MenuItem>

                    {apiFolderData?.map((item, index) => {
                      return (
                        <MenuItem
                          key={item?.folderId}
                          value={item?.folderId}
                          onClick={() => {
                            navigate(`/emails/folder/${item?.folderId}`);
                            localStorage.setItem("selectedId", item?.folderId);
                            dispatch(setDispatchFoldersId(item?.folderId));
                          }}
                        >
                          {item?.folderName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>

              {/* Right-click */}
              {isContextMenuOpen && (
                <Box
                  style={{
                    left: `${menuPosition.x}px`,
                    top: `${menuPosition.y}px`,
                    position: "absolute",
                    backgroundColor: "#ffffff",
                    borderRadius: "4px",
                    boxShadow: `0 4px 6px rgba(0, 0, 0, 0.1)`,
                    zIndex: 1000,
                    width: `150px`,
                  }}
                  onMouseLeave={handleCloseContextMenu}
                >
                  <Box
                    sx={{
                      padding: `8px 16px`,
                      fontSize: "13px",
                      cursor: `pointer`,
                      transition: `backgroundColor 0.2s`,
                      "&:hover": {
                        backgroundColor: "#f0f0f0",
                      },
                    }}
                    onClick={() => {
                      handleCloseContextMenu();
                      ReadUserEmail({
                        emailId: [selectedID],
                        IsRead: isRead ? false : true,
                        UserId: userId,
                        CreatedBy:parseInt(userId),
                      });
                    }}
                  >
                    {isRead ? "Mark as Unread" : "Mark as Read"}
                  </Box>
                  <Box
                    sx={{
                      padding: `8px 16px`,
                      fontSize: "13px",
                      cursor: `pointer`,
                      transition: `backgroundColor 0.2s`,
                      "&:hover": {
                        backgroundColor: "#f0f0f0",
                      },
                    }}
                    onClick={() => {
                      handleCloseContextMenu();
                      moveFromCustomFolder();
                    }}
                  >
                    Restore to Folder
                  </Box>
                  <Box
                    sx={{
                      padding: `8px 16px`,
                      cursor: `pointer`,
                      fontSize: "13px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      transition: `backgroundColor 0.2s`,
                      "&:hover": {
                        backgroundColor: ` #f0f0f0`,
                      },
                    }}
                    onMouseEnter={handleViewMouseEnter}
                    onMouseLeave={handleViewMouseLeave}
                  >
                    Move to Folder
                    <ArrowForwardIosIcon
                      sx={{
                        fontSize: "small",
                      }}
                    />
                    {hoveredView && (
                      <Box
                        sx={{
                          position: `absolute`,
                          left: `150px`,
                          top: 0,
                          backgroundColor: `#ffffff`,
                          boxShadow: `0 4px 6px rgba(0, 0, 0, 0.1)`,
                          borderRadius: `4px`,
                          marginTop: `20px`,
                          width: `150px`,
                          overflowY: "auto",
                          maxHeight: "170px",
                        }}
                      >
                        {moveFolderFilter?.map((item) => {
                          return (
                            <Box
                              sx={{
                                padding: `8px 16px`,
                                cursor: `pointer`,
                                transition: ` backgroundColor 0.2s`,
                                "&:hover": { backgroundColor: `#f0f0f0` },
                              }}
                              onClick={async () => {
                                handleCloseContextMenu();
                                try {
                                  const moveResponse = await moveToFolder({
                                    EmailId: [selectedID],
                                    FolderId: item?.folderId,
                                    UserId: userId,
                                  });
                                  if (moveResponse?.status === 200) {
                                    await getUserEmailList(
                                      userId,
                                      apiPage?.inPageNumber,
                                      apiPage?.inRows,
                                      dispatchFolderId
                                    );
                                    setSelectedID([]);
                                  } else {
                                    console.error("Failed to move email");
                                  }
                                } catch (error) {
                                  console.error("Error moving email:", error);
                                }
                              }}
                            >
                              {item.folderName}
                            </Box>
                          );
                        })}
                      </Box>
                    )}
                  </Box>
                  <Box
                    sx={{
                      padding: `8px 16px`,
                      cursor: `pointer`,
                      color: "#265388",
                      fontSize: "13px",
                      transition: `backgroundColor 0.2s`,
                      "&:hover": {
                        backgroundColor: "#f0f0f0",
                      },
                    }}
                    onClick={() => {
                      setOpenModal(true);
                      setFolderId(null);
                      handleCloseContextMenu();
                    }}
                  >
                    Create new Folder
                  </Box>
                  <Box
                    sx={{
                      padding: `8px 16px`,
                      cursor: `pointer`,
                      fontSize: "13px",
                      color: "red",
                      transition: `backgroundColor 0.2s`,
                      "&:hover": {
                        backgroundColor: "#f0f0f0",
                      },
                    }}
                    onClick={() => {
                      deleteUserEmail({
                        emailId: [selectedID],
                        UserId: userId,
                        IsTrash: true,
                        CreatedBy:parseInt(userId),
                      });
                      handleCloseContextMenu();
                    }}
                  >
                    Delete
                  </Box>
                </Box>
              )}

              {/* Box3 table email data*/}
              {paginationData?.length <= 0 && loading === true ? (
                <Box
                  minHeight={"150px"}
                  width={"100%"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <CircularProgress />
                </Box>
              ) : paginationData?.length > 0 && loading === false ? (
                <Box sx={{ height: "67vh", overflowY: "auto" }}>
                  {paginationData &&
                    paginationData
                      ?.filter((items) => {
                        const searchData =
                          search === "" ||
                          items?.subject
                            ?.toLowerCase()
                            ?.includes(search.toLowerCase()) ||
                          "" ||
                          items?.body
                            ?.toLowerCase()
                            ?.includes(search.toLowerCase()) ||
                          "";
                        return searchData;
                      })
                      ?.map((item, key) => {
                        const date = new Date(item?.createdDate);
                        const formattedDate = date.toLocaleDateString("en-GB");
                        // Format time as hh:mm AM/PM
                        const formattedTime = date.toLocaleTimeString("en-GB", {
                          hour: "2-digit",
                          minute: "2-digit",
                          // hour12: true, // 12-hour format with AM/PM
                        });

                        return (
                          <Box
                            key={key}
                            onContextMenu={(e) => {
                              handleRightClick(e);
                              setIsRead(item.isRead);
                            }}
                            sx={{
                              borderLeft: "4px solid #085f99",
                              marginBottom: "4px",
                              paddingLeft: "10px",
                              outline: "1px solid #00000026",
                              p: "2px",
                              bgcolor:
                                EmailId == item?.emailId ? "#e5eaff" : "#fff",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                padding: "5px",
                              }}
                              onContextMenu={(e) => {
                                setSelectedID(item?.emailId);
                                //setFolderId(category?.folderId);
                                //setFolderNameSideBar(category?.folderName);
                              }}
                            >
                              <Box sx={{ width: "8%" }}>
                                {item?.isFav ? (
                                  <IconButton
                                    onClick={() => {
                                      FavEmails({
                                        emailId: [item?.emailId],
                                        isFav:
                                          item?.isFav === true ? false : true,
                                        UserId: userId,
                                        Deleted: false,
                                        CreatedBy:parseInt(userId), 
                                      });
                                    }}
                                    sx={{ width: "20px", height: "20px" }}
                                  >
                                    <StarIcon
                                      fontSize="small"
                                      style={{
                                        color: "#FFD700",
                                        fontSize: "20px",
                                        marginRight: "10px",
                                      }}
                                    />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    onClick={() => {
                                      FavEmails({
                                        emailId: [item?.emailId],
                                        isFav:
                                          item?.isFav === true ? false : true,
                                        UserId: userId,
                                        Deleted: false,
                                        CreatedBy:parseInt(userId),
                                      });
                                    }}
                                    sx={{ width: "20px", height: "20px" }}
                                  >
                                    <StarBorderIcon
                                      fontSize="small"
                                      style={{
                                        fontSize: "20px",
                                        marginRight: "10px",
                                      }}
                                    />
                                  </IconButton>
                                )}
                              </Box>
                              <Box
                                onClick={() => {
                                  localStorage.setItem(
                                    "emailIds",
                                    item?.emailId
                                  );
                                  dispatch(setEmailId(item?.emailId));
                                  dispatch(setHandleSentEmail(false));
                                  if(item?.isRead===false || item?.isRead===null){
                                    ReadUserEmail({
                                      emailId: [item?.emailId],
                                      IsRead: true,
                                      UserId: userId,
                                      CreatedBy: parseInt(userId)
                                    });
                                  }
                                  
                                }}
                                sx={{
                                  cursor: "pointer",
                                  width: "92%",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box>
                                  <Typography
                                    sx={{
                                      fontFamily: "Poppins",
                                      fontWeight: item?.isRead
                                        ? "normal"
                                        : "bold",
                                      color: item?.isRead ? "gray" : "black",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {item?.senderFirstName?.length > 15 &&
                                    item?.senderLastName?.length > 10
                                      ? `${item?.senderFirstName?.slice(0, 15)} 
                                    ${item?.senderLastName?.slice(0, 10)}...`
                                      : `${item?.senderFirstName} ${item?.senderLastName}`}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontFamily: "Poppins",
                                      fontWeight: item?.isRead
                                        ? "normal"
                                        : "bold",
                                      color: item?.isRead ? "gray" : "black",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {item?.subject?.length > 22
                                      ? item?.subject?.slice(0, 22) + "..."
                                      : item?.subject}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                    }}
                                  >
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          item?.body?.replace(/<[^>]*>/g, "")
                                            ?.length > 22
                                            ? item?.body
                                                ?.replace(/<[^>]*>/g, "")
                                                ?.slice(0, 22) + "..."
                                            : item?.body?.replace(
                                                /<[^>]*>/g,
                                                ""
                                              ),
                                      }}
                                      style={{
                                        fontWeight: item?.isRead
                                          ? "normal"
                                          : "bold",
                                        color: item?.isRead ? "gray" : "black",
                                        display: "-webkit-box",
                                        WebkitLineClamp: 4,
                                        WebkitBoxOrient: "vertical",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                    />
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    sx={{
                                      fontSize: "11px",
                                      fontFamily: "Poppines",
                                    }}
                                  >
                                    {/* {formattedDate} {formattedTime} */}
                                    {dayjs(item?.createdDate)
                                      ?.add(13, "hour")
                                      ?.format("MM/DD/YYYY hh:mm A")}
                                  </Typography>
                                </Box>
                              </Box>
                              {/* favIcons */}
                            </Box>
                          </Box>
                        );
                      })}
                </Box>
              ) : (
                <Box
                  minHeight={"150px"}
                  width={"100%"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Typography>No Email</Typography>
                </Box>
              )}
            </Box>

            <Stack
              direction={"row"}
              mb={"10px"}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FormControl sx={{ m: 1, maxWidth: 80 }}>
                <Select
                  value={itemPerPage}
                  onChange={handlePagination}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{ height: 30 }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </FormControl>
              <Typography fontSize={"small"}>
                {count === 0 ? 0 : startIndex + 1} - {endIndex} of {count}
              </Typography>
              <Box spacing={2}>
                <IconButton onClick={handlePrevious} disabled={startIndex <= 0}>
                  <ArrowBackIosRoundedIcon fontSize="small" />
                </IconButton>

                <IconButton onClick={handleNext} disabled={endIndex === count}>
                  <ArrowForwardIosRoundedIcon fontSize="small" />
                </IconButton>
              </Box>
            </Stack>
          </Box>
        </Grid>
        {/* right window data */}
        <Grid item xs={11.9} sm={11.9} md={8.5} lg={8.5} xl={8.5}>
          {/* Box1 filter */}
          <Box
            sx={{
              background: "white",
              borderRadius: "6px",
              border: "1px solid lightgray",
              height: { xs: "90vh", xl: "97vh", lg: "97vh", md: "97vh" },
            }}
          >
            <EmailWindow />
          </Box>
        </Grid>
      </Grid>
      {/* Modal */}
      <CreateNewFolder
        openModal={openModal}
        setOpenModal={setOpenModal}
        folderId={folderId}
      />
    </div>
  );
};

export default NewFolders;
