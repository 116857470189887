import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { AddIcCall, Delete, MapsUgc } from "@mui/icons-material";
import * as XLSX from "xlsx";
import {
  ExportUserContacts,
  getUserContacts,
  getUserContactsByParams,
  updateUserContact,
} from "../../../services/api/apiManager";
import { TRUE } from "sass";
import { enqueueSnackbar, useSnackbar } from "notistack";
import AddcontactModal from "../chats/chatList/modal/AddcontactModal";
import { useSelector } from "react-redux";

export const Contacts = () => {
  // region All states
  const [userContacts, setUserContacts] = useState([]);
  const [userName, setUserName] = useState("");
  const [filterField, setFilterField] = useState(false);
  const [contactType, SetContactType] = useState("");
  const [status, setStatus] = useState("");
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [count, setCount] = useState();
  const [favContact, setFavContact] = useState([]);
  const [loader, setLoader] = useState(false);
  const userId = localStorage.getItem("user_id");
  const { enqueueSnackbar } = useSnackbar();

  const handleContact = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setUserName("");
    setOpen(false);
    //setAddedContacts(new Set());
  };

  const ContactData = async (
    User_ID,
    pageNumber,
    Rows,
    isfav,
    isExternal,
    UserName
  ) => {
    try {
      setLoader(true);
      const response = await getUserContactsByParams(
        User_ID,
        pageNumber,
        Rows,
        isfav,
        isExternal,
        UserName
      );
      if (response.status === 200) {
        const userContactData = response?.data?.result;
        setUserContacts(userContactData);
        setLoader(false);
        return response?.data?.result;
      }
    } catch (error) {
      setLoader(false);
      console.error("Error : ", error);
    }
  };
  useEffect(() => {
    ContactData(userId, 100, 1, "", contactType, userName);
  }, [userName, contactType]);

  const handleDeleteContact = async (contactId) => {
    try {
      const res = await updateUserContact({
        userContactId: contactId,
        modifiedBy: 1,
        isBlocked: false,
        deleted: true,
        CreatedBy: parseInt(userId),
      });
      if (res.status === 200) {
        ContactData(userId, 100, 1, "", contactType, userName);
        enqueueSnackbar("Contact Deleted Succeffully!", {
          variant: "success",
        });
      }
    } catch (error) {
      console.warn("Error fetching chats", error);
    }
  };

  // region Get User Contacts Data to Excel
  const GetUsersContantToExcel = async () => {
    try {
      const response = await ExportUserContacts(parseInt(userId));

      if (response?.status === 200) {
        handleDownload(response?.data?.result?.fileContents);
      }
    } catch (err) {
      console.warn(err);
    }
  };

  // for excel data
  const handleDownload = (fileContentsExcel) => {
    const base64Data = atob(fileContentsExcel);
    const workbook = XLSX.read(base64Data, { type: "binary" });
    const fileName = "file.xlsx";
    XLSX.writeFile(workbook, fileName);
  };

  // get User Contacts data
  const GetUserContactsData = {
    UserId: userId,
    isBlock: false,
    isFav: false,
    IsAdmin: false,
    isExternal: false,
    UserName: null,
  };

  const [apiPage, setApiPage] = useState({
    inPageNumber: 1,
    inRows: 100,
  });

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const handlePaginationChange = (e) => {
    setPaginationModel({ page: e.page, pageSize: e.pageSize });
    if (
      paginationModel.page != 0 &&
      paginationModel.pageSize === 10 &&
      paginationModel.page % 9 === 0
    ) {
      setApiPage((prev) => {
        return {
          inPageNumber: prev.inPageNumber + 1,
          inRows: prev.inRows + 100,
        };
      });
    } else if (paginationModel.page !== 0 && paginationModel.pageSize === 100) {
      setApiPage((prev) => {
        return {
          inPageNumber: prev.inPageNumber + 1,
          inRows: prev.inRows + 100,
        };
      });
    } else if (paginationModel.page === 0 && paginationModel.pageSize === 100) {
      setApiPage((prev) => {
        return {
          inPageNumber: prev.inPageNumber,
          inRows: prev.inRows + 100,
        };
      });
    } else if (
      paginationModel.page != 0 &&
      paginationModel.pageSize === 25 &&
      paginationModel.page % 3 === 0
    ) {
      setApiPage((prev) => {
        return {
          inPageNumber: prev.inPageNumber + 1,
          inRows: prev.inRows + 100,
        };
      });
    } else if (
      paginationModel.page != 0 &&
      paginationModel.pageSize === 50 &&
      paginationModel.page % 1 === 0
    ) {
      setApiPage((prev) => {
        return {
          inPageNumber: prev.inPageNumber,
          inRows: prev.inRows,
        };
      });
    } else {
      setApiPage((prev) => {
        return {
          inPageNumber: 1,
          inRows: 100,
        };
      });
    }
  };

  //   data grid column
  const columns = [
    { field: "id", headerName: "SERIAL NO" },
    { field: "contactId", headerName: "CONTACT ID", width: 200 },
    {
      field: "name",
      headerName: "NAME",
      flex: 1,
      sortable: true,
      width: 250,
    },
    {
      field: "phoneNumber",
      headerName: "PHONE NUMBER",
      flex: 1,
      width: 250,
    },

    {
      field: "contactType",
      headerName: "CONTACT TYPE",
      flex: 1,
      width: 250,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            justifyContent={"start"}
            alignItems={"center"}
            height={"100%"}
          >
            <Typography
              sx={{
                fontSize: "small",
                fontWeight: 600,
                color:
                  params?.row?.contactType === "Internal" ? "green" : "#085F99",
              }}
            >
              {params?.row?.contactType}
            </Typography>
          </Box>
        );
      },
    },

    {
      field: "status",
      headerName: "STATUS",
      flex: 0.5,
      textAlign: "center",
      renderCell: (params) => (
        <span
          style={{
            color: params.value ? "green" : "yellow",
            fontWeight: "bold",
          }}
        >
          {params.value ? "Active" : "Inactive"}
        </span>
      ),
    },
    {
      field: "action",
      headerName: "ACTION",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <Stack
            direction={"row"}
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",

              alignItems: "center",
            }}
          >
            {params?.row?.contactType === "Internal" ? (
              <IconButton>
                <MapsUgc
                  sx={{ color: "#1565C0 !important", fontSize: "medium" }}
                />
              </IconButton>
            ) : (
              <IconButton>
                <AddIcCall
                  sx={{ color: "#1565C0 !important", fontSize: "medium" }}
                />
              </IconButton>
            )}
            <IconButton
              onClick={() => handleDeleteContact(params?.row?.contactId)}
            >
              <Delete sx={{ color: "red !important", fontSize: "medium" }} />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  const rows = userContacts?.map((val, index) => {
    return {
      id: index + 1,
      contactId: `${val?.userContactId}`,
      name:
        `${val?.firstName || ""} ${val?.lastName || ""} ${
          val?.externalPersonName || ""
        }`.trim() || "No Name Available",
      phoneNumber: `${val.externalPersonContactNo || ""}`.trim() || "_",
      contactType: `${val.externalPersonContactNo ? "External" : "Internal"}`,
      status: `${val.isActive}`,
    };
  });
  return (
    <Box sx={{ p: 2, bgcolor: "#F7FAFF", height: "100vh" }}>
      <Box
        padding={"1rem 10px"}
        display={"flex"}
        sx={{
          flexDirection: { md: "row", xs: "column" },
          gap: "10px",
          justifyContent: { md: "space-between", xs: "center" },
          alignItems: { xs: "center" },
          bgcolor: "white",
          border: "1px solid #e4edfc",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography variant="h6" fontWeight={600}>
            Contacts
          </Typography>
        </Box>

        <Box>
          <Box
            display={"flex"}
            gap={"0.3rem"}
            sx={{
              flexDirection: { md: "row", xs: "column" },
              "& > :nth-of-type(1)": {
                order: { xs: 2, md: 1 },
              },
              "& > :nth-of-type(2)": {
                order: { xs: 1, md: 2 },
              },
            }}
          >
            {/* all filter field */}
            {filterField && (
              <Box display={"flex"} gap={"0.3rem"}>
                {/* search by name  */}
                <input
                  value={userName}
                  onChange={(e) => {
                    setUserName(e.target.value);
                  }}
                  placeholder="Search Name"
                  size="small"
                  style={{
                    boxShadow: "none",
                    border: "1px solid #00000030",
                    borderRadius: "5px",
                    height: "37px",
                    width: "6.5rem",
                    padding: "5px 10px",
                  }}
                />
                <FormControl
                  variant="standard"
                  sx={{
                    minWidth: 100,
                    mt: "-15px",
                  }}
                >
                  <InputLabel
                    shrink={!!contactType}
                    sx={{
                      ml: "10px",
                      fontSize: "12px",
                      mt: "5px",
                    }}
                    //id="demo-simple-select-standard-label"
                  >
                    Contact Type
                  </InputLabel>
                  <Select
                    value={contactType}
                    onChange={(e) => SetContactType(e.target.value)}
                    sx={{
                      borderLeft: "6px solid #085F99",
                      borderRadius: "7px",
                      "&.MuiInput-underline:before": {
                        borderBottom: "none",
                      },
                      "&.Mui-focused": {
                        "&.MuiInput-underline:before": {
                          borderBottom: "none",
                        },
                      },
                      height: "35px",
                      width: "100px",
                      "& .MuiSelect-select": {
                        fontSize: "12px",
                        ml: "5px",
                      },
                      outline: "1px solid #00000026",
                    }}
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    MenuProps={{
                      PaperProps: {
                        style: {
                          overflowX: "auto",
                          maxHeight: "300px",
                          width: "130px",
                          overflowY: "auto",
                          scrollbarWidth: "thin",
                          scrollbarColor: "dark",
                          msOverflowStyle: "none",
                          WebkitOverflowScrolling: "touch",
                        },
                      },
                    }}
                  >
                    <MenuItem
                      value={""}
                      sx={{
                        fontSize: "13px",
                      }}
                    >
                      Clear filter
                    </MenuItem>
                    <MenuItem
                      value={"false"}
                      sx={{
                        fontSize: "13px",
                      }}
                    >
                      Internal
                    </MenuItem>
                    <MenuItem
                      value={"true"}
                      sx={{
                        fontSize: "13px",
                      }}
                    >
                      External
                    </MenuItem>
                  </Select>
                </FormControl>

                {/* status */}
                <FormControl
                  variant="standard"
                  sx={{
                    minWidth: 100,
                    mt: "-15px",
                  }}
                >
                  <InputLabel
                    shrink={status === true || status === false}
                    sx={{
                      ml: "10px",
                      fontSize: "12px",
                      mt: "5px",
                    }}
                    id="demo-simple-select-standard-label"
                  >
                    Status
                  </InputLabel>
                  <Select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    sx={{
                      borderLeft: "6px solid #085F99",
                      borderRadius: "7px",
                      "&.MuiInput-underline:before": {
                        borderBottom: "none",
                      },
                      "&.Mui-focused": {
                        "&.MuiInput-underline:before": {
                          borderBottom: "none",
                        },
                      },
                      height: "35px",
                      width: "100px",
                      "& .MuiSelect-select": {
                        fontSize: "12px",
                        ml: "5px",
                      },

                      outline: "1px solid #00000026",
                    }}
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    label="Status"
                    MenuProps={{
                      PaperProps: {
                        style: {
                          overflowX: "auto",
                          maxHeight: "300px",
                          width: "130px",
                          overflowY: "auto",
                          overflowX: "auto",
                          scrollbarWidth: "thin",
                          scrollbarColor: "dark",
                          msOverflowStyle: "none",
                          WebkitOverflowScrolling: "touch",
                        },
                      },
                    }}
                  >
                    <MenuItem
                      value={""}
                      sx={{
                        fontSize: "13px",
                      }}
                    >
                      Clear Filter
                    </MenuItem>
                    <MenuItem
                      value={true}
                      sx={{
                        fontSize: "13px",
                      }}
                    >
                      Active
                    </MenuItem>
                    <MenuItem
                      value={false}
                      sx={{
                        fontSize: "13px",
                      }}
                    >
                      InActive
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            )}
            <Box display={"flex"} gap={"0.3rem"}>
              <Button
                onClick={() => setFilterField(!filterField)}
                size="small"
                sx={{
                  width: "5rem",
                  height: "2.3rem",
                  borderRadius: "8px",
                  fontSize: "12px",
                  fontWeight: "400",
                  fontFamily: "Poppins",
                  boxShadow: "none",
                  color: " #344054",
                  textTransform: "capitalize",
                  bgcolor: "#D7E5EF",
                }}
                startIcon={
                  <FilterListOutlinedIcon
                    sx={{ size: "small", color: " #344054" }}
                  />
                }
              >
                Filter
              </Button>
              {/* csv Export */}
              <Button
                onClick={() => GetUsersContantToExcel()}
                size="small"
                sx={{
                  width: "5rem",
                  height: "2.3rem",
                  borderRadius: "8px",
                  fontSize: "12px",
                  fontWeight: "400",
                  font: "poppen",
                  border: "1px solid #00000026",
                  boxShadow: "none",
                  color: " #344054",
                  textTransform: "capitalize",
                }}
                startIcon={
                  <CloudDownloadOutlinedIcon
                    sx={{ size: "small", color: " #344054" }}
                  />
                }
              >
                Export
              </Button>
              <Button
                onClick={handleContact}
                size="small"
                variant="contained"
                sx={{
                  width: "8rem",
                  bgcolor: "#085F99",
                  height: "2.3rem",
                  borderRadius: "8px",
                  fontSize: "12px",
                  fontWeight: "400",
                  font: "poppen",
                  boxShadow: "none",
                  color: "#FFFFFF",
                  textTransform: "capitalize",
                }}
                startIcon={
                  <AddOutlinedIcon sx={{ size: "small", color: "#FFFFFF" }} />
                }
              >
                Add Contact
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          overflowX: "auto",
          maxWidth: "100%",
          bgcolor: "white",
          height: "600px",
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          loading={loader === true}
          columnHeaderHeight={28}
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationChange}
          rowCount={count}
          pageSizeOptions={[10, 25, 50, 100]}
          disableRowSelectionOnClick
          sx={{
            "& .MuiDataGrid-cell": {
              fontSize: "13px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "200px",
            },
            "& .MuiDataGrid-columnHeader": {
              backgroundColor: "#D7E5EF",
              color: "#085F99",
              fontFamily: "Poppins",
              fontWeight: "600",
              fontSize: "15px",
            },
            "& .MuiSvgIcon-root": {
              color: "#8B83BA",
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: "#D7E5EF",
            },
            "& .MuiTablePagination-selectLabel": {
              fontFamily: "Poppins",
              fontWeight: "500",
              fontSize: "15px",
              color: "#085F99",
            },
            "& .MuiInputBase-root": {
              border: "0.5px solid #383838",
              width: "60px",
              height: "30px",
              borderRadius: "10%",
              backgroundColor: "white",
            },
            "& .MuiTablePagination-displayedRows": {
              color: "#085F99",
              fontFamily: "Poppins",
              fontWeight: "500",
              fontSize: "15px",
            },
            "& .MuiTablePagination-actions": {
              color: "#085F99",
            },
            "& .MuiSelect-select": {
              color: "#085F99",
            },
          }}
        />
      </Box>
      <Outlet />
      {open && (
        <AddcontactModal
          open={open}
          handleClose={handleClose}
          ContactData={() =>
            ContactData(userId, 100, 1, "", contactType, userName)
          }
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
        />
      )}
    </Box>
  );
};
