import {
  Box,
  Modal,
  Typography,
  IconButton,
  Divider,
  Button,
} from "@mui/material";
import { setDraftEmails } from "../../../../services/store/slice/ChatSlice";
import {
  setOpenDraftModal,
  setEmptyEmailInputs,
  setRenderDraft,
  setRerenderFromRedux,
  setEmptyEmails,
  setDraftId,
} from "../../../../services/store/slice/EmailSlice";
import React, {
  startTransition,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { enqueueSnackbar } from "notistack";
import { sentEmailData } from "../../../../services/api/apiManager";

const DraftModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pendingPath, setPendingPath] = useState("");
  const userId=localStorage.getItem("user_id");
  const location = useLocation();
  const actionTypeDraftEmails = useSelector(
    (state) => state?.chat?.draftEmails
  );

  const draftId = useSelector((state) => state?.email?.draftId);
  const openDraftModal = useSelector((state) => state?.email?.openDraftModal);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (openDraftModal && draftId != actionTypeDraftEmails?.SenderId) {
      setIsModalOpen(true);
    } else {
      dispatch(setDraftEmails(null));
      dispatch(setEmptyEmails(true));
      dispatch(setDraftId(""));
    }
  }, [openDraftModal]);

  const navigateFun = (path) => {
    let hasDraftData = false;
    // Check if EmailType is internal or external and validate respective fields
    if (actionTypeDraftEmails?.EmailType === "Internal") {
      hasDraftData =
        (actionTypeDraftEmails?.RecipentData &&
          actionTypeDraftEmails?.RecipentData.length > 0) ||
        (actionTypeDraftEmails?.RecipentDataCc &&
          actionTypeDraftEmails?.RecipentDataCc.length > 0) ||
        (actionTypeDraftEmails?.RecipentDataBcc &&
          actionTypeDraftEmails?.RecipentDataBcc.length > 0);
    } else if (actionTypeDraftEmails?.EmailType === "External") {
      hasDraftData =
        (actionTypeDraftEmails?.File &&
          actionTypeDraftEmails?.File?.length > 0) ||
        (actionTypeDraftEmails?.RecipentData &&
          actionTypeDraftEmails?.RecipentData.length > 0) ||
        (actionTypeDraftEmails?.RecipentDataCc &&
          actionTypeDraftEmails?.RecipentDataCc.length > 0) ||
        (actionTypeDraftEmails?.RecipentDataBcc &&
          actionTypeDraftEmails?.RecipentDataBcc.length > 0);
    }

    if (
      location?.pathname != "/emails/newEmail" &&
      hasDraftData &&
      draftId != actionTypeDraftEmails?.SenderId
    ) {
      setIsModalOpen(true);
      setPendingPath(path);
    } else {
      navigate(path);
      if (draftId == actionTypeDraftEmails?.SenderId) {
        dispatch(setEmptyEmails(true));
      }
    }
  };

  useLayoutEffect(() => {
    navigateFun(location.pathname);
  }, [location.pathname]);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    // border: "2px solid #000",
    // boxShadow: 24,
    p: 2,
  };

  const draftEmailData = async (data) => {
    try {
      const formData = new FormData();
      // formData.append("ParentEmailId", data?.ParentEmailId);
      formData.append("SenderId", Number(data?.SenderId));
      formData.append("Subject", data?.Subject);
      formData.append("Body", data?.Body);
      formData.append("EmailType", data?.EmailType);
      formData.append("IsRead", false);
      formData.append("IsFav", false);
      formData.append("IsDraft", true);
      formData.append("CreatedBy", userId);
      // For Internal
      if (data?.EmailType === "Internal") {
        data?.RecipentData?.forEach((toData, index) => {
          formData.append(`Recipients[${index}].UserId`, toData?.userId);
          formData.append(`Recipients[${index}].recipientemail`, toData?.email);
          formData.append(`Recipients[${index}].RecipientType`, "To");
        });
        data?.RecipentDataCc?.forEach((toData, index) => {
          formData.append(
            `Recipients[${data?.RecipentData?.length + index}].UserId`,
            toData?.userId
          );
          formData.append(
            `Recipients[${data?.RecipentData?.length + index}].recipientemail`,
            toData?.email
          );
          formData.append(
            `Recipients[${data?.RecipentData?.length + index}].RecipientType`,
            "CC"
          );
        });
        data?.RecipentDataBcc?.forEach((toData, index) => {
          formData.append(
            `Recipients[${
              data?.RecipentDataCc?.length + data?.RecipentData?.length + index
            }].UserId`,
            toData?.userId
          );
          formData.append(
            `Recipients[${
              data?.RecipentDataCc?.length + data?.RecipentData?.length + index
            }].recipientemail`,
            toData?.email
          );
          formData.append(
            `Recipients[${
              data?.RecipentDataCc?.length + data?.RecipentData?.length + index
            }].RecipientType`,
            "BCC"
          );
        });
      }
      // for External Email
      if (data?.EmailType === "External") {
        formData.append(`Recipients[0].recipientemail`, data?.ToValue);
        formData.append(`Recipients[0].RecipientType`, "To");
        if (data?.CcValue?.length > 0) {
          formData.append(`Recipients[1].recipientemail`, data?.CcValue);
          formData.append(`Recipients[1].RecipientType`, "CC");
        }
        if (data?.BccValue?.length > 0) {
          formData.append(
            `Recipients[${data?.CcValue?.length > 0 ? 2 : 1}].recipientemail`,
            data?.BccValue
          );
          formData.append(
            `Recipients[${data?.CcValue?.length > 0 ? 2 : 1}].RecipientType`,
            "BCC"
          );
        }
      }

      formData.append("File", data?.File);
      const response = await sentEmailData(formData);
      if (response?.status === 200) {
        enqueueSnackbar("Email is Saved in Draft", {
          variant: "success",
        });
        dispatch(setDraftEmails(null));

        if (pendingPath) {
          navigate(pendingPath);
        }
      } else {
        enqueueSnackbar("Error", { variant: "error" });
        if (pendingPath) {
          navigate(pendingPath);
        }
      }
    } catch (error) {
      console.lwarn(error);
      enqueueSnackbar("Error", { variant: "error" });
      if (pendingPath) {
        navigate(pendingPath);
      }
    }
  };

  return (
    <div>
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "5px ",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "auto",
              }}
            >
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{
                  color: "#085f99",
                  fontSize: "18px",
                  fontWeight: 600,
                  textAlign: "center",
                }}
              >
                Confirmation
              </Typography>
            </Box>
            <IconButton>
              <CancelOutlinedIcon
                sx={{ color: "#085f99" }}
                onClick={() => {
                  if (pendingPath) {
                    navigate(pendingPath); // Navigate to the stored path if "Cancel" is clicked
                  }

                  setIsModalOpen(false);
                  dispatch(setOpenDraftModal(false));
                  dispatch(setEmptyEmailInputs(true));
                  dispatch(setDraftEmails(null));
                }}
              />
            </IconButton>
          </Box>
          <Divider orientation="horizontal" flexItem sx={{ marginY: "10px" }} />
          <Box pl={3} pr={3} pb={3}>
            <Typography
              sx={{
                mt: 2,
                fontSize: "16px",
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              Do you want to save your changes?
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              // marginRight: "20px",
            }}
          >
            <Box
              sx={{
                // width: "auto",
                display: "flex",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <Button
                variant="contained"
                size="large"
                fullWidth
                sx={{
                  backgroundColor: "#dedede",
                  color: "black",
                  "&:hover": { backgroundColor: "#d6d4d4" },
                }}
                onClick={() => {
                  if (pendingPath) {
                    navigate(pendingPath); // Navigate to the stored path if "Cancel" is clicked
                  }

                  setIsModalOpen(false);
                  dispatch(setOpenDraftModal(false));
                  dispatch(setEmptyEmailInputs(true));
                  dispatch(setDraftEmails(null));
                }}
              >
                No
              </Button>
              <Button
                variant="contained"
                size="large"
                fullWidth
                onClick={() => {
                  if (
                    (actionTypeDraftEmails.RecipentData &&
                      actionTypeDraftEmails.RecipentData?.length > 0) ||
                    (actionTypeDraftEmails.RecipentDataCc &&
                      actionTypeDraftEmails.RecipentDataCc?.length > 0) ||
                    (actionTypeDraftEmails.RecipentDataBcc &&
                      actionTypeDraftEmails.RecipentDataBcc?.length > 0) ||
                    // Check if selectedOptionsBcc has values
                    (actionTypeDraftEmails.Subject &&
                      actionTypeDraftEmails.Subject.trim() !== "") ||
                    (actionTypeDraftEmails.Body &&
                      actionTypeDraftEmails.Body.trim() !== "")
                  ) {
                    draftEmailData(actionTypeDraftEmails);
                  } else {
                    console.warn("No data to trigger API call");
                  }
                  setIsModalOpen(false);
                  dispatch(setOpenDraftModal(false));
                  dispatch(setEmptyEmailInputs(true));
                  dispatch(setRenderDraft(true));
                }}
              >
                YES
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default DraftModal;
