import React, { useState } from "react";
import { Box, Paper, Typography, Button, Alert, Snackbar } from "@mui/material";
import loginImage from "../../../../assets/img/max.png";
import favicon from "../../../../assets/img/x.png";
import "./login.css";
import { TextField, InputAdornment } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom"; // For navigation
import email from "../../../../assets/img/Message.png";
import { sendPassOtp } from "../../../services/api/apiManager";

const Recover = () => {
  const navigate = useNavigate();
  const [sendEmail, setSendEmail] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState({
    openSnack: false,
    snackMessage: "",
  });

  // Api recover Password
  const sendOtpRecoverPass = async (email) => {
    try {
      const response = await sendPassOtp(email);
      if (response?.status === 200 && response?.data?.message === "OTP Sent") {
        setSendEmail("");
        setOpenSnackbar({
          openSnack: true,
          snackMessage: "success",
        });
        setTimeout(() => {
          navigate("/submit", { state: { email: email } });
        }, 1000);
      } else {
        setOpenSnackbar({
          openSnack: true,
          snackMessage: "error",
        });
        setTimeout(() => {
          navigate("/");
        }, 1000);
      }
    } catch (err) {
      console.warn(err);
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100vh", // Full height of the viewport
        backgroundImage: `url(${loginImage})`,
        backgroundSize: "cover", // Cover the entire container
        backgroundPosition: "center", // Center the image
        backgroundRepeat: "no-repeat", // Prevent repeating the image
      }}
    >
      <Paper
        elevation={3}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          padding: "31px",
          backgroundColor: "#ffffff", // Optional: semi-transparent background
          borderRadius: "10px",
          textAlign: "center",
          width: {
            xl: "25%",
            lg: "25%",
            md: "40%",
            sm: "75%",
            xs: "75%",
          },
        }}
      >
        <Box pt={2.5} pb={2.5}>
          <Typography variant="h4" component="h1">
            <img src={favicon} alt="Description of the image" />
          </Typography>
          <Typography
            variant="h5"
            component="h1"
            style={{ fontWeight: "600", fontSize: "17px" }}
          >
            <span style={{ color: "#2f2f2f" }}>Recover Password</span>
          </Typography>
          <Typography
            variant="h6"
            component="h1"
            style={{ fontSize: "14px" }}
            pt={0.5}
          >
            <span style={{ color: "#90919b", fontSize: "14px" }}>
              Enter your email to Recover Password
            </span>
          </Typography>
          <Box pt={5}>
            <ThemeProvider theme={theme}>
              <Box pt={2}>
                <TextField
                  value={sendEmail}
                  onChange={(e) => setSendEmail(e.target.value)}
                  variant="outlined"
                  fullWidth
                  placeholder="Email Address"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src={email}
                          alt="Description of the image"
                          className="icons-width"
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </ThemeProvider>
          </Box>
          <Box pt={5}>
            <Button
              disabled={sendEmail === "" && true}
              onClick={() => {
                sendOtpRecoverPass(sendEmail);
              }}
              variant="contained"
              className="btn"
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Paper>
      <Snackbar
        open={openSnackbar?.openSnack}
        autoHideDuration={2000}
        onClose={() => setOpenSnackbar({ openSnack: false, snackMessage: "" })}
        anchorOrigin={{ vertical: "center", horizontal: "center" }}
      >
        <Alert
          onClose={() =>
            setOpenSnackbar({ openSnack: false, snackMessage: "" })
          }
          severity={
            openSnackbar?.snackMessage === "success" ? "success" : "error"
          }
          variant="filled"
          sx={{
            width: "100%",
            height: "2.5rem",
            textAlign: "center",
            fontSize: "0.9rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          action={
            <>
              <span
                onClick={() =>
                  setOpenSnackbar({ openSnack: false, snackMessage: "" })
                }
                style={{
                  cursor: "pointer",
                  m: "auto 0",
                  fontSize: "13px",
                  color: "rgb(231, 231, 231)",
                }}
              >
                Close
              </span>
            </>
          }
        >
          {openSnackbar.snackMessage === "success"
            ? "Otp send successfully"
            : "Invalid Credentials, Please Try again"}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Recover;
const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            borderRadius: "4px",
            backgroundColor: "#f6f7fb", // Background color of the text field
            // "& .MuiOutlinedInput-root": {

            "& input": {
              padding: "6px 12px", // Decrease padding to reduce height
              fontSize: "12px", // Adjust font size if needed
              height: "30px",
              backgroundColor: "#f6f7fb",
            },
            "& fieldset": {
              borderColor: "#f6f7fb", // Border color when inactive
            },
            "&:hover fieldset": {
              borderColor: "#f6f7fb", // Border color when hovered
            },
            "&.Mui-focused fieldset": {
              borderColor: "#f6f7fb", // Border color when focused
            },
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          padding: "4px 8px", // Adjust padding if needed
        },
      },
    },
  },
});
